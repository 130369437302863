.p-page-title {
  @include pc {
    .l-page-body & {
      max-width: 1200px + 160px;
      padding: 0 80px;
      margin: -40px auto 160px;
      display: flex;
      flex-direction: column;
    }
    &__meta {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 32px;
    }
    &__footer {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 40px;
      margin-top: 41px;
      text-align: justify;
      color: rgba(0, 0, 0, 0.3);
      margin-bottom: -8px;
    }
    &__date {
      margin-right: 16px;
      @include roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 32px;
    }
    &__title {
      margin-top: -15px;
      margin-bottom: -16px;
    }
    &__description {
      margin-top: 43px;
      font-size: 16px;
      line-height: 2;
      font-weight: 500;
      text-align: justify;
      margin-bottom: -8px;
    }
    &__category {
      color: $blue;
      font-weight: 700;
    }

    &--center {
      .p-page-title__title {
        text-align: center;
      }
      .p-page-title__description {
        text-align: center;
      }
    }
    &--md {
      .l-page-body & {
        max-width: 920px + 160px;
        padding: 0 80px;
        margin: -40px auto 160px;
        display: flex;
        flex-direction: column;
      }
      .p-page-title {
        &__meta {
          margin-bottom: 40px;
        }
        &__title {
          font-style: normal;
          font-weight: 700;
          font-size: 40px;
          line-height: 72px;
          text-align: justify;
          margin-top: -17px;
          
        }
      }
    }
  }
  @include sp {
    .l-page-body & {
      padding: 0 vw(32);
      margin-bottom: vw(80);
    }
    &__title {
      margin-top: vw(-8);
      margin-bottom: vw(-8);
    }
    &__description {
      margin-top: vw(24);
      font-size: vw(16);
      line-height: 2;
      font-weight: 500;
      text-align: justify;
      margin-bottom: vw(-8);
    }
    &__meta {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      // margin-bottom: 32px;
      margin-top: vw(-6);
      margin-bottom: vw(28 - 4);
    }
    &__category {
      color: $blue;
      font-weight: 700;
      font-size: vw(14);
    }
    &__date {
      @include roboto;
      margin-right: vw(10);
      font-style: normal;
      font-weight: 700;
      font-size: vw(14);
      line-height: 2;
    }
    &__category {
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
    }
    &__footer {
      font-style: normal;
      font-weight: 500;
      font-size: vw(16);
      line-height: 2;
      text-align: justify;
      color: rgba(0, 0, 0, 0.3);
      margin-top: vw(32 - 8);
      margin-bottom: vw(-8);
    }
    &--md {
      .l-page-body & {
        padding: 0 vw(32);
        margin-bottom: vw(80);
      }
      .p-page-title {
        &__title {
          font-style: normal;
          font-weight: 700;
          font-size: vw(28);
          line-height: vw(48);
          text-align: justify;
        }
      }
    }
  }
}
