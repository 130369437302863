@include pc {
  .p-services {
    display: flex;
    flex-wrap: wrap;
    @include shadow;
    // border: 1px solid #000;

    &:not(.p-services--even) {
      .p-service {
      
        &:nth-of-type(-n + 3) {
          margin-top: 0;
          width: calc((100% - 48px) / 3);
          @include octagon(101px);
        }
        &:nth-of-type(n + 4) {
          .p-service__title {
            min-height: 72px;
          }
        }
        &:nth-of-type(3) {
          margin-right: 0;
        }
        &:last-child {
          margin-right: 0;
        }
  
      }
    }
    &--even {
      .p-service {
        &:nth-of-type(-n + 3) {
          margin-top: 0;
        }
        &:nth-of-type(3n) {
          margin-right: 0;
        }
        width: calc((100% - 48px) / 3);
        @include octagon(101px);
      }
    }
  }

  .p-service {
    width: calc((100% - 72px) / 4);
    margin-top: 80px;
    margin-right: 24px;
    position: relative;
    @include octagon(74px);
    &__image {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: 1;
      background-color: #ccc;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .u-action--target {
      height: 100%;
      width: 100%;
      // border: 1px solid #000;
    }
    &__body {
      background: linear-gradient(180deg, rgba(30, 162, 217, 0) 50%, rgba(#1ea2d9, .8) 66.67%);
      // aspect-ratio: 0.75/1;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding: 235px 32px 32px;
      position: relative;
      height: 100%;
      z-index: 2;
    }
    &__title {
      font-weight: 700;
      font-size: 20px;
      line-height: 36px;
      display: flex;
      letter-spacing: -0.01em;
      color: #ffffff;
      margin-bottom: 5px;
      text-shadow: 0px 0px 4px rgba(30, 162, 217, 0.2);
    }
    &__number {
      @include roboto;
      font-weight: 700;
      font-size: 16px;
      line-height: 32px;
      display: flex;
      align-items: center;
      color: #ffffff;
      margin-bottom: -1px;

      text-shadow: 0px 0px 4px rgba(30, 162, 217, 0.2);
    }
    &__text {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 32px;
      display: flex;
      align-items: center;
      text-align: justify;
      color: #ffffff;
      text-shadow: 0px 0px 4px rgba(30, 162, 217, 0.2);
      margin-bottom: -8px;
    }
    &--lg {
      // opacity: 0.2;

      .p-service__body {
        padding: 279px 32px 64px;
      }
      .p-service__number {
        margin-bottom: 3px;
      }
      .p-service__title {
        margin-bottom: 9px;
      }

    }
  }
  .p-service-card {
    &:hover {
      .c-more {
        &::before {
          transform: scale(1.25);
        }
      }
    }
  }
}
@include sp {
  .p-services {
    display: grid;
    gap: vw(24);
    width: 100%;
    &:not(.p-services--even) {
      .p-service {
      
      //   &:nth-of-type(-n + 3) {
      //     margin-top: 0;
      //     width: calc((100% - 48px) / 3);
      //     @include octagon(101px);
      //   }
      //   &:nth-of-type(n + 4) {
      //     .p-service__title {
      //       min-height: 72px;
      //     }
      //   }
      //   &:nth-of-type(3) {
      //     margin-right: 0;
      //   }
      //   &:last-child {
      //     margin-right: 0;
      //   }
  
      }
    }
    &--even {
      .p-service {
        // &:nth-of-type(-n + 3) {
        //   margin-top: 0;
        // }
        // &:nth-of-type(3n) {
        //   margin-right: 0;
        // }
        // width: calc((100% - 48px) / 3);
        // @include octagon(101px);
      }
    }
  }
  .p-service {
    position: relative;
    @include octagon(vw(82));
    &__image {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: 1;
      background-color: #ccc;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &__body {
      background: linear-gradient(180deg, rgba(30, 162, 217, 0) 29.69%, rgba(#1EA2D9, .8) 78.12%);
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      position: relative;
      height: 100%;
      padding: vw(160) vw(32) vw(56);
      z-index: 2;
      min-height: vw(272);
    }
    &__title {
      font-weight: 700;
      font-size: vw(20);
      line-height: 1.8;
      color: #FFFFFF;
      text-shadow: 0px 0px vw(4) rgba(30, 162, 217, 0.2);
      margin-bottom: vw(-9);
    }
    &__number {
      @include roboto;
      font-weight: 700;
      font-size: vw(16);
      margin-bottom: vw(9);
      display: flex;
      align-items: center;
      color: #FFFFFF;
      text-shadow: 0px 0px 4px rgba(30, 162, 217, 0.2);
    }
    &__text {
      display: none;
    }
  }
}