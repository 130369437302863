
  .p-other-contents {
    @include pc {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 24px;
      @include shadow;
      &__item {
        @include octagon(46px);
        background-color: #fff;
        padding: 32px;
        min-height: 432px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        &[href] {
          .p-other-contents__title,
          .p-other-contents__text {
            transition: color .48s $curve_main;
          }
          &:hover {
            .p-other-contents__title,
            .p-other-contents__text {
              color: $blue;
            }
            .c-more {
              &::before {
                @include content;
                transform: scale(1.25);
              }
            }
          }
        }
      }
      &__icon {
        width: 80px;
        height: 80px;
        margin-bottom: 16px;
        margin-top: 4px;
        img {
          width: 80px;
          height: 80px;
        }
      }
      &__title {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 36px;
        
        display: flex;
        align-items: center;
        text-align: center;
        margin-bottom: 16px;
        margin-top: -5px;
      }
      &__text {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 32px;
        align-items: center;
        margin-bottom: 12px;
        margin-top: -6px;
      }
    }
    @include sp {
      display: grid;
      grid-template-rows: repeat(auto);
      gap: vw(48);
      @include shadow;
      margin-left: vw(-8);
      margin-right: vw(-8);
      &__item {
        @include octagon(vw(55));
        background-color: #fff;
        padding: vw(48) vw(32);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      &__icon {
        width: vw(80);
        height: vw(80);
        margin-bottom: vw(4);
        img {
          width: vw(80);
          height: vw(80);
        }
      }
      &__title {
        font-style: normal;
        font-weight: 700;
        font-size: vw(20);
        line-height: 2;
        
        display: flex;
        align-items: center;
        text-align: center;
        margin-bottom: vw(16);
        margin-top: vw(5);
      }
      &__text {
        font-style: normal;
        font-weight: 500;
        font-size: vw(16);
        line-height: 2;
        align-items: center;
        margin-bottom: vw(16);
        margin-top: vw(-9);
        text-align: justify;
      }

      .c-more {
        margin-bottom: vw(2);
        margin-top: vw(2);
      }
    }
  }