// oirei
.c-cards {
  @include pc {
    display: grid;
    gap: 48px;
    grid-template-columns: 1fr 1fr 1fr;
    &--2 {
      grid-template-columns: repeat(2, 1fr);
    }
    &--4 {
      grid-template-columns: repeat(4, 1fr);
      gap: 32px;
      .u-octagon__inner {
        @include octagon(43px);
      }
    }
    &--horiz {
      grid-template-columns: repeat(2, 1fr);
    }
    &--sm {
      .c-card {
        .u-octagon--md > .u-octagon__inner {
          @include octagon(43px);
        }
        &__image {
          margin-bottom: 14px;
        }
        &__title {
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 24px;
        }
        &__subtitle {
          font-size: 14px;
          line-height: 24px;
          margin-bottom: 10px;
          margin-top: -13px;
        }
        &__meta {
          margin-bottom: 12px;
          padding-top: 4px;
        }
        .c-hashtags__item {
          font-size: 12px;
        }
        .c-label {
          font-size: 12px;
          padding: 2px 16px;
        }
      }
    }
  }
  @include sp {
    display: grid;
    gap: vw(48);
    grid-template-rows: repeat(1, auto);
    &--sm {
      .c-card {
        margin-bottom: vw(-1);
        &__image {
          margin-bottom: vw(19);
        }
        &__title {
          font-size: vw(18);
          line-height: vw(32);
          -webkit-line-clamp: 2;
          margin-bottom: vw(8);
        }
        &__subtitle {
          font-size: vw(16);
          line-height: 2;
          margin-bottom: vw(6);
        }
        .c-hashtags__item {
          font-size: vw(12);
        }
        &__meta {
          margin-top: vw(-3);
          margin-bottom: vw(19);
          .c-label {
            padding-top: 0;
            padding-bottom: 0;
            font-size: vw(12);
            font-weight: 500;
          }
        }
      }
    }
    &--4 {
      gap: vw(32);
      .c-card {
        &__row {
          display: grid;
          grid-template-columns: vw(120) auto;
          align-items: center;
          gap: vw(16);
        }

        &__title {
          font-size: vw(16);
          line-height: 1.75;
          margin-bottom: vw(8);
          margin-top: vw(-4);
        }
      }
      .c-card__image {
        margin-right: vw(16);
        aspect-ratio: 1/1;
        width: vw(120);
        height: vw(120);
        margin-bottom: 0;
        .u-octagon {
          width: vw(120);
          height: vw(120);
          &__inner {
            width: vw(120);
            height: vw(120);
            @include octagon(vw(24));
            img {
              height: 100%;
              width: 100%;
              object-fit: cover;
            }
          }
        }
      }
    }
  }
}

.c-card {
  @include pc {
    display: flex;
    flex-direction: column;
    position: relative;
    a {
      transition: color 0.4s $curve_main;
      &:hover {
        color: $blue;
      }
    }
    // &[href]:not(.c-card__row) {
    //   display: -webkit-box;

    //   .c-card {
    //     &__title {
    //       transition: color .4s $curve_main;
    //     }
    //   }
    //   &:hover {
    //     .c-card {
    //       &__title {
    //         color: $blue;
    //       }
    //     }
    //   }
    // }
    &__label {
      position: absolute;
      background-color: #fff;
      padding: 6px 24px;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 2;
      display: flex;
      align-items: center;
      text-align: center;
      background: $blue;
      color: #ffffff;
      top: -8px;
      left: -8px;
      z-index: 5;
    }
    &__subtitle {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 32px;
      /* or 200% */

      display: flex;
      align-items: center;
      margin-bottom: 21px;
      margin-top: -8px;

      // color: #000000;
      // /* main/black */
    }
    &__image {
      margin-bottom: 32px - 8;
      &:last-child {
        margin-bottom: 0;
      }
      .u-octagon {
        width: 100%;
        // border: 1px solid #000;
        &__inner {
          aspect-ratio: 384/288;
          background-color: #ccc;
          will-change: transform;
          backface-visibility: hidden;
          // transform: rotate(1deg);
          img {
            aspect-ratio: 384/288;
            // border: 1px solid #000;
            // height: 200px;
            width: 100%;
            object-fit: cover;
            height: auto;
          }
        }
      }
    }
    &__products {
      dt {
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        margin-top: -10px;
        margin-bottom: 2px;
        color: rgba(0, 0, 0, 0.3);
      }
      dd {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        margin-bottom: -4px;
      }
    }
    // ------------------------------------------------------------
    // meta(date/category label)
    &__meta {
      margin-bottom: 16px;
      align-items: center;
      display: grid;
      grid-template-columns: repeat(2, auto);
      justify-content: start;
      overflow: hidden;
      text-overflow: ellipsis;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &__date {
      @include roboto;
      margin-right: 16px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 32px;
    }
    &__category {
      flex: 1;
      overflow: hidden;
    }
    // .c-label {
    //   border: 4px solid cyan;
    // }

    // ------------------------------------------------------------
    // header(category(c-label)/title)
    &__header {
      margin-bottom: 24px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &__title {
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 40px;
      /* or 200% */

      display: flex;
      align-items: center;
      text-align: justify;

      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      margin-bottom: 24px - 8;

      &:last-child {
        margin-bottom: 0;
      }

      .c-card--variable & {
        overflow: unset;
        display: block;
        -webkit-box-orient: unset;
        -webkit-line-clamp: unset;
      }

      &[href] {
        transition: color 0.4s $curve_main;
        &:hover {
          color: $blue;
        }
      }

      + .c-hashtags {
        margin-top: -8px;
        display: flex;
        // border: 1px solid #000;
      }
    }
    &--horiz {
      .c-card {
        &__row {
          display: grid;
          gap: 24px;
          grid-template-columns: 235px auto;
          align-items: center;
        }
        &__image {
          margin-bottom: 0;
          .u-octagon__inner {
            @include octagon(35px);
          }
        }
      }
    }
  }
  @include sp {
    display: flex;
    flex-direction: column;
    position: relative;
    &__label {
      position: absolute;
      background-color: #fff;
      padding: vw(6) vw(24);
      font-style: normal;
      font-weight: 700;
      font-size: vw(14);
      line-height: 2;
      display: flex;
      align-items: center;
      text-align: center;
      background: $blue;
      color: #ffffff;
      top: vw(-8);
      left: vw(-8);
      z-index: 5;
    }
    &__subtitle {
      font-style: normal;
      font-weight: 500;
      font-size: vw(16);
      line-height: 2;
      display: flex;
      align-items: center;
      margin-bottom: vw(17);
      margin-top: vw(-4);
    }
    &__image {
      margin-bottom: vw(24);
      .u-octagon {
        width: 100%;
        &__inner {
          aspect-ratio: 311/232;
          background-color: #ccc;
          img {
            aspect-ratio: 311/232;
            width: 100%;
            object-fit: cover;
            height: auto;
          }
        }
      }
    }
    &__products {
      dt {
        font-style: normal;
        font-weight: 500;
        font-size: vw(12);
        line-height: vw(20);
        text-align: justify;
        color: rgba(0, 0, 0, 0.3);
        margin-bottom: vw(4);
      }
      dd {
        font-style: normal;
        font-weight: 500;
        font-size: vw(14);
        line-height: vw(20);
        text-align: justify;
      }
    }
    &__meta {
      margin-bottom: vw(22);
      align-items: center;
      display: grid;
      grid-template-columns: repeat(2, auto);
      justify-content: start;
      overflow: hidden;
      text-overflow: ellipsis;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &__date {
      @include roboto;
      margin-right: vw(8);
      font-style: normal;
      font-weight: 500;
      font-size: vw(14);
      line-height: 2;
    }
    .c-data {
      margin-top: vw(2);
    }
    &__category {
      flex: 1;
      overflow: hidden;
    }
    &__title {
      font-style: normal;
      font-weight: 700;
      font-size: vw(20);
      line-height: 2;
      text-align: justify; // ios bug
      @include safari {
        text-align: left;
      }
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      margin-top: vw(-7);
      margin-bottom: vw(12);
      &:last-child {
        margin-bottom: 0;
      }

      .c-card--variable & {
        overflow: unset;
        display: block;
        -webkit-box-orient: unset;
        -webkit-line-clamp: unset;
      }
    }
    &__body {
      // display: inline-flex;
      flex-direction: column;
      display: flex;
      // justify-content: flex-start;
      align-items: flex-start;
    }
    .c-hashtags {
      display: block;
      margin-bottom: vw(10);
      margin-top: vw(2);
      &__item {
        display: inline;
        margin-right: vw(8);
      }
      &:last-child {
        margin-bottom: vw(8);
      }
    }
  }
}
