.level1-heading {
  @include pc {
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 80px;
    text-align: justify;
    color: #000000;
    // margin-top: -28px;
  }
  @include sp {
    font-style: normal;
    font-weight: 700;
    font-size: vw(28);
    line-height: vw(48);
    text-align: justify;
    // margin-top: vw(-11);
  }
}

.level2-heading {
  font-style: normal;
  font-weight: 700;
  @include pc {
    font-size: 32px;
    line-height: 56px;
  }
  @include sp {
    text-align: justify;
    font-size: vw(24);
    line-height: vw(40);
  }
}

.level3-heading {
  font-style: normal;
  font-weight: 700;
  text-align: justify;
  @include pc {
    font-size: 24px;
    line-height: 48px;
  }
  @include sp {
    font-size: vw(20);
    line-height: 2;
  }
}

.level4-heading {
  font-style: normal;
  font-weight: 700;
  @include pc {
    font-size: 20px;
    line-height: 32px;
    margin: -13px 0;
  }
  @include sp {
    font-size: vw(16);
    line-height: vw(32);
    // margin-top: vw(-8);
  }
}


// @include sp {
//   h3 {
//     background-color: #f00 !important;
//     line-height: vw(32) !important;
//   }
//   h4 {
//     background-color: #f00 !important;
//     line-height: vw(30) !important;
//   }
// }