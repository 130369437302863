
// base ------------------------------------------------------------------------
// @include sp {
//   html,
//   body {
//     height: 100%;
//     min-height: 100vh;
//   }
// }
a {
  color: #000;
  text-decoration: none;
}
body {
  // @include yugo;
  -webkit-font-smoothing: antialiased;
  background-color: #fff;
  color: #000;
  background-color: #fff;
  background: rgba(30, 162, 217, 0.2);
  @include noto_sans;

  container-type: inline-size;
  @include pc {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-height: 100vh;
  }
  // @include sp {
  //   // overscroll-behavior: none;
  //   // padding-bottom: vw(72); // sp footer 
  //   // &.is-menu-active {
  //   //   overflow: hidden;
  //   // }
  // }
}
body {
  position: relative;
}
button {
  cursor: pointer;
}
