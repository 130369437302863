// oirei
.c-breadcrumb {
  @include pc {
    background-color: #fff;
    display: flex;
    align-items: center;
    padding: 0 48px;
    z-index: 2;
    position: relative;
    &::before,
    &::after {
      @include content;
      height: 2px;
      position: absolute;
      left: calc(50% - 50cqw);
      right: calc(50% - 50cqw);
      background-color: rgba(30, 162, 217, 0.2);
    }
    &::before {
      top: 0;
    }
    &::after {
      bottom: 0;
    }
    
    &__item {
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 48px;
      color: rgba(0, 0, 0, 0.3);
      
      br {
        display: none;
      }
      
      &::after {
        content: '>';
        display: block;
        width: 8px;
        height: 48px;
        margin: 0 8px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 48px;
        
        color: rgba(0, 0, 0, 0.3);
      }
      &:last-child {
        &::after {
          content: none;
        }
      }
      &[href] {
        font-weight: 700;
        color: $blue;
      }
    }
  }
  @include sp {
    background-color: #fff;
    height: vw(40);
    display: flex;
    overflow: auto;
    white-space: nowrap;
    border-top: vw(2) solid rgba(30, 162, 217, 0.2);
    border-bottom: vw(2) solid rgba(30, 162, 217, 0.2);
    // line-height: vw(40);
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding: vw(8) vw(24) vw(12);
    &::-webkit-scrollbar{
      display:none;
    }
    &__item {
      font-weight: 500;
      font-size: vw(10);
      line-height: 2;
      color: rgba(0, 0, 0, 0.3);

      br {
        display: none;
      }
      &::after {
        content: '>';
        margin: 0 vw(8);
        color: rgba(0, 0, 0, 0.3);
      }
      &:last-child {
        &::after {
          content: none;
        }
      }
      
      &[href] {
        color: $blue;
        font-weight: 700;
      }
    }
  }
}
