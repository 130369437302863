body.page-template-privacy {
  @include pc {
    .l-page-body {
      padding-top: 150px;
      h1 {
        margin-bottom: 3px;
      }
      h2 {
        margin-top: 56px;
        & + p {
          margin-top: 8px;
        }
      }
      // p {
      //   margin-bottom: -8px;
      // }
      ol {
        margin-left: 22px;
        margin-top: 10px;
        li {
          margin-bottom: 13px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      footer {
        margin-top: 80px - 16;
      }
    }
  }
  @include sp {
    .l-page-body {
      padding-top: vw(80);
      h1 {
        margin-top: vw(-8);
      }
      h2 {
        margin-top: vw(64);
      }
      ol {
        margin-left: vw(22);
        margin-top: vw(16);
        li {
          margin-bottom: vw(8);
          line-height: 2;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      footer {
        margin-top: vw(64);
        margin-bottom: vw(-8);
      }
    }
  }
}