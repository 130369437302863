// oirei
// done px sp

.c-other-links  {
  @include pc {
    padding: 24px 0;
    &__item {
      margin-left: 17px;
      font-weight: 500;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.3);
      // &[href] {
      //   @include hover_color;
      // }
    }
  }
  @include sp {
    display: grid;
    justify-content: center;
    grid-template-columns: auto auto;
    gap: vw(32);
    &__item {
      font-style: normal;
      font-weight: 500;
      font-size: vw(14);
      line-height: 2;
      display: flex;
      align-items: center;
      color: rgba(0, 0, 0, 0.3);
    }
  }
}