


// done px pc
.p-widget-download {
  // filter: drop-shadow(0px 0px 24px rgba(0, 0, 0, 0.1));
  @include shadow;
  @include hover_color($green);
  &__border {
    display: block;
    @include octagon(42px);
    background: #b4deaa;
    padding: 2px;
    margin-bottom: 41px;
  }
  &__inner {
    @include octagon(40.5px);
    background: #EAF5EB;
    padding: 30px;
  }
  // border: 2px solid $green;
  &__thumbnail {
    background-color: #ccc;
    @include octagon(42px);
    aspect-ratio: 288/216;
    margin-bottom: 35px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__title {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    text-align: justify;
    margin-bottom: 24px - 7;
    
  }
  &__text {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    margin-bottom: -8px;
  }
}