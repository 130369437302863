.c-toasts {
  @include shadow;
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 9999;
  color: #fff;
  &__inner {
    background-color: $blue;
    @include octagon_lt(42px);
  }

  &__opened {
    width: 420px;
    position: absolute;
    right: 0;
    bottom: 0;
    transform: translateY(100%);
    transition: transform .4s $curve_main;
    .is-open & {
      transform: translateY(0);
    }
    .is-open & {
      display: block;
    }
    .c-toasts {
      &__inner {
        padding: 48px;
      }
      &__close {
        width: 32px;
        height: 32px;
        position: absolute;
        top: 12px;
        right: 12px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          transition: background-color .4s $curve_main;
          width: 24px;
          height: 24px;
          border-radius: 50%;
          circle, rect {
            transition: fill .4s $curve_main;
          }
          path {
            transition: stroke .4s $curve_main;
          }
        }
        &:hover {
          svg {
            background-color: #fff;
            circle {
              fill: #fff;
            }
            rect {
              fill: $blue;
            }
            path {
              stroke: $blue;
            }
          }
        }
      }
      &__title {
        font-style: normal;
        text-align: justify;
        margin-bottom: 11px;
        font-weight: 700;
        font-size: 20px;
        line-height: 40px;
        margin-top: -7px;
      }
      &__text {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
      }
    }
    .btn {
      margin-top: 17px;
      border-color: #fff;
      height: 48px;
      padding: 0;
      &__text {
        font-weight: 600;
      }
    }
    .c-data {
      margin-top: 21px;
    }
  }
  &__closed {
    width: 420px;
    cursor: pointer;
    transform: translateY(0);
    transition: transform .4s $curve_main;
    position: absolute;
    right: 0;
    bottom: 0;
    .is-open & {
      transform: translateY(100%);
    }
    .c-toasts {
      &__inner {
        padding: 32px;
      }
      &__title {
        font-style: normal;
        font-weight: 700;
        text-align: justify;
        font-weight: 700;
        font-size: 16px;
        line-height: 28px;
        margin-top: -2px;
      }
      &__open {
        @include content;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        // border: 2px solid #fff;
        position: absolute;
        top: 32px;
        right: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: background-color .4s $curve_main;
        svg {
          width: 24px;
          height: 24px;
          circle, rect {
            transition: fill .4s $curve_main;
          }
          path {
            transition: stroke .4s $curve_main;
          }
        }
      }
    }
    .c-data {
      // margin-top: -2px;
      margin-top: 7px;
      margin-bottom: 1px;
      grid-template-rows: unset;
    }
    &:hover {
      .c-toasts__open {
        background-color: #fff;
        circle {
          fill: #fff;
        }
        rect {
          fill: $blue;
        }
        path {
          stroke: $blue;
        }
      }
    }
  }
  @include sp {
    display: none;
  }
}
