.l-author {

  @include pc {
    margin-top: 48px;
    .level3-heading {
      margin-bottom: 24px;
    }
  }
  @include sp {
    margin-top: vw(80);
    .level3-heading {
      margin-bottom: vw(34);
      font-size: vw(24);
    }
    .c-user {
      margin-top: vw(24);
      margin-left: vw(-8);
      margin-right: vw(-8);
    }
  }
}