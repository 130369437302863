body.page-template-thanks,
body.page-template-inquiry {
  .l-footer {
    padding-top: 0;
    @include sp {
      padding: vw(32 - 6);
    }
  }
  .l-header__logo {
    pointer-events: none;
  }
  .l-header__open {
    display: none;
  }
  .l-header__dropdown {
    display: none;
  }
  .l-footer__row--main {
    display: none;
  }
  .l-footer__row--sub {
    justify-content: center;
  }
  .c-other-links {
    display: none;
  }
  .l-header__button {
    display: none;
  }
  .l-page-body {
    @include pc {
      padding-bottom: 240px;
      margin-bottom: 0;
    }
    @include sp {
      padding-bottom: vw(160);
    }
  }

  @include sp {
    padding-bottom: 0;
    .p-sp-footer {
      display: none;
    }
  }
}

body.page-template-thanks {
  @include pc {
    .p-thanks {
      padding-bottom: 240px;
      padding-top: 206px;
      .l-container {
        display: block;
        &--sm {
          margin-top: -16px;
        }
      }
      &__subtitle {
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 64px;
        margin-bottom: 8px;
        /* または200% */
        
        display: flex;
        align-items: center;
        text-align: justify;
        
      }
      &__qr {
        float: right;
        margin-left: 48px;
        width: 216px;
        height: 216px;
        img {
          width: 216px;
          height: 216px;
          object-fit: cover;
        }
      }
      &__text {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 32px;
        /* または200% */
        
        display: flex;
        align-items: center;
        text-align: justify;
        margin-bottom: 24px;
        
      }
      .btn {
        width: 290px;
      }
    }
  }
  @include sp {
    .p-thanks {
      &__content {
      }
      &__subtitle {
        font-style: normal;
        font-weight: 700;
        font-size: vw(24);
        line-height: vw(40);
        margin-bottom: vw(18);
        margin-top: vw(-16);
        
        display: flex;
        align-items: center;
        text-align: justify;
      }
      &__text {
        font-style: normal;
        font-weight: 500;
        font-size: vw(16);
        line-height: 2;
        display: flex;
        align-items: center;
        text-align: justify;
        margin-bottom: vw(24);
      }
      &__qr {
        display: none;
      }
    }
  }
}
