@include pc {
  .u-action {
    &--frost {
      // cursor: pointer;
      .u-action--target {
        position: relative;
        img {
          transition: transform 1.5s $ease-out-expo;
        }
        &::after {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 10;
          // background: green;

          // background: linear-gradient(
          //   45deg,
          //   rgba(30, 162, 217, 0) 0%,
          //   rgba(30, 162, 217, 0.2) 50%,
          //   rgba(30, 162, 217, 0) 100%
          // );

          background: linear-gradient(
            -30deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0) 25%,
            rgba(#fff, 0.4) 50%,
            rgba(255, 255, 255, 0) 75%,
            rgba(255, 255, 255, 0) 100%
          );
          background-position: left 100% top;
          mix-blend-mode: luminosity;
          background-size: 800% auto;
          background-position: 0%;
          background-position: left 100% top;
          pointer-events: none;
        }
        // animation: mirror 2s $ease-out-expo 0s 1 normal forwards ;
      }
      &:hover {
        .u-action--target {
          img {
            transform: scale(1.04);
          }
          &::after {
            background-position: 0%;
            transition: background-position 2.71828s $ease-out-expo;
            // animation: mirror 2s $ease-out-expo 0s 1 normal forwards ;
            // animation: name duration timing-function delay iteration-count direction fill-mode;
          }
        }
      }
    }
  }
  // * {
  //   &::after {background-attachment: fixed;}
  // }

  @keyframes mirror {
    0% {
      background-position: left 100% top;
    }
    100% {
      background-position: 0%;
    }
  }
}
