body.single-press {

  
  @include pc {
    .l-page-body .p-page-title {
      margin-bottom: 69px;
    }
    .c-panel {
      margin-top: 72px;
      & + h2 {
        margin-top: 160px - 16;
      }
    }
    .p-press-inquiry {
      margin-top: 8px;
      margin-bottom: -8px;
      margin-top: 149px;
      a {
        font-weight: bold;
      }
      p {
        margin-top: 12px;
      }
    }
  }
  @include sp {
    .p-page-title {
      margin-bottom: vw(40);
    }
    .p-page-title__meta {
      margin-bottom: vw(18);

    }
    .p-press-inquiry {
      margin-top: vw(8);
      margin-bottom: vw(-1);
      margin-top: vw(152);
      a {
        font-weight: bold;
      }
      p {
        margin-top: vw(16);
      }
    }
    .c-panel {
      margin-top: vw(72);
      & + h2 {
        margin-top: vw(160 - 8);
      }
    }
  }
}

body.post-type-archive-press {
  @include sp {
    // .l-articles {
    //   border: 1px solid #000;
    // }
  }
}