
// column / column category
body.category-column {
  @include pc {
    .p-octobg--archive {
      margin-top: -480px;
    }
    .l-recommend {
      max-width: 1680px;
      margin: 0 auto;
      margin-bottom: 160px;
      &__body {
        padding: 160px 80px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      &__header {
        margin-bottom: 80px;
      }
      &__title {
        color: $blue;
        text-align: center;
        margin-bottom: -15px;
      }
      .u-octagon__background {
  
        @include pc {
          @include octobox_padding(48px);
        }
      }
      .c-cards {
        max-width: 1200px;
      }
    }
    .p-searchbox {
      margin-bottom: 80px;
    }
    // メインカラムwidget非表示
    .l-blog-col:not(.l-sidemenu) {
      .c-widget {
        display: none;
      }
    }

  }
  @include sp {

    // @include sp-body-background-gradient(4568);
    .l-archive-header {
      margin-bottom: vw(160);
      position: relative;
      z-index: 6;
    }
    .l-recommend {
      margin: 0 auto vw(160);
      &__body {
        padding: vw(120) vw(38);
        // padding: 160px 80px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      &__header {
        margin-bottom: vw(72);
      }
      &__title {
        color: $blue;
        text-align: center;
        margin-top: vw(-8);
      }
    }
    .l-sidemenu {
      display: none;
      // border: 1px solid #000;
      .c-widget {
        margin-bottom: 0;
      }
    }
    .p-searchbox {
      margin-bottom: vw(41);
    }
    .c-widget {
      margin-bottom: vw(160);
      &--popular-posts {
        display: none;
      }
    }
  }
}
