
.p-searchbox {
  @include pc {
    width: 100%;
    &__form {
      margin-bottom: 24px;
      display: grid;
      grid-template-columns: auto 144px;
      gap: 16px;
    }
    &__input {
      // height: 56px;
      background: #FFFFFF;
      border: 2px solid #1EA2D9;
      border-radius: 80px;
      padding: 10px 24px;
      font-weight: 600;
      font-size: 16px;
      line-height: 32px;
      /* or 200% */
      &::placeholder {
        color: rgba(0, 0, 0, 0.3);
      }
    }
    &__button {
      .btn__text {
        font-weight: 600;
      }
      .btn__icon {
        width: 32px;
        height: 32px;
        margin-right: 8px;
      }
    }
  }
  @include sp {
    width: 100%;
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    &__form {
      margin-bottom: vw(40);
      position: relative;
      margin-left: vw(-8);
      margin-right: vw(-8);
      // width: auto;
      // width: 100%;
    }
    &__input {
      // height: 56px;
      background: #FFFFFF;
      border: 2px solid #1EA2D9;
      border-radius: vw(80);
      padding: vw(14) vw(24 + 56 + 8) vw(14) vw(24);
      font-weight: 600;
      font-size: vw(16);
      line-height: vw(24);
      width: 100%;
      box-sizing: border-box;
      /* or 200% */
      &::placeholder {
        color: rgba(0, 0, 0, 0.3);
      }
    }
    &__button {
      @include __clear-button-styles;
      position: absolute;
      width: vw(56);
      height: vw(56);
      right: vw(8);
      top: 0;
      z-index: 2;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      .btn__icon {
        width: vw(24);
        height: vw(24);
        margin: 0;
        display: block;
        path {
          fill: $blue;
        }
        // margin-right: 8px;
      }
      .btn__text {
        display: none;
      }
    }
  }
}

.c-suggests {
  @include pc {
    &__title {
      font-weight: 700;
      font-size: 14px;
      line-height: 28px;
      /* or 200% */
      
      display: flex;
      align-items: center;
      
      /* main/blue */
      
      color: #1EA2D9;
      margin-bottom: 2px;
      
    }
    &__list {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
    }
    &__item {
      font-weight: 500;
      font-size: 14px;
      line-height: 32px;
      /* identical to box height, or 229% */
      
      display: flex;
      align-items: center;
      margin-right: 8px;
      &::before {
        content: '#';
        margin-right: 2px;
      }
      
    }
  }
  @include sp {
    margin-bottom: vw(-17);
    &__title {
      font-weight: 700;
      font-size: vw(16);
      line-height: 2;
      display: flex;
      align-items: center;
      color: #1EA2D9;
      margin-bottom: vw(8);
      
    }
    &__list {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
    }
    &__item {
      font-weight: 500;
      font-size: vw(14);
      line-height: vw(32);
      display: flex;
      align-items: center;
      margin-right: vw(16);
      margin-bottom: vw(8);
      &::before {
        content: '#';
        margin-right: vw(3);
        margin-left: vw(1);
      }
    }
  }
}