// oirei

.c-more {
  @include pc {
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    /* identical to box height, or 200% */
    
    display: flex;
    align-items: center;
    text-align: right;
    
    /* main/blue */
    
    color: #1EA2D9;
    position: relative;
    
    
    &::after {
      @include content;
      width: 24px;
      height: 24px;
      margin-left: 8px;
      background: imgurl('icon--more24.svg') center/contain no-repeat;
      z-index: 2;
    }
    &::before {
      @include content;
      width: 24px;
      height: 24px;
      background-color: #1EA2D9;
      border-radius: 50%;
      position: absolute;
      right: 0;
      z-index: 1;
      transition: transform .48s $curve_main;
      will-change: transform;
    }
    &--md {
      font-size: 16px;
      &::after {
        width: 32px;
        height: 32px;
        background: imgurl('icon--more32.svg') center/contain no-repeat;
      }
      &::before {
        width: 32px;
        height: 32px;
      }
    }
    &--lg {
      font-size: 20px;
      line-height: 40px;
      &::after {
        width: 40px;
        height: 40px;
        background: imgurl('icon--more.svg') center/contain no-repeat;
      }
      &::before {
        width: 40px;
        height: 40px;
      }
    }
    &--inline {
      display: inline-flex;
    }
    &:hover {
      &::before {
        @include content;
        transform: scale(1.25);
      }
    }
  }
  @include sp {
    font-weight: 700;
    font-size: vw(16);
    line-height: vw(28);
    display: flex;
    align-items: center;
    text-align: right;
    
    /* main/blue */
    
    color: #1EA2D9;
    position: relative;
    
    
    &::after {
      @include content;
      width: vw(24);
      height: vw(24);
      margin-left: vw(8);
      background: imgurl('icon--more_sp.svg') center/contain no-repeat;
      z-index: 2;
      background-color: #1EA2D9;
      border-radius: 50%;
      flex-shrink: 0;
    }
    // &::before {
    //   @include content;
    //   width: vw(24);
    //   height: vw(24);
    //   background-color: #1EA2D9;
    //   border-radius: 50%;
    //   position: absolute;
    //   right: 0;
    //   z-index: 1;
    // }
  }
}