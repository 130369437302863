
// oirei

// done px pc
.c-toc {
  @include pc {
    @include shadow;
    &__inner {
      padding: 88px;
      @include octagon(120px);
      background-color: #fff;
      display: flex;
      flex-direction: column;
    }
    &__title {
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 40px;
      /* or 200% */
  
      display: flex;
      align-items: center;
  
      color: $blue;
      margin-bottom: 16px;
      margin-top: -6px;
    }
    a {
      text-decoration: none;
      color: #000;
      transition: color .4s $curve_main;
      &:hover {
        color: $blue;
      }
    }
    &__level2 {
      border-bottom: 2px solid rgba(30, 162, 217, 0.2);
      margin-top: 24px;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      padding-bottom: 6px;
      line-height: 2;
      &:first-of-type {
        margin-top: 0;
      }
    }
    &__level3 {
      margin-top: 0;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
  
      margin-top: 16px;
    }
  }
  @include sp {
    @include shadow;
    margin-left: vw(-8);
    margin-right: vw(-8);
    &__inner {
      padding: vw(80) vw(32) vw(72);
      @include octagon(vw(55.4));
      background-color: #fff;
      display: flex;
      flex-direction: column;
    }
    &__title {
      font-style: normal;
      font-weight: 700;
      font-size: vw(20);
      line-height: 2;
      display: flex;
      align-items: center;
  
      color: $blue;
      margin-bottom: vw(31);
      margin-top: vw(-7);
    }
    a {
      text-decoration: none;
      color: #000;
    }
    &__level2 {
      border-bottom: 2px solid rgba(30, 162, 217, 0.2);
      margin-top: vw(30);
      font-style: normal;
      font-weight: 500;
      font-size: vw(18);
      line-height: 2;
      padding-bottom: vw(14);
      text-align: justify;
      margin-bottom: vw(8);
      &:first-of-type {
        margin-top: 0;
      }
    }
    &__level3 {
      margin-top: 0;
      font-style: normal;
      font-weight: 500;
      font-size: vw(16);
      line-height: 2;
  
      margin-top: vw(16);
      text-align: justify;
    }
  }
}
