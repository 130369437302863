
@import "component/_btn";

.u-wp-content {
  font-style: normal;
  font-weight: 500;
  line-height: 2;
  // overflow: hidden;
  @include pc {
    font-size: 16px;
  }
  @include sp {
    font-size: vw(16);
    text-align: justify;
  }

  .c-more {
    @include pc {
      display: inline-flex;
      text-decoration: none;
      margin-top: 18px;
      &::after {
        margin-left: 8px;
      }
      &:hover {
        opacity: 1;
      }
      br {
        display: none;
      }
    }
    @include sp {
      display: inline-flex;
      text-decoration: none;
      width: 100%;
      justify-content: center;
      text-align: left;
      margin-top: vw(16);
      margin-bottom: vw(7);
      &:last-child {
        margin-bottom: 0;
      }
      &::after {
        margin-left: vw(16);
      }
    }
  }

  .c-panel {
    @include pc {
      margin-top: 24px;
    }
    @include sp {
      min-width: 100%;
      max-width: unset;
      width: auto;
      margin-top: vw(24);
    }
  }

  div {
    // @include pc {
    //   margin-top: 48px;
    // }
    @include sp {
      // max-width: 100%;
      max-width: 100%;
      // margin-top: vw(48);
    }
  }

  p {
    @include pc {
      margin-top: 24px;
      &:first-child {
        margin-top: 4px;
        // background-color: #f99;
      }
      & + p {
        margin-top: 8px;
      }
    }
    @include sp {
      margin-top: vw(16);
      &:first-child {
        margin-top: vw(4);
      }
    }
  }
  br {
    + p {
      @include sp {
        margin-top: 0;
      }
    }
  }
  .btn {
    @extend .btn--primary;
    @extend .btn--md;
    text-align: center;
    text-decoration: none;
    color: #fff;
    @include pc {
      width: 320px;
      transition: all .4s $curve_main;
      margin: 0 auto;
      &:hover {
        color: $blue;
      }
    }
    @include sp {
      width: 100%;
      @include btn_sp;
      font-size: vw(16);
      line-height: vw(60);
      font-weight: 700;
      color: #fff;
    }
  }
  a {
    text-decoration: underline;
    color: $blue;
    @include pc {
      transition: opacity 0.3s $ease-out-cubic;
      &:hover {
        opacity: 0.75;
      }
    }
    @include sp {
      word-break: break-all;
    }
  }
  $border: #dcdcdc;

  .wp-block-group {
    &.has-background {
      .wp-block-group__inner-container {
        padding: 2rem 3rem;
        @include sp {
          padding: 1.5rem;
        }
      }
      // .wp-block-column {
      //   // padding: 2rem;
      // }
      // padding: 4rem;
    }
  }
  h1:not(.c-card__title) {
    @extend .level1-heading;
    &:first-child {
      margin-top: 0;
    }
  }
  h2 {
    &:first-child {
      margin-top: 0;
    }
    font-style: normal;
    font-weight: 700;
    @include pc {
      font-size: 32px;
      margin-top: 80px;
      line-height: 56px;
    }
    @include sp {
      text-align: justify;
      // margin-top: vw(-8);
      font-size: vw(24);
      line-height: vw(40);
      margin-top: vw(48);
      margin-top: vw(72);
      // @include dev(red);
    }
  }
  p + h2 {
    @include sp {
      margin-top: vw(72-8);
    }
  }
  h4 {
    font-style: normal;
    font-weight: 700;
    display: flex;
    align-items: center;
    text-align: justify;
    border-bottom: 1px solid #000;

    @include pc {
      margin-top: 33px;
      padding-bottom: 6px;
      font-size: 20px;
      line-height: 32px;
      margin-bottom: -8px;
    }
    @include sp {
      margin-top: vw(33);
      padding-bottom: vw(6);
      font-size: vw(20);
      margin-bottom: vw(-8);
      line-height: vw(30);
    }
  }
  h5 {
    font-style: normal;
    font-weight: 700;
    /* または200% */
    text-align: justify;
    display: flex;
    align-items: center;
    &::before {
      @include content;
      width: 12px;
      height: 2px;
      flex: 12px 0 0;
      background-color: #000;
      margin-right: 8px;
    }

    @include pc {
      font-size: 16px;
      line-height: 32px;
      margin-top: 24px;
      margin-bottom: -24px;
    }
    @include sp {
      font-size: vw(16);
      line-height: vw(32);
      margin-top: vw(24);
      margin-bottom: vw(-24);
    }
  }
  h3,
  h6 {
    font-style: normal;
    display: flex;
    align-items: center;
    text-align: justify;
    
    @include pc {
      margin-top: 39px;
      font-weight: 700;
      font-size: 24px;
      line-height: 40px;
      margin-bottom: -8px;
    }

    @include sp {
      margin-top: vw(33);
      font-weight: 700;
      font-size: vw(20);
      line-height: vw(32);
      margin-bottom: vw(-9);
    }

    /* main/blue */

    // color: #1EA2D9;
    &:first-child {
      margin-top: 0;
    }
    & + p {
      @include pc {
        margin-top: 17px;
      }
    }
  }
  embed,
  iframe,
  img {
    max-width: 100%;
    height: auto;
    @include pc {
      margin-top: 24px;
    }
    @include sp {
      margin-top: vw(24);
    }
  }
  // iframe {
  //   border: 1px solid #000;
  // }
  .wp-block-image {
    width: 100%;
    @include pc {}
      margin-top: 24px;
    @include sp {
      margin-top: vw(32);
    }
    img {
      width: 100%;
    }
  }
  hr {
    clear: both;
    height: 0px;
    border: 0;
    border-bottom: 2px solid #B8E2F3;
    width: 100%;
    @include pc {
      margin: 35px 0 0;
    }
    @include sp {
      border-width: vw(2);
      margin-top: vw(32);
    }
  }
  em {
    font-weight: normal;
    font-style: italic;
  }
  b, strong {
    font-weight: 700;
  }
  table {
    @include pc {
      width: 100%;
      margin-bottom: 40px;
    }
    @include sp {
      min-width: 100%;
      overflow: scroll;
      margin-bottom: vw(32);
    }
  }
  // table{
  //   @include pc{
  //     width: 100%;
  //     margin-bottom: 40px;
  //   };
  //   @include sp{
  //     min-width: 100%;
  //     overflow: scroll;
  //     margin-bottom: vw(40);
  //   };
  //   th{
  //
  //     font-weight: bold;
  //     text-align: left;
  //     border-bottom: 1px solid $border;
  //     line-height: 1.7;
  //     @include pc{
  //       // padding: 20px 20px 20px 0;
  //     };
  //     @include sp{
  //       // border-width: vw(2);
  //       // padding: vw(10) vw(10) vw(10) 0;
  //     };
  //     &:last-of-type{
  //       padding-right: 0;
  //     }
  //   }
  //   td{
  //     border-bottom: 1px solid $border;
  //
  //     line-height: 1.7;
  //     @include pc{
  //       min-width: 5rem;
  //       // padding: 20px 20px 20px 0;
  //     };
  //     @include sp{
  //       border-width: vw(2);
  //       min-width: 3rem;
  //       // padding: vw(10) vw(10) vw(10) 0;
  //     };
  //     &:last-of-type{
  //       padding-right: 0;
  //     }
  //   }
  // }
  ul:not(.notice):not(.c-checklist):not(.c-list) {
    @include pc {
      padding-left: 0;
      margin-left: 0;
      margin-top: 20px;
      &:first-child {
        margin-top: 0;
      }
      li {
        padding-left: 15px;
        display: flex;
        align-items: flex-start;
        position: relative;
        margin-bottom: 9px;
        &::before {
          position: absolute;
  
          left: 0;
          flex: 8px 0 0;
          display: block;
          content: '';
          background-color: $blue;
          border-radius: 50%;
          
          margin-right: 7px;
          margin-top: 12px;
          width: 8px;
          height: 8px;
        }
        
        &:last-child {
          margin-bottom: 0;
          padding-bottom: 0;
        }
      }
    }
    @include sp {
      padding-left: 0;
      margin-left: 0;
      margin-top: vw(20);
      &:first-child {
        margin-top: 0;
      }
      li {
        padding-left: vw(15);
        display: flex;
        align-items: flex-start;
        position: relative;
        margin-bottom: vw(8);
        &::before {
          position: absolute;
  
          left: 0;
          flex: vw(8) 0 0;
          display: block;
          content: '';
          background-color: $blue;
          border-radius: 50%;
          
          margin-right: 10px;
          margin-top: vw(12);
          width: vw(8);
          height: vw(8);
        }
      }
    }
  }

  ul.c-list {
    @include pc {
      padding-left: 0;
      margin-left: 0;
      margin-top: 20px;
      margin-bottom: -9px;
      &:first-child {
        margin-top: 0;
      }
      li {
        padding-left: 15px;
        display: flex;
        align-items: flex-start;
        position: relative;
        &::before {
          position: absolute;
  
          left: 0;
          flex: 8px 0 0;
          display: block;
          content: '';
          background-color: $blue;
          border-radius: 50%;
          
          margin-right: 7px;
          margin-top: 12px;
          width: 8px;
          height: 8px;
        }
        
        &:last-child {
          margin-bottom: 0;
          padding-bottom: 0;
        }
      }
    }
    @include sp {
      padding-left: 0;
      margin-left: 0;
      margin-top: vw(20);
      &:first-child {
        margin-top: 0;
      }
      li {
        padding-left: vw(15);
        display: flex;
        align-items: flex-start;
        position: relative;
        margin-bottom: vw(8);
        &::before {
          position: absolute;
  
          left: 0;
          flex: vw(8) 0 0;
          display: block;
          content: '';
          background-color: $blue;
          border-radius: 50%;
          
          margin-right: 10px;
          margin-top: vw(12);
          width: vw(8);
          height: vw(8);
        }
      }
    }
  }
  small {
    font-size: 14px;
  }
  ul.notice {
    @include pc {
      padding-left: 0;
      margin-left: 0;
      margin-top: 20px;
      font-size: 14px;
      &:first-child {
        margin-top: 0;
      }
      li {
        padding-left: 15px;
        // display: flex;
        // align-items: flex-start;
        position: relative;
        margin-bottom: 0px;
        &::before {
          position: absolute;
  
          left: 0;
          flex: 8px 0 0;
          display: block;
          content: '※';
          margin-right: 4px;
          font-size: 14px;
        }
      }
    }
    @include sp {
      margin-top: vw(20);
      font-size: vw(14);
      &:first-child {
        margin-top: 0;
      }
      li {
        padding-left: vw(18);
        line-height: vw(24);
        margin-bottom: vw(8);
        position: relative;
        &:last-child {
          margin-bottom: 0;
        }
        &::before {
          position: absolute;
  
          left: 0;
          flex: vw(9) 0 0;
          display: block;
          content: '※';
          font-size: vw(14);
        }
      }
    }
  }
  ol {
    list-style: decimal;
  }
  ol {
    &:last-child {
      margin-bottom: 0;
    }
    @include pc {
      margin-top: 20px;
      margin-bottom: 40px;
      margin-left: 30px;
    }
    @include sp {
      margin-left: 2em;
      margin-bottom: vw(40);
    }
    li {
      line-height: 1.7;

      @include pc {
        margin-bottom: 5px;
      }
      @include sp {
        margin-bottom: vw(5);
      }
    }
  }

  dl:not(.is-disc) {
    @include pc {
      display: grid;
      grid-template-columns: 31.304347832% auto;
      border-bottom: 2px solid rgba(30, 162, 217, 0.2);
  
      dt {
        font-weight: 700;
        color: $blue;
        text-align: justify;
  
  
        padding-right: 48px;
        border-top: 2px solid rgba(30, 162, 217, 0.2);
        padding: 21px 48px 19px 0;
        font-size: 20px;
        line-height: 40px;
        
      }
      dd {
        border-top: 2px solid rgba(30, 162, 217, 0.2);
        padding: 21px 0 19px;
      }
    }
    @include sp {
      dt {
        font-style: normal;
        font-weight: 700;
        font-size: vw(20);
        line-height: 2;
        border-bottom: vw(2) solid rgba(30, 162, 217, 0.2);
        padding-bottom: vw(14);
        color: $blue;
        margin-bottom: vw(28);
      }
      dd {
        margin-bottom: vw(48 - 12);
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .wp-block-gallery {
    margin-left: 0;
  }
  q,
  cite {
    font-style: italic;
  }
  code {
    font-family: "monospace";
    background: #eee;
    color: #333;
    letter-spacing: 0;
    // background: #0f0;
  }
  pre,
  code,
  var,
  samp,
  kbd,
  .mono {
    font-family: Consolas, "Courier New", Courier, Monaco, monospace;
    line-height: 1.2;
    letter-spacing: 0;
    margin-top: 1.5rem;
  }
  pre {
    overflow: scroll;
    margin-bottom: 2em;
    background: #eee;
    line-height: 1.2;
    padding: 1em;
    font-size: 0.9em;
  }
  sup {
    vertical-align: super;
    font-size: smaller;
  }
  sub {
    vertical-align: sub;
    font-size: smaller;
  }
  blockquote {
    font-style: italic;
    * {
      &:last-child {
        margin-bottom: 0;
      }
    }
    @include pc {
      @include octagon(42px);
      padding: 88px;
      background-color: #fff;
      margin-top: 48px;
      font-size: 16px;
    }
    @include sp {
      margin-top: vw(32);
      padding: vw(80) vw(32);
      margin-bottom: vw(32);
      background-color: #fff;
      @include octagon(vw(55));
      font-size: vw(16);
      line-height: 2;
      margin-left: vw(-8);
      margin-right: vw(-8);
    }
  }
  .alignleft {
    float: left;
    display: block;
    clear: both;
    @include pc {
      margin-right: 20px;
      margin-top: 10px;
      margin-bottom: 20px;
      max-width: 440px;
    }
    @include sp {
      margin-right: vw(20);
      margin-top: vw(10);
      margin-bottom: vw(20);
    }
  }
  .alignright {
    float: right;
    display: block;
    clear: both;
    @include pc {
      margin-left: 20px;
      margin-top: 10px;
      margin-bottom: 20px;
    }
    @include sp {
      margin-left: vw(20);
      margin-top: vw(10);
      margin-bottom: vw(20);
    }
  }
  .aligncenter {
    // margin-bottom: 20px;
    text-align: center;
    display: block;
    clear: both;
    @include pc {
      margin: 0 auto 20px;
    }
    @include sp {
      margin: 0 auto vw(20);
    }
  }
  .alignnone {
    clear: both;
  }
  figure {
    figcaption {
      line-height: 1.7;
      text-align: center;
      opacity: 0.3;
      @include pc {
        font-size: 14px;
        margin-top: 20px;
      }
      @include sp {
        margin-top: vw(10);
        margin-bottom: vw(20);
        font-size: vw(14);
      }
    }
    &.is-liquid {
      img {
        width: 100%;
      }
    }
  }

  ul.c-checklist {
    @include pc {
      list-style: none;
      margin-left: 0;
      margin-top: 13px;
      li {
        display: flex;
        margin-bottom: 9px;
        &:last-child {
          margin-bottom: 0;
        }
        &::before {
          flex: 32px 0 0;
          margin-right: 8px;
          @include content;
          width: 32px;
          height: 32px;
          background: url('#{$images}/icon--check.svg') center no-repeat;
          // margin-top: -3px;
        }
      }
    }
    @include sp {
      list-style: none;
      margin-left: 0;
      margin-top: vw(35);
      margin-bottom: vw(-4);
      li {
        display: flex;
        margin-bottom: vw(24);
        &:last-child {
          margin-bottom: 0;
        }
        &::before {
          flex: vw(24) 0 0;
          margin-right: vw(8);
          @include content;
          width: vw(24);
          margin-top: vw(4);
          height: vw(24);
          background: url('#{$images}/icon--check_sp.svg') center/contain no-repeat;
          // margin-top: -3px;
        }
      }
    }
  }

  .c-image-list {
    @include pc {
      margin-top: 24px;
      &__item {
        margin: 0;
      }
    }
    @include sp {
      margin-top: vw(24);
      &__item {
        margin: 0;
      }
    }
  }




  .c-users {
    img {
      margin-top: 0;
    }
    @include pc {
      margin-top: 24px;
    }
  }
  .c-user {
    @include pc {
      &__title {
        font-weight: 700;
        font-size: 20px;
        line-height: 40px;
        white-space: nowrap;
        margin-bottom: 0;
      }
      &__text {
        margin-top: 0;
      }
    }
  }
}
