// oirei

// size
// default 100% max1680
// md 1440


hr.dev {
  margin: 80px 0;
}

.l-section {
  @include pc {
    position: relative;
    &__octobox {
      margin: 0 auto;
      max-width: 1680px;
      &--md {
        max-width: 1440px;
        // .u-octagon__inner {
        //   @include octagon(244px);
        // }
        > .u-octagon__inner {
          @include octagon(244px);
        }
        .u-octagon__background {
          @include octobox_padding(120px);
          &::after {
            @include octagon(217px, 1);
          }
        }
      }
    }
    &__body {
      // border: 1px solid #000;
      // padding: 240px 120px ;
      max-width: 1440px;
      padding: 240px 120px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
  
    
    // background: linear-gradient(
    //     180deg,
    //     rgba(30, 162, 217, 0) 0%,
    //     rgba(30, 162, 217, 0.2) 50%,
    //     rgba(30, 162, 217, 0) 100%
        
    //   );
  
    &__header {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 120px;
      color: $blue;
    }
    &__title {
      margin-bottom: 48px;
      display: flex;
      flex-direction: column;
      align-items: center;
      &-ja {
        font-style: normal;
        font-weight: 700;
        font-size: 48px;
        line-height: 52px;
      }
      &-en {
        @include roboto;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 32px;
        text-transform: uppercase;
        margin-top: 5px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    &__lead {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 40px;
      /* or 200% */
      
      display: flex;
      align-items: center;
      text-align: center;
      
      margin-top: -14px;
      margin-bottom: -10px;
      /* main/black */
      
      color: #000000;
      
    }
  }
  @include sp {
    position: relative;
    // &__octobox {
    //   // margin: 0 auto;
    // }
    &__body {
      // max-width: 1440px;
      padding: vw(120) vw(32);
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
  
    
    background: linear-gradient( 180deg, rgba(30, 162, 217, 0) 0%, rgba(30, 162, 217, 0.2) 50%, rgba(30, 162, 217, 0) 100% );
  
    &__header {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: vw(80);
    }
    &__title {
      color: $blue;
      margin-bottom: vw(32);
      display: flex;
      flex-direction: column;
      align-items: center;
      &-ja {
        font-style: normal;
        font-weight: 700;
        font-size: vw(32);
        line-height: 2;
        margin-top: vw(-16);
        
      }
      &-en {
        @include roboto;
        font-style: normal;
        font-weight: 700;
        font-size: vw(14);
        line-height: vw(28);
        text-transform: uppercase;
        margin-top: vw(-5);
      }
      &:last-child {
        margin-bottom: vw(-6);
      }
    }
    &__lead {
      font-style: normal;
      font-weight: 500;
      font-size: vw(16);
      line-height: 2;
      margin-top: vw(-14);
      text-align: justify;
      margin-bottom: vw(-8);
    }
  }
}
