body.single-works {
  @include pc {
    .l-single-works {
      .p-detail-info {
        margin-bottom: 240px;
      }
      .c-toc {
        margin-bottom: 80px;
      }
    }
  }
  @include sp {
    .l-single-works {
      .p-detail-info {
        margin-bottom: vw(160);
      }
      .c-toc {
        margin-bottom: vw(160 - 7);
      }
      &__author {
        margin-top: vw(60);

        .c-user {
          margin-top: vw(20);
        }
        .level3-heading {
          margin-bottom: vw(10);
        }
      }
      .c-summary {
        margin-top: vw(72);
        &:first-child {
          margin-top: 0;
        }
      }
    }
    .p-banners {
      margin-top: 0;
    }
    .p-banner__header {
      margin-bottom: vw(24);
    }
  }
}
