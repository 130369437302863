
@include pc {
  .l-blog-row {
    display: grid;
    grid-template-columns: 1fr auto;
    // margin-bottom: 228px;
    position: relative;
    &--smallgap {
      gap: 48px;
    }
    &--sidemenu {
      grid-template-columns: 1fr 29.33333333%;
      gap: 64px;
    }
    &--has-background {
      padding-top: 440px;
      margin-top: -440px;
      &::after {
        pointer-events: none;
        @include content;
        background: linear-gradient(0deg, rgba(30, 162, 217, 0) 0%, rgba(30, 162, 217, 0.2) 48.44%, rgba(30, 162, 217, 0.2) 76.56%, rgba(30, 162, 217, 0) 100%);
        position: absolute;
        z-index: 20;
        // width: 100%;
        top: 0;
        left: calc(50% - 50vw);
        right: calc(50% - 50vw);
        max-height: 3640px;
        height: 100%;
        z-index: -1;
      }
    }
  }
  .l-blog-col {
    display: flex;
    flex-direction: column;
    // align-items: center;
    // &.u-wp-content {
    //   align-items: flex-start;
    // }
  }
}

@include sp {
  .l-blog-row {
    // padding-bottom: vw(148);
    display: flex;
    
    flex-direction: column;
    &--desc {
      flex-direction: column-reverse;
    }
  }
  .l-blog-col {
    & + .l-blog-col {
      margin-bottom: vw(160);
    }
  }
}