
@mixin sp-body-background-gradient($height: 4568) {
  &::after {
    content: '';
    display: block;
    width: 100%;
    z-index: -1;
    background: linear-gradient(180deg, rgba(30, 162, 217, 0) 0%, rgba(30, 162, 217, 0.2) vw(3092.9928), rgba(30, 162, 217, 0.2) vw(4353.7608), rgba(30, 162, 217, 0) vw(4568));

    background: linear-gradient(
      0deg, 
      rgba(30, 162, 217, 0) 0vw, 
      rgba(30, 162, 217, .2) vw(3092.9928), 
      rgba(30, 162, 217, .2) vw(4353.7608), 
      rgba(30, 162, 217, 0) vw(4568)
    );
    // height: 100%;
    max-height: vw($height);
    top: vw(264);
    left: 0;
    bottom: 0;
    position: absolute;
  }
}


@mixin __clear-octobg-style {
  &::after {
    content: none;
  }
}

.p-octobg {
  position: relative;
  
  
  // 背景をクリック不可にし直下要素で戻す
  pointer-events: none;
  & > * {
    pointer-events: auto;
  }

  
  &::after {
    @include content;
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    background-position: center top;
    background-repeat: no-repeat;
    z-index: -1;
    height: 100%;
    background-size: cover;

    @include bg--odd;
    @include pc {
      background-size: 100% 62.5vw;
      @include octagon_half();
      max-height: 62.5vw;
      height: 100%;
      mask-image: linear-gradient(to bottom, #000 0px, transparent 100%);
      background-repeat: no-repeat;
    }
    @include sp {
      background-size: auto vw(272);
      @include octagon_half(vw(63));
      mask-image: linear-gradient(to bottom, #000 0px, transparent vw(272));
      background-repeat: no-repeat;
      background-position: 0 0.24px;
      height: vw(272);
    }
  }
  &--odd {
    // default
    &::after {
      @include bg--odd;
    }
  }
  &--even {
    &::after {
      @include bg--even;
    }
  }
  &--works {
    @include pc {
      padding-top: 400px;
      margin-top: -190px;
    }
    // &::after {
    //   background-image: imgurl("background__ice--works.jpg");
    // }
  }
  // &--single {
  //   &::after {
  //     background-image: imgurl("background__ice--single.jpg");
  //   }
  // }
  &--static {
    @include pc {

      &::after {
        background: linear-gradient(180deg, 
        rgba(30, 162, 217, 0.2) 0%, 
        rgba(30, 162, 217, 0.2) 50%, 
        rgba(30, 162, 217, 0) 100%
        );
        height: 100%;
        max-height: 2832px;
        mask-image: unset;
      }
    }
    @include sp {
      
      &::after {
        mask-image: unset;
        height: 100%;
        background: linear-gradient(180deg, 
        rgba(30, 162, 217, 0.2) 0%, 
        rgba(30, 162, 217, 0.2) 50%, 
        rgba(30, 162, 217, 0) 100%
        );
      }
    }
  }
  // &--related {
  //   z-index: 6;
  //   // background-color: #fff;
  //   &::after {
  //     background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 50%, #FFFFFF 100%);
  //     // background-color: #fff;
  //   }


  // }
}
