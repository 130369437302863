.warn {
  background-color: orange;
  padding: 24px;
  font-size: 20px;
  font-weight: bold;
}
.error {
  background-color: #f00;
  color: #fff;
  padding: 24px;
  font-size: 20px;
  font-weight: bold;
}