
.c-checkbox {
  @include pc {
    display: inline-block;
    transition: color .4s $curve_main;
    &:hover {
      color: $blue;
    }
    &__input {
      display: none;
      &:checked {
        + .c-checkbox__text {
          &::after {
            opacity: 1;
          }
        }
      }
    }
    &__text {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 32px;
      display: inline-flex;
      align-items: center;
      user-select: none;
      cursor: pointer;
      &::before {
        @include content;
        width: 16px;
        height: 16px;
        background-color: #fff;
        box-sizing: border-box;
        border: 2px solid $blue;
        margin-right: 8px;
        margin-top: 2px;
      }
      &::after {
        @include content;
        width: 16px;
        height: 16px;
        margin-right: 8px;
        position: absolute;
        background-color: $blue;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDEyIDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMSAxTDQuODEyMzMgNy4xODc2N0wwLjgxMjMyOCAzLjE4NzY3IiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiLz4KPC9zdmc+Cg==");
        background-size: 12px 9px;
        background-position: left 2px center;
        background-repeat: no-repeat;
        opacity: 0;
        margin-top: 2px;
      }
    }
  }
  @include sp {
    display: block;
    &__input {
      display: none;
      &:checked {
        + .c-checkbox__text {
          &::after {
            opacity: 1;
          }
        }
      }
    }
    &__text {
      font-style: normal;
      font-weight: 500;
      font-size: vw(16);
      line-height: vw(24);
      display: inline-flex;
      align-items: center;
      user-select: none;
      cursor: pointer;
      &::before {
        @include content;
        width: vw(24);
        height: vw(24);
        background-color: #fff;
        box-sizing: border-box;
        border: vw(2) solid $blue;
        background-color: #fff;
        margin-right: vw(8);
        // margin-top: 2px;
      }
      &::after {
        @include content;
        width: vw(24);
        height: vw(24);
        margin-right: vw(8);
        position: absolute;
        background-color: $blue;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCAxOCAxMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03LjIxODgzIDkuMzY3MzdMMTUuNzkzMiAwLjc5Mjk2OUwxNy4yMDc0IDIuMjA3MThMNy4yMTg4MyAxMi4xOTU4TDAuNTExNzE5IDUuNDg4NjlMMS45MjU5MyA0LjA3NDQ4TDcuMjE4ODMgOS4zNjczN1oiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=");
        background-size: vw(18) auto;
        background-position: center center;
        background-repeat: no-repeat;
        opacity: 0;
        margin-top: vw(1);
      }
    }
  }
}