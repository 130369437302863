
.c-summary {
  @include pc {
    color: $blue;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 32px;
    /* または200% */
    
    display: flex;
    align-items: center;
    margin-bottom: 0;
    margin-top: 64px;
    &:first-child {
      margin-top: 0;
    }
    &::before {
      content: '-';
      margin-right: .25em;
    }
    & + h2 {
      margin-top: 9px;
    }
  }
  @include sp {
    color: $blue;
    font-style: normal;
    font-weight: 700;
    font-size: vw(16);
    line-height: 2;
    
    display: flex;
    align-items: center;
    margin-bottom: 0;
    margin-top: vw(72);
    &:first-child {
      margin-top: 0;
    }
    &::before {
      content: '-';
      margin-right: .25em;
    }
    & + h2 {
      margin-top: vw(16);
    }
  }
}