
.c-widget {
  @include pc {
    width: 100%;
    &__title {
      color: $blue;
      margin-bottom: 32px;
    }
  }
  @include sp {
    width: 100%;
    &__title {
      color: $blue;
      margin-bottom: vw(32 - 11);
      margin-top: vw(-8);
    }
  }
}