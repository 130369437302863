.l-archive-header {
  @include pc {
    @include __container_pc;
    margin-top: 160px;
    display: grid;
    gap: 88px;
    grid-template-columns: auto auto;
    align-items: center;
    position: relative;
    padding-bottom: 160px;
    z-index: 5;
    &--has-background {
      padding-bottom: 720px;
      &::after {
        @include content;
        background: linear-gradient(
          180deg,
          rgba(30, 162, 217, 0) 0%,
          rgba(30, 162, 217, 0.2) 50%,
          rgba(30, 162, 217, 0) 100%
        );
        position: absolute;
        z-index: 20;
        // width: 100%;
        left: calc(50% - 50vw);
        right: calc(50% - 50vw);
        height: 840px;
        z-index: -1;
        bottom: 0;
        pointer-events: none;
      }
    }

    &__body {
      padding-bottom: 6px;
    }

    &__description {
      margin-top: 27px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 32px;
      text-align: justify;
    }
    .p-download__button {
      display: none;
    }

    &__action {
      display: grid;
      gap: 32px;
      grid-template-rows: repeat(1, auto);
    }

    // .p-download {
    //   width: 526px;
    // }


  }
  @include sp {
    margin-top: vw(50);
    padding: 0 vw(32);
    margin-bottom: vw(48 - 16);
    z-index: 5;
    position: relative;
    &__title {
      margin-top: vw(-10);
    }
    &--has-background {
      // padding-bottom: 720px;
      &::after {
        @include content;

        background: linear-gradient(0deg, rgba(30, 162, 217, 0.06) 9.38%, rgba(30, 162, 217, 0.2) 39.11%, rgba(30, 162, 217, 0.2) 69.32%, rgba(30, 162, 217, 0) 100%);
        // background: linear-gradient(0deg, rgba(30, 162, 217, 0.06) 0%, rgba(30, 162, 217, 0.2) 50%, rgba(30, 162, 217, 0) 100%);
        // border: 1px solid #000;
        position: absolute;
        left: calc(50% - 50vw);
        right: calc(50% - 50vw);
        z-index: -1;
        bottom: vw(40);
        top: vw(72);
        pointer-events: none;
      }
    }
    &__description {
      font-weight: 500;
      font-size: vw(16);
      line-height: 2;
      text-align: justify;
      margin-top: vw(16);
    }
    .p-download {
      margin-top: vw(40);
      margin-left: vw(-8);
      margin-right: vw(-8);
      width: auto;
      &__title {
        margin-bottom: vw(8);
      }
      &__text {
        margin-bottom: vw(24);
      }
      &__thumbnail {
        display: none;
      }
    }
  }
}
