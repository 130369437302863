.l-page-header {
  @include pc {
    position: relative;
    z-index: 2;
    
    .p-download--horiz .p-download__title {
      font-weight: 700;
    }
    // titleエリア無し
    &__eyecatch {
      height: 512px;
      width: 100%;
      // @include octagon(120px);
      position: relative;
      z-index: 2;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  
    // titleエリアあり
    &__row {
      @include __container_pc;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: start;
      position: relative;
      z-index: 5;
      width: 100%;
    }
    &__col {
      padding: 160px 0 160px + 192;
      // border: 1px solid #000;
      margin-right: 64px;
      &:last-child {
        margin-right: 0;
      }
    }
    &__description {
      margin-top: 27px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 32px;
      text-align: justify;
      margin-bottom: -8px;
    }
    .level1-heading {
      margin-top: -12px;
    }
    .btn-group {
      margin-bottom: 32px;
    }
    .btn {
      @include btn--scaling;
    }
    &--md {
      .l-page-header__row {
        max-width: 1360px;
      }
    }
  }
  @include sp {
    position: relative;
    z-index: 2;
    
    // titleエリア無し
    &__eyecatch {
      height: vw(280);
      width: 100%;
      position: relative;
      z-index: 2;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  
    // titleエリアあり
    &__row {
      @include __container_pc;
      display: grid;
      gap: vw(48);
      grid-template-rows: repeat(auto);
      align-items: start;
      position: relative;
      z-index: 5;
      padding: vw(80) vw(32) vw(120);
    }
    // &__col {
    //   // padding: 160px 0 160px + 192;
    // }
    &__description {
      margin-top: vw(16);
      font-style: normal;
      font-weight: 500;
      font-size: vw(16);
      line-height: 2;
      text-align: justify;
      margin-bottom: vw(-8);
    }
    .level1-heading {
      margin-top: vw(-8);
    }
    .p-download {
      margin-left: vw(-8);
      margin-right: vw(-8);
      width: auto;
      &__thumbnail {
        display: none;
      }
      &__button {
        margin-top: vw(32);
      }
    }
  }
}
.l-page-body {
  @include pc {
    z-index: 3;
    position: relative;
    margin-bottom: -190px;
    padding-bottom: 240px + 190;
    padding-top: 200px;
    &::after {
      background: linear-gradient(180deg, #D5EFF9 22.92%, #E4F4FA 100%, #E4F4FA 100%);
      @include content;
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      width: 100%;
      background-position: center top;
      background-repeat: no-repeat;
      z-index: -1;
      height: 100%;
      background-size: cover;
    }
    // & + .l-footer {
    //   margin-top: 190px;
    //   z-index: 10;
    // }
  }
  @include sp {
    z-index: 3;
    position: relative;
    padding-top: vw(80);
    padding-bottom: vw(160 + 40);
    &::after {
      background: linear-gradient(180deg, #D5EFF9 22.92%, #E4F4FA 100%, #E4F4FA 100%);
      @include content;
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      width: 100%;
      background-position: center top;
      background-repeat: no-repeat;
      z-index: -1;
      height: 100%;
      background-size: cover;
      height: 100%;
    }
  }
}


// ヘッダ＋コンテント組み合わせレイアウト時
.l-page-header {
  & + .l-page-body {
    @include pc {
      padding-top: 240px;
      margin-top: -192px;
      @include shadow;
      &::after {
        @include octagon_half();
      }
    }
    @include sp {
      margin-top: vw(-40);
      @include shadow;
      padding-top: vw(160);
      .p-page-title {
        margin-top: vw(-40);
        display: flex;
        flex-direction: column;
        padding: 0 vw(32);
        &__meta {
          margin-top: vw(0);
        }
      }
      &::after {
        @include octagon_half(vw(63.5));
      }
    }
  }
}