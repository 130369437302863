.c-panel {
  @include pc {
    @include shadow;
    &__title {
      // border: 1px solid #000;
      // grid-column: #f09;
      margin-bottom: 24px;
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 48px;
      /* または200% */

      display: flex;
      align-items: center;
      text-align: justify;
      margin-top: -12px;
    }
    &__inner {
      background-color: #fff;
      padding: 88px 88px;
      @include octagon(42px);
    }
    ul,
    dl:not(.p-qa__list) {
      display: flex;
      flex-direction: column;
      border: 0;
      // margin-top: -7px;
      li, dt {
        border-top: 0;
        padding-bottom: 15px;
        padding-top: 0;
        margin-top: -7px;
      }
      dd {
        padding-top: 26px;
        margin-bottom: 25px;
        &:last-child {
          margin-bottom: 0;
          padding-bottom: 0;
        }
      }
      &.is-disc {
        margin: -8px 0;
        li, dt {
          margin: 8px 0 0;
          padding: 0;
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 32px;
          color: $blue;
          display: flex;
          align-items: center;
          &::before {
            @include content;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: $blue;
            margin-top: 2px;
            flex: 8px 0 0;
            margin-right: 8px;
          }
          &:first-child {
            margin-top: 0;
          }
        }
        dd {
          padding: 0;
          margin: 0 0 0;
          font-weight: 500;
          font-size: 16px;
          line-height: 32px;
        }
      }
    }
    &--md {
      .c-panel {
        &__inner {
          @include octagon(120px);
        }
      }
    }
    &--sm {
      .c-panel {
        &__inner {
          padding: 48px;
        }
      }
    }
  }
  @include sp {
    @include shadow;
    margin-left: vw(-8);
    margin-right: vw(-8);
    width: auto !important;
    &__inner {
      background-color: #fff;
      @include octagon(vw(55));
      padding: vw(80) vw(32);
    }
    dl:not(.p-qa__list) {
      margin-top: vw(-7);
    }
    a {
      text-decoration: none;
    }
    dl:not(.p-qa__list) {
      display: flex;
      flex-direction: column;
      border: 0;
      // margin-top: -7px;
      li, dt {
        border-top: 0;
        padding-bottom: vw(15);
        padding-top: 0;
        margin-top: vw(-7);
      }
      dd {
        padding-top: vw(26);
        margin-bottom: vw(25);
        &:last-child {
          margin-bottom: 0;
          padding-bottom: 0;
        }
      }
      &.is-disc {
        margin: vw(-8) 0;
        li, dt {
          margin: vw(12) 0 0;
          padding: 0;
          font-style: normal;
          font-weight: 700;
          font-size: vw(16);
          line-height: 2;
          color: $blue;
          display: flex;
          align-items: center;
          &::before {
            @include content;
            width: vw(8);
            height: vw(8);
            border-radius: 50%;
            background-color: $blue;
            margin-top: vw(2);
            flex: vw(8) 0 0;
            margin-right: vw(8);
          }
          &:first-child {
            margin-top: 0;
          }
        }
        dd {
          padding: 0 0 0 vw(16);
          margin: vw(4) 0 0 0;
          font-weight: 500;
          font-size: vw(16);
          line-height: 2;
        }
      }
    }

    &--sm {
      .c-panel {
        &__inner {
          padding-top: vw(44);
          padding-bottom: vw(36);
        }
      }
    }
  }
}
