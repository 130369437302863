

  .c-label {
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: center;
    background: rgba(30, 162, 217, 0.2);
    color: #1ea2d9;
    max-width: 100%;
    font-style: normal;
    font-weight: 700;
    @include pc {
      font-size: 14px;
      line-height: 28px;
      padding: 6px 16px;
    }
    @include sp {
      font-size: vw(14);
      line-height: 2;
      padding: vw(2) vw(8);
      font-weight: 500;
      
    }
  }