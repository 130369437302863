$BREAKPOINT: 960px;

$mq_sp: null, $BREAKPOINT - 1px;
$mq_xs: $BREAKPOINT, 1199px;
$mq_sm: 1200px, 1279px;
$mq_md: 1280px, 1348px;
$mq_lg: 1349px, null;

$sp_viewport_width: 375;


$gray: #AFAFAF;
$black: #000;


$section_padding: 60px;
$header_height: 72px;

// $section_padding: 0;
// $header_height: 0;

$blue: #1EA2D9;
$green: #4CA737;


$curve_main: cubic-bezier(0.345, 0.210, 0.120, 1.005);
$images: '/wp-content/themes/edamame_media/assets/images/'