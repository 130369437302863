.p-detail-info {
  @include pc {
    .l-iceblock__body {
      max-width: 1160px;
    }
    &__body {
      width: 100%;
      h4 {
        border: 0;
        margin: 0;
        font-size: 16px;
        padding: 0;
        margin-top: 17px;
        margin-bottom: 8px;
        &:first-child {
          margin-top: 7px;
        }
        & + p {
          margin-top: 0;
          &:last-child {
            padding-bottom: 7px;
          }
        }
      }
    }
    &__title {
      font-weight: 700;
      font-size: 32px;
      line-height: 56px;
      text-align: center;
      color: $blue;
      margin-bottom: 80px - 16;
      margin-top: -16px;
    }
  }

  @include sp {
    // half grad (top)
    // background: linear-gradient(180deg, rgba(30, 162, 217, 0) 0%, rgba(30, 162, 217, 0.2)  100%);
    .p-octobg {
      padding-top: vw(208);
      margin-top: vw(-40);
      // padding-bottom: 832px;
      padding-bottom: vw(160);
      z-index: 7;
      &::after {
        @include bg--odd;
      }
    }
    &__title {
      font-weight: 700;
      font-size: vw(24);
      line-height: vw(40);
      text-align: center;
      color: $blue;
      margin-bottom: vw(65);
      margin-top: vw(-8);
    }
    &__body {
      h4 {
        font-size: vw(16);
        line-height: vw(30);
        padding: 0;
        border: 0;
        margin-top: vw(18);
        &:first-child {
          margin-top: vw(2);
        }
        & + p {
          &:last-child {
            margin-bottom: vw(-4);
          }
        }
      }
      p {
        margin-top: vw(17);
      }
    }
    // & + .p-subscribe {
    //   // background: linear-gradient(180deg, rgba(30, 162, 217, 0.2) 0%, rgba(30, 162, 217, 0) 100%);
    // }
  }
}
