@mixin arrow-circle_pc {
  margin-left: auto;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid $blue;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2px;
  transition: background-color 0.4s $curve_main;
  // margin-top: -1px;
  path {
    transition: stroke 0.4s $curve_main;
    stroke: $blue;
  }
}
@mixin arrow-circle_pc--lg {
  width: 32px;
  height: 32px;
  svg {
    width: 32px;
  }
}

@mixin arrow-circle_sp {
  @include content;
  margin-left: auto;
  width: vw(24);
  height: vw(24);
  border-radius: 50%;
  border: vw(2) solid $blue;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  // margin-bottom: 2px;
  // transition: background-color 0.4s $curve_main;
  // margin-top: -1px;
  svg {
    // width: 100%;
    width: vw(24);
    height: vw(24);
  }
  path {
    // transition: stroke 0.4s $curve_main;
    stroke: $blue;
  }
}
@mixin arrow-circle_sp--lg {
  width: vw(32);
  height: vw(32);
  svg {
    width: vw(32);
  }
}

@mixin arrow-circle-hover {
  &:hover {
    background-color: $blue;
    path {
      stroke: #fff;
    }
  }
}


.c-arrow-circle {
  @include pc {
    @include arrow-circle_pc();
    @include arrow-circle-hover();
  }
  @include sp {
    @include arrow-circle_sp;
  }
  &--green {
    border-color: $green;
    path {
      // transition: stroke 0.4s $curve_main;
      stroke: $green;
    }
  }
}