$speed: .8;
@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
  
}
.p-header-description {
  body.home & {
    opacity: 0;
  }
  body.loaded.home & {
    animation: fade (1s * $speed) $curve_main 0s 1 normal forwards;
  }
}
.l-header {
  body.home & {
    opacity: 0;
  }
  body.loaded.home & {
    animation: fade (1s * $speed) $curve_main .2s 1 normal forwards;
  }
}
.p-kv {
  &__body {
    body.home & {
      opacity: 0;
      transition: opacity (2s * $speed) $curve_main (.25s * $speed);
    }
    body.loaded.home & {
      opacity: 1;
    }
  }
  &__gallery {
    body.home & {
      opacity: 0;
      transition: opacity (2.5s * $speed) $curve_main (.4s * $speed);
    }
    body.loaded.home & {
      opacity: 1;
    }
  }
}
