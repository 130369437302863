// oirei

.l-articles {
  @include pc {
    display: grid;
    width: 100%;
    gap: 24px;
  }
  @include sp {
    display: grid;
    gap: vw(24);
    margin-left: vw(-8);
    margin-right: vw(-8);
  }
}

.p-article {
  @include pc {

    @include shadow;
    &__inner {
      background-color: #fff;
      padding: 32px;
  
      display: grid;
      gap: 24px;
      // grid-template-columns: 88px 104px 1fr;
      grid-template-columns: 214px 1fr;
      align-items: center;
      @include octagon(22px);
      min-height: 128px;
    }

    &.is-new {
      .p-article {
        &__body {
          &::before {
            background-color: $blue;
            width: 48px;
            height: 24px;
            content: 'NEW';
            @include roboto;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            margin-bottom: 16px;
            
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;

            color: #FFFFFF;
          }
        }
      }
    }

    &:hover {
      .p-article {
        &__title {
          color: $blue;
        }
      }
    }
    &__header {
      display: grid;
      gap: 24px;
      grid-template-columns: 88px auto ;
    }
    &__date {
      display: flex;
      flex-direction: column;
      justify-content: center;
      @include roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 32px;
      margin-top: 2px;
    }
    &__category {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 32px;
      margin-top: 2px;
      /* or 200% */
  
      display: flex;
      align-items: center;
      justify-content: flex-start;
  
      /* main/blue */
  
      color: #1ea2d9;
    }
    &__title {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 40px;
      margin-top: -7px;
      margin-bottom: -8px;
      /* or 200% */
  
      display: flex;
      align-items: center;
      text-align: justify;
      align-items: center;
      color: #000000;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      transition: color 0.4s $curve_main;
      -webkit-box-valign: center;
      .p-article--variable & {
        display: unset;
        -webkit-box-orient: unset;
        -webkit-line-clamp: unset;
      }
    }
  }
  @include sp {
    @include shadow;
    &__inner {
      background-color: #fff;
      padding: vw(32);
      display: grid;
      gap: vw(16);
      grid-template-rows: auto auto;
      @include octagon(vw(22));
    }
    &.is-new {
      .p-article {
        &__body {
          &::before {
            background-color: $blue;
            width: vw(48);
            height: vw(24);
            content: 'NEW';
            @include roboto;
            font-weight: 500;
            font-size: vw(14);
            line-height: vw(24);
            margin-bottom: vw(22);
            margin-top: vw(-7);
            
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;

            color: #FFFFFF;
          }
        }
      }
    }

    &__header {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      margin-top: vw(-5);
    }
    &__date {
      display: flex;
      flex-direction: column;
      justify-content: center;
      @include roboto;
      font-style: normal;
      font-weight: 500;
      font-size: vw(14);
      line-height: 2;
      margin-right: vw(8);
    }
    &__category {
      font-style: normal;
      font-weight: 700;
      font-size: vw(14);
      line-height: 2;
  
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: $blue;
    }
    &__title {
      font-style: normal;
      font-weight: 500;
      font-size: vw(16);
      line-height: 2;
      display: flex;
      align-items: center;
      text-align: justify; // ios bug
      @include safari {
        text-align: left;
      }
      align-items: center;
      color: #000000;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      -webkit-box-valign: center;
      margin-top: vw(-14);
      margin-bottom: vw(-8);
      .p-article--variable & {
        display: flex;
        -webkit-box-orient: unset;
        -webkit-line-clamp: unset;
      }
    }
  }
}