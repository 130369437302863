// oirei
@include pc {
  // &__seminar {
  //   margin-bottom: 160px;
  // }
  .p-banners {
    width: 100%;
    margin-top: 160px;

    &:first-child {
      margin-top: 0;
    }

    &__item {
      margin-bottom: 80px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .p-banner {
    @include shadow;
    width: 100%;
    &--inquiry {
      .p-banner {
        &__header {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-bottom: 36px;
        }
        &__title {
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          display: flex;
          align-items: center;
          text-align: center;
          color: $green;
          margin-bottom: 12px;
        }
        &__lead {
          font-weight: 500;
          font-size: 24px;
          line-height: 48px;
          /* または200% */

          display: flex;
          align-items: center;
          text-align: center;
        }
        &__action {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          align-items: center;
          margin-top: -20px;
          .btn-group {
            margin-top: 20px;
          }
          .c-tel {
            margin-top: 20px;
            align-items: center;
            display: flex;
            flex-direction: column;
            margin-left: 32px;
          }
        }
      }
      &.is-wrap {
        .p-banner__header {
          margin-bottom: 22px;
        }
        .p-banner__action {
          flex-wrap: wrap;
          .c-tel {
            margin-top: 34px;
          }
        }
      }
    }
    &--seminar {
      .p-banner {
        &__body {
          display: flex;
          flex-direction: row-reverse;
          justify-content: center;
          text-align: center;
          align-items: center;
          padding-left: 10px;
          margin-right: -10px;
        }
        &__header {
          width: 100%;
          max-width: 324px;
        }
        &__title {
          font-weight: 500;
          font-size: 24px;
          line-height: 40px;
          margin-top: -9px;
          /* または167% */

          display: flex;
          align-items: center;
          text-align: center;
          margin-bottom: 8px;
        }
        &__thumbnail {
          width: 270px;
          flex: 270px 0 0;
          aspect-ratio: 270/200;
          @include octagon(40px);
          margin-left: 70px;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
      .btn {
        margin-top: 24px;
        max-width: 324px;
        width: 100%;
      }
    }
    &[href] {
      // display: none;
      .btn {
        pointer-events: none;
      }
      &:hover {
        
        .btn {
          @include btn_elements_color($blue);
          // @include btn_elements_invert_color($green);
          background-color: #fff;
        }
      }
    }
  }
  .p-related-download {
    @include shadow;
    width: 100%;
    &__col {
      margin: 0 24px;
    }

    &__body {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &__text {
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 48px;
      /* or 200% */

      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;

      margin-bottom: 14px;
      transition: color 0.4s $curve_main;
    }
    &__images {
      display: grid;
      grid-template-columns: repeat(2, minmax(auto, 270px));
      gap: 24px;
    }
    &__image {
      aspect-ratio: 270/200;
      // background-color: #ccc;
      @include octagon(40px);
      img {
        aspect-ratio: 270/200;
        object-fit: cover;
        width: 100%;
      }
    }

    &[href] {
      // display: none;
      .btn {
        pointer-events: none;
      }
      &:hover {
        .p-related-download {
          &__text {
            color: $green
          }
        }
        .btn {
          @include btn_elements_color(#fff);
          // @include btn_elements_invert_color($green);
          background-color: $green;
        }
      }
    }
  }
}
@include sp {
  // &__seminar {
  //   margin-bottom: vw(160);
  //   .c-card {
  //     &:nth-of-type(n+3) {
  //       display: none;
  //     }
  //   }
  // }

  .p-banners {
    display: grid;
    grid-template-rows: repeat(auto);
    gap: vw(80);
  }
  .p-banner {
    margin-left: vw(-8);
    margin-right: vw(-8);
    @include shadow;
    &__header {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: vw(28);
    }
    &__title {
      color: $green;
      font-style: normal;
      font-weight: 700;
      font-size: vw(16);
      margin-bottom: vw(16);
      text-align: center;
    }
    &__lead {
      font-style: normal;
      font-weight: 500;
      font-size: vw(16);
      line-height: 2;
      display: flex;
      align-items: center;
      text-align: center;
    }
    .c-tel {
      margin-top: vw(27);
    }
    &--seminar {
      .p-banner {
        &__thumbnail {
          margin-bottom: vw(32);
          @include octagon(vw(39));
          aspect-ratio: 263/192;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        &__title {
          font-style: normal;
          margin-top: vw(-8);
          font-weight: 500;
          font-size: vw(16);
          line-height: 2;

          display: flex;
          align-items: center;
          text-align: center;
          color: #000;
          margin-bottom: vw(8);
        }
        &__header {
          margin: 0;
        }
      }
      img {
        width: 100%;
      }
      .btn {
        margin-top: vw(24);
      }
    }
  }
  .p-related-download {
    position: relative;
    margin-left: vw(-8);
    margin-right: vw(-8);
    // width: auto;
    &__col {
      width: 100%;
    }

    &__body {
      display: flex;
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
    }
    &__text {
      font-style: normal;
      font-weight: 500;
      font-size: vw(16);
      line-height: 2;
      text-align: center;
      margin-top: vw(-8);
      margin-bottom: vw(16);
    }
    &__images {
      width: 100%;
      margin-bottom: vw(32);
    }
    &__image {
      aspect-ratio: 263/192;
      // background-color: #ccc;
      @include octagon(vw(38));
      width: 100%;
      img {
        aspect-ratio: 263/192;
        object-fit: cover;
        width: 100%;
      }
      display: none;
      &:first-child {
        display: block;
      }
    }
  }
}
