body.archive {
  .p-filterbox {
    @include pc {
      margin-bottom: 160px;
      z-index: 10;
    }
    @include sp {
      margin-bottom: vw(160);
    }
  }
}
body.category-seminar-event {
  @include pc {
    .l-archive-header {
      padding-bottom: 432px;
      margin-bottom: -272px;
    }
    .p-octobg--archive {
      padding-bottom: 240px;
      z-index: 5;
      &::after {
        margin-top: -256px;
      }
    }
  }
  @include sp {
    .l-page-header {
      &__action {
        display: none;
      }
    }
    .l-seminar-searchbox {
      // margin-top: vw(-552);

    }
    .p-filterbox {
      position: relative;
      margin-left: vw(-8);
      margin-right: vw(-8);
      width: auto;
      z-index: 10;
      // z-index: 15;
    }
    .p-octobg--archive {
      z-index: 6;
      padding-top: 0;
      margin-top: 0;
      &::after {
        margin-top: 0;
        margin-top: vw(-208);
      }
    }
  }
}
