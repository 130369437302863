// oirei
.p-pagination {
  @include pc {


    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    height: 32px;
    .page-numbers {
      width: 36px;
      height: 32px;

      @include roboto;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 40px;
      
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      &::after {
        @include content;
        background: $blue;
        width: 8px;
        height: 8px;
        position: absolute;
        left: 50%;
        margin-left: -4px;
        bottom: 0;
        bottom: -10px;
        border-radius: 4px;
        pointer-events: none;
        opacity: 0;
      }
      @include hover_color;
      transition-duration: .3s;
      &.prev,
      &.next {
        @include arrow-circle_pc;
        @include arrow-circle_pc--lg;
        @include arrow-circle-hover;
        margin-top: 1px;
        &::after {
          content: none;
        }
      }
      &.prev {
        margin-right: 40px;
        transform: scale(-1, 1);
      }
      &.next {
        margin-left: 40px;
      }
      &.current {
        color: $blue;
        &::after {
          opacity: 1;
        }
      }
      &.dots {
        margin: 0 3px;
        pointer-events: none;
        user-select: none;
      }
    }

  }
  @include sp {

    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: vw(-6);
    .page-numbers {
      width: vw(44);
      height: vw(44);
      @include roboto;
      font-style: normal;
      font-weight: 700;
      font-size: vw(20);
      line-height: 2;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      display: none;
      &::after {
        @include content;
        background: $blue;
        width: vw(8);
        height: vw(8);
        position: absolute;
        left: 50%;
        margin-left: vw(-4);
        bottom: 0;
        bottom: vw(-5);
        border-radius: vw(4);
        pointer-events: none;
        opacity: 0;
      }
      @include hover_color;
      transition-duration: .3s;
      &.prev,
      &.next {
        @include arrow-circle_sp;
        // @include arrow-circle_sp--lg;
        flex: vw(32) 0 0;
        height: vw(32);
        width: vw(32);
        display: flex;
        // @include arrow-circle_pc--lg;
        // @include arrow-circle-hover;
        margin-top: 1px;
        background-image: url(../images/arrow--pager__sp.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: vw(32) auto;
        svg {
          display: none;
          // margin-top: vw(-2);
          margin-left: vw(-2);
          margin-right: vw(-2);
          height: vw(32);
          width: vw(32);
          // border: 1px solid #000;
        }
        &::after {
          content: none;
        }
      }
      &.prev {
        transform: scale(-1, 1);
        position: absolute;
        left: 0;
      }
      &.next {
        margin-left: 40px;
        position: absolute;
        right: 0;
      }
      &.current {
        color: $blue;
        display: flex;
        &::after {
          opacity: 1;
        }
        & + .page-numbers {
          display: flex;
          & + .page-numbers {
            display: flex;
            & + .page-numbers {
              display: flex;
            }
          }
        }
      }
      // &.dots {
      //   margin: 0 3px;
      //   pointer-events: none;
      //   user-select: none;
      // }
    }

  }
}