
.c-order-list {
  @include pc {
    margin-top: -17px;
    &__item {
      display: flex;
      align-items: center;
      margin-bottom: 14px;
      border-bottom: 2px solid rgba(30, 162, 217, 0.2);
      counter-increment: mycounter;
      padding: 7px 0 11px ;
      &:last-child {
        margin-bottom: 0;
      }
      &::before {
        content: counter(mycounter, decimal-leading-zero);
        @include roboto;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        color: $blue;
        display: flex;
        align-items: center;
        margin-right: 11px;
        margin-top: 4px;
      }
      &:hover {
        color: $blue;
        .c-order-list__arrow {
          background-color: $blue;
          path {
            stroke: #fff;
          }
        }
      }
    }
    &__text {
      font-style: normal;
      display: flex;
      align-items: center;
      transition: color 0.4s $curve_main;
    }
  
    &__arrow {
      margin-left: auto;
      width: 20px;
      flex: 20px 0 0;
      height: 20px;
      border-radius: 50%;
      border: 2px solid $blue;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 2px;
      transition: background-color 0.4s $curve_main;
      position: relative;
      svg {
        position: absolute;
        top: -2px;
        left: -2px;
      }
      &:hover {
        @include arrow-circle-hover;
      }
    }
    &--lg {
      .c-order-list {
        &__item {
          padding: 17px 0;
          margin-bottom: 12px;
          &::before {
            font-size: 16px;
            margin-right: 11px;
          }
        }
        &__text {
          font-weight: 500;
          font-size: 20px;
          line-height: 32px;
        }
        &__arrow {
          width: 24px;
          height: 24px;
          flex: 24px 0 0;
          svg {
            width: 24px;
            height: 24px;
            position: relative;
            position: absolute;
            top: -2px;
            left: -2px;
          }
        }
      }
    }
  }
  @include sp {
    margin-top: vw(-9);
    &__item {
      display: flex;
      align-items: center;
      margin-bottom: vw(4);
      padding: vw(8) 0 vw(10);
      border-bottom: vw(2) solid rgba(30, 162, 217, 0.2);
      
      counter-increment: mycounter;
      &:last-child {
        margin-bottom: 0;
      }
      &::before {
        content: counter(mycounter, decimal-leading-zero);
        @include roboto;
        font-style: normal;
        font-weight: 700;
        font-size: vw(16);
        color: $blue;
        display: flex;
        align-items: center;
        margin-right: vw(9);
        margin-top: vw(3);
      }
      &:hover {
        .c-order-list__arrow {
          background-color: $blue;
          path {
            stroke: #fff;
          }
        }
      }
    }
    &__text {
      font-style: normal;
      font-weight: 500;
      font-size: vw(16);
      line-height: 2;
      display: flex;
      align-items: center;
    }
  
    &__arrow {
      margin-left: auto;
      width: vw(24);
      height: vw(24);
      border-radius: 50%;
      border: vw(2) solid $blue;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      // margin-bottom: 2px;
      // transition: background-color 0.4s $curve_main;
      // margin-top: -1px;
      svg {
        display: none;
      }
      background-image: imgurl('arrow--order-list_sp.svg');
      background-size: vw(24) auto;
      background-position: center;
      path {
        // transition: stroke 0.4s $curve_main;
        stroke: $blue;
      }
    }
  }
}