.c-detail-has-thumbnail {
  @include pc {
    display: grid;
    gap: 24px;
    grid-template-columns: 329px auto;
    align-items: start;
    img {
      margin-top: 0;
      aspect-ratio: 329/440;
      object-fit: cover;
      max-width: 100%;
    }
  }
  @include sp {
    &__image {
      margin-bottom: vw(24);
    }
    img {
      width: 100%;
    }
  }
}
