.l-form {
  display: grid;
  grid-template-rows: repeat(1, auto);
  gap: 48px;
}

@mixin form_style {
  form {
    label {
      cursor: pointer;
      margin-bottom: 8px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 32px;
      display: flex;
    }
  }
  input[type=radio] {
    appearance: none;
    position: relative;
    margin-right: 8px;
    &::before {
      content: '';
      width: 16px;
      height: 16px;
      display: block;
      border-radius: 16px;
      background-color: #fff;
      border: 2px solid $blue;
      box-sizing: border-box;
      margin-top: 5px;
    }
    &:checked {
      &::after {
        position: absolute;
        content: '';
        width: 8px;
        height: 8px;
        display: block;
        border-radius: 4px;
        background-color: $blue;
        // border: 2px solid $blue;
        margin-top: 5px;
        top: 4px;
        left: 4px;
      }
    }
  }
  input[type=checkbox] {
    appearance: none;
    position: relative;
    &::before {
      content: '';
      width: 16px;
      height: 16px;
      display: block;
      background-color: #fff;
      border: 2px solid $blue;
      box-sizing: border-box;
      margin-top: 5px;
      margin-right: 8px;
    }
    &:checked {
      &::after {
        position: absolute;
        content: '';
        width: 8px;
        height: 8px;
        display: block;
        background-color: $blue;
        // border: 2px solid $blue;
        margin-top: 5px;
        top: 4px;
        left: 4px;
      }
    }
  }
  input[type=text],
  input[type=password],
  input[type=email],
  input[type=url],
  input[type=search],
  input[type=tel],
  textarea {
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    display: flex;
    align-items: center;
    text-align: justify;
    color: #000000;
    border: 2px solid $blue;
    background-color: #fff;
    padding: 6px 16px;
    outline: 0;
    box-sizing: border-box;
    width: 100%;
    // &:focus {
    //   border-color: #c00;
    // }
    &::placeholder {
      color: rgba(0, 0, 0, 0.3);
    }
  }
  textarea {
    height: 160px;
    width: 100%;
  }
  // @-moz-document url-prefix() {
  //   input[type=radio] {
  //     appearance: none;
  //   }
  // }
  .form-group {
    margin-bottom: 48px;
  }
}
.u-wp-content--form {
  @include form_style
}
.form-group {
  
  display: grid;
  align-items: start;
  gap: 48px;
  grid-template-columns: minmax(#{math.div(240, 920) * 100%}, 240px) auto;
  // border: 1px solid #000;
  &__head {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 40px;
    /* or 200% */
    
    display: flex;
    align-items: center;
    text-align: justify;
    color: $blue;  
  }
  &__body {
    display: flex;
    align-items: flex-start;
    // &--list {
    //   display: grid;
    //   grid-template-rows: repeat(1, auto);
    //   gap: 8px;
    // }
    input[type=text],
    input[type=email],
    input[type=password]
    {
      width: 100%;
    }

  }
  &__list {
    display: grid;
    grid-template-rows: repeat(1, auto);
    gap: 8px;
    // margin-top: -6px;
  }
  &.is-required {
    .form-group {
      &__body {
        &::before {
          margin-top: 12px;
          flex: 44px 0 0;
          content: '必須';
          margin-right: 16px;
          background-color: $blue;
          font-weight: 700;
          font-size: 14px;
          line-height: 24px;
          /* identical to box height, or 171% */
          
          display: flex;
          align-items: center;
          text-align: center;
          justify-content: center;
          
          color: #FFFFFF;
          width: 44px;
          height: 24px;
          
        }
      }
    }
  }
  input[type=text],
  input[type=password],
  input[type=email],
  input[type=url],
  input[type=search],
  input[type=tel],
  textarea {
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    display: flex;
    align-items: center;
    text-align: justify;
    color: #000000;
    border: 2px solid $blue;
    background-color: #fff;
    padding: 6px 16px;
    outline: 0;
    box-sizing: border-box;
    width: 100%;
    // &:focus {
    //   border-color: #c00;
    // }
    &::placeholder {
      color: rgba(0, 0, 0, 0.3);
    }
  }
  textarea {
    height: 160px;
    width: 100%;
  }
}
