body.page-template-system-frozen {

  @include pc {
    .l-page-body {
      padding-top: 120px;
    }
    .p-system-frozen-feature {
      margin-bottom: 240px;
      .l-iceblock__body {
        max-width: 1160px;
      }
      .u-wp-content {
        margin-bottom: -8px;
      }
    }
    .c-toc {
      margin-bottom: 64px;
    }
  }
  @include sp {
    .p-system-frozen-feature {
      margin-bottom: vw(160);
      .l-iceblock__body {
        padding-top: vw(110);
      }
    }
    .c-toc {
      margin-bottom: vw(160 - 8);
    }
  }
}