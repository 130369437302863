body.page-template-feature {
  @include pc {

    .p-feature-lead {
      margin-bottom: 240px;
      // h2 {
      //   margin-bottom: 24px;
      // }
      &__title {
        text-align: center;
      }
      .l-iceblock__body {
        align-items: flex-start;
        min-height: 792px - 129;
      }
      .l-iceblock__background {
        &::after {
          @include octagon(292px, 1.38388626);
        }
      }
      &__title {
        margin-bottom: 48px - 11;
      }
      &__subtitle {
        margin-bottom: 32px;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 32px;
        margin-bottom: 16px;
      }
      &__text {
        p {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 32px;
          /* または200% */
          
          display: flex;
          align-items: center;
          margin-bottom: 8px;
          &:last-child {
            margin-bottom: 0;
          }  
        }
      }
  
    }
    .p-feature-checklist {
      margin-bottom: 240px;
      &__title {
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 56px;
        margin: 0; 
        margin-top: -16px;
        margin-bottom: 32px;
      }
      .c-panel__inner {
        padding-left: 146px;
        padding-right: 146px;
      }
      .c-checklist {
        margin-left: -6px;
        margin-bottom: -8px;
        li {
          margin-bottom: 8px;
        }
      }
    }
  
    .l-features {
      &__title {
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 56px;
        text-align: center;
        margin-bottom: 120px;
        color: $blue;
        margin-top: -16px;
      }
    }
  
    .p-features-offer {
      margin-bottom: 80px;
      &__grid {
        display: grid;
        gap: 48px;
        grid-template-columns: 1fr 1fr;
        align-items: center;
      }
      &__text {
        margin-top: 8px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 32px;
        p {
          margin-bottom: 8px;
        }
      }
      &__figure {
        img {
          width: 100%;
        }
      }
  
    }
    .p-features-service {
      margin-top: -12px;
      margin-bottom: 240px;
      &__title {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 48px;
        text-align: center;
        margin-bottom: 12px;
        // color: $blue;
      }
      &__text {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 32px;
        text-align: center;
        margin-bottom: 40px;
      }
      &__services {
        margin-bottom: 80px;
      }
    }
    .p-features-otherservice {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      gap: 48px;
      &__title {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 32px;
        align-items: center;
        color: $blue;
        margin-bottom: 3px;
      }
      &__lead {
        margin-bottom: 13px;
      }
      &__text {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 32px;
      }
      &__image {
        img {
          width: 100%;
          aspect-ratio: 576/432;
          object-fit: cover;
          @include octagon(85px);
        }
      }
    }
  
    .p-features__work {
      margin-bottom: 240px;
      .l-iceblock__background::after {
        @include octagon(292px, 1.38388626)
      }
      &__list {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 24px 48px;
      }
      &__item {
        position: relative;
        height: 80px;
        text-align: center;
        img {
          position: relative;
          height: 100%;
          width: 100%;
          object-fit: contain;
  
        }
      }
      .c-more {
        margin-top: 48px;
      }
  
    }
    .p-features__expert {
      // .c-users {}
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 240px;
      &__title {
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 56px;
        text-align: center;
        color: $blue;
        margin-bottom: 80px;
      }
      .c-more {
        margin-top: 80px;
      }
      
    }
  
  
  }
  @include sp {

    .p-feature-lead {
      margin-bottom: vw(160);
      &__title {
        margin-top: vw(-6);
        margin-bottom: vw(20);
      }
      &__subtitle {
        text-align: left;
        align-self: flex-start;
        margin-bottom: vw(16);
      }
      &__text {
        font-style: normal;
        font-weight: 500;
        font-size: vw(16);
        line-height: 2;
        text-align: justify;
        
        p {
          margin-bottom: vw(16);
          &:last-child {
            margin-bottom: vw(-8);
          }  
        }
      }
  
    }
    .p-feature-checklist {
      margin-bottom: vw(160);
      &__title {
        font-style: normal;
        font-weight: 700;
        font-size: vw(24);
        line-height: vw(40);
        text-align: justify;
        margin-bottom: vw(44);
        margin-top: vw(-8);
      }
    }
  
    .l-features {
      &__title {
        font-style: normal;
        font-weight: 700;
        font-size: vw(24);
        line-height: vw(40);
        text-align: center;
        color: $blue;
        margin-bottom: vw(72);
        margin-top: vw(-8);
      }
    }

    .p-figure-block__title {
      margin-bottom: vw(16);
    }
  
    .p-features-offer {
      margin-top: vw(-11);
      margin-bottom: vw(72);
      &__title {
        margin-bottom: vw(24 + 41);
      }
      &__text {
        font-style: normal;
        font-weight: 500;
        font-size: vw(16);
        line-height: 2;
        text-align: justify;
        
        p {
          margin-bottom: vw(16);
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      &__figure {
        margin-top: vw(24);
        img {
          width: 100%;
        }
      }
  
    }
    .p-features-service {
      margin-bottom: vw(160);
      &__title {
        font-style: normal;
        font-weight: 700;
        font-size: vw(20);
        line-height: vw(32);
        text-align: justify;
        margin-bottom: vw(8);
      }
      &__text {
        font-style: normal;
        font-weight: 500;
        font-size: vw(16);
        line-height: 2;
        text-align: justify;
        margin-bottom: vw(40);
        br {
          display: none;
        }
      }
      &__services {
        margin-bottom: vw(80);
      }
    }
    .p-features-otherservice {
      &__title {
        font-style: normal;
        font-weight: 700;
        font-size: vw(16);
        line-height: 2;
        align-items: center;
        color: $blue;
        margin-bottom: vw(1);
        margin-top: vw(-8);
      }
      &__lead {
        font-style: normal;
        font-weight: 700;
        font-size: vw(20);
        line-height: vw(32);
        text-align: justify;
        margin-bottom: vw(8);
      }
      &__text {
        font-style: normal;
        font-weight: 500;
        font-size: vw(16);
        line-height: 2;
        text-align: justify;
        margin-bottom: vw(16);
        
      }
      &__image {
        img {
          @include octagon(vw(46));
          width: 100%;
        }
      }
    }
  
    .p-features__work {
      margin-bottom: vw(160);
      .l-iceblock__background::after {
        @include octagon(vw(63))
      }
      &__list {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: vw(16) vw(15);
        margin-top: vw(4);
        width: 100%;
      }
      &__item {
        height: vw(56);
        text-align: center;
        img {
          height: 100%;
          object-fit: contain;
          width: 100%;
        }
      }
      .c-more {
        margin-bottom: vw(-4);
        margin-top: vw(32);
      }
  
    }
    .p-features__expert {
      margin-bottom: vw(156);
      display: flex;
      flex-direction: column;
      align-items: center;
      &__title {
        font-style: normal;
        font-weight: 700;
        font-size: vw(24);
        line-height: vw(40);
        text-align: center;
        color: $blue;
        margin-top: vw(-8);
        margin-bottom: vw(71);
      }
      .c-more {
        margin-top: vw(80);
      }
    }
  
  
  }
}