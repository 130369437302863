
// appearance
// flat 


.l-iceblock {
  margin: 0 auto;
  max-width: 1680px;
  position: relative;
  z-index: 5;

  
  & &__inner {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    
    // background-color: rgba(#fff, 0.3);
    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      background: url(#{$images}/texture--ice.png) ;
      opacity: 0.5;
      // mix-blend-mode: soft-light;
      will-change: transform;
      z-index: -1;
      backface-visibility: hidden;
    }
    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      // background-color: rgba(#fff, 0.5);
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.25) -34.43%, rgba(255, 255, 255, 0.5) 99.98%);

      
      // mix-blend-mode: soft-light;
      z-index: -2;
      will-change: transform;
      backface-visibility: hidden;
    }
    // background-blend-mode: soft-light;

    @include pc {
      @include octagon(317px);
      backdrop-filter: blur(5px);
      .level2-heading {
        margin-top: -16px;
      }
    }
    @include sp {
      @include octagon(vw(63));
      backdrop-filter: blur(vw(5));
    }
  }
  & &__body {
    @include pc {
      padding: 240px 120px;
      max-width: 1200px + 240px;
      min-height: (292px + 120px) * 2;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    @include sp {
      padding: vw(120 - 4) vw(32);
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  & &__background {
    pointer-events: none;
    user-select: none;
    position: absolute;
    @include pc {
      @include octobox_padding(120px);
    }
    @include sp {
      top: vw(32);
      right: vw(16);
      bottom: vw(32);
      left: vw(16);
    }

    // 氷の核
    &::after {
      content: "";
      display: block;
      // background-color: #fff;

      background: linear-gradient(0deg, rgba(255, 255, 255, 0.5) -67.65%, #FFFFFF 100%);
      
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: -1;
      @include pc {
        @include octagon(292px, 1);
      }
      @include sp {
        @include octagon(vw(48), 1);
      }
    }
  }
  @include sp {
    &--sm {
      .l-iceblock__inner {
        @include octagon(vw(55));
      }
      .l-iceblock__background::after {
        @include octagon(vw(48), 1);
      }
    }
  }
  @include pc {
    &--md {
      max-width: 1440px;
      margin: 0 auto;
      .l-iceblock {
        &__inner {
          @include octagon(244px);
        }
        &__body {
          min-height: (120px + 217px) * 2;
        }
        &__background {
          @include octobox_padding(120px);
          &::after {
            @include octagon(217px, 1);
          }
        }
      }
    }
    
    &--cramp {
      .l-iceblock {
        &__body {
          max-width: 1160px;
        }
        // &__body {
        //   min-height: (104px) * 2;
        //   padding: 108px 48px;
        // }
        // &__background {
        //   @include octobox_padding(48px);
        //   &::after {
        //     @include octagon(104px, 1);
        //   }
        // }
      }
    }
  
    &--sm {
      .l-iceblock {
        &__inner {
          @include octagon(120px);
        }
        &__body {
          min-height: (104px) * 2;
          padding: 108px 48px;
        }
        &__background {
          @include octobox_padding(48px);
          &::after {
            @include octagon(104px, 1);
          }
        }
      }
    }
  
    &--flat {
      .l-iceblock {
        &__inner {
          @include octagon(120px);
        }
        &__body {
          // padding: 160px 240px;
          padding-top: 160px;
          padding-bottom: 160px;
          min-height: (80px + 70px) * 2;
        }
        &__background {
          top: 48px;
          right: 48px;
          bottom: 48px;
          left: 48px;
          &::after {
            @include octagon(104.5px, 1);
          }
        }
      }
    }
    &--narrow {
      // max-width: 1440px;
      margin: 0 auto;
      .l-iceblock {
        &__inner {
          // @include octagon(244px);
        }
        &__body {
          min-height: (120px + 211px) * 2;
          max-width: 1160px;
          .u-wp-content p {
            margin-top: 11px;
          }
        }
        &__background {
          @include octobox_padding(120px);
          &::after {
            @include octagon(292px, 1.7);
          }
        }
      }
    }
  }


}


// animation
.l-iceblock {
  // transition: opacity 1.2s $curve_main, transform 1.2s $curve_main;
  // will-change: transform;
  // // transform: translateY(1rem);
  // opacity: 0;
  &__body {
    opacity: 0;
    transition: opacity 1.6s $curve_main, transform 1.2s $curve_main;
    // transition-delay: .8s;
    transform: translateY(1rem);
  }

  &.is-inview {
    // opacity: 1;
    // transform: translateY(0);
    .l-iceblock {
      &__body {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
  
}