
body.post-type-archive-service {
  @include sp {
    .l-page-header__action {
      display: none;
    }
    .l-page-header {
      .c-tel {
        display: none;
      }
    }
  }
}
body.page-template-service {
  @include pc {
    .l-service-lead {
      margin-bottom: 240px - 8;
    }
    .l-service-summary {
      margin-bottom: 240px;
    }
    .l-service-nav {
      margin-bottom: 160px - 12;
    }
    .l-service-main {
      margin-bottom: 80px;
    }
    .l-service-action {
      margin-bottom: 80px;
    }
    .l-service-experts {
      margin-bottom: 80px;
      .level2-heading {
        line-height: 2;
        margin-bottom: 20px;
      }
      .c-users {
        margin-top: 14px;
      }
      &__lead {
        margin-bottom: 30px;
      }
      &__body {
        margin-top: 39px;
      }
    }
    .l-service-works {
      margin-top: 39px;
      &__works {
        margin-top: 40px;
      }
    }
    .l-service-qa {
      margin-bottom: 80px;
    }
    .l-service-inquiry {
      &__form {
        margin-top: 80px;
      }
    }
    .l-page-header + .l-page-body {
      padding-top: 120px;
    }


    .c-image-list {
      & + .c-more {
        margin-top: 26px;
      }
    }
  }
  @include sp {
    .l-service-lead {
      margin-bottom: vw(160);
    }
    .l-service-summary {
      margin-bottom: vw(160);
    }
    .l-service-nav {
      margin-bottom: vw(160);
    }
    .l-service-main {
      margin-bottom: vw(80);
    }
    .l-service-action {
      margin-bottom: vw(80);
    }
    .l-service-experts {
      margin-bottom: vw(80);
      .level2-heading {
        line-height: 2;
        margin-bottom: vw(28);
      }
      .c-users {
        margin-top: vw(16);
      }
      &__lead {
        margin-bottom: vw(30);
      }
      &__body {
        margin-top: vw(39);
      }
    }
    .l-service-works {
      margin-top: vw(33);
      &__works {
        margin-top: vw(30);
      }
    }
    .l-service-qa {
      margin-bottom: vw(80);
    }
    .l-service-inquiry {
      &__form {
        margin-top: vw(80);
      }
    }
  }
}

.l-services-grid {
  @include pc {

    display: grid;
    justify-content: space-between;
    grid-template-columns: 1fr 1fr;
    gap: 80px 48px;
  }

  @include sp {
    display: grid;
    gap: vw(48);
    grid-template-rows: repeat(auto);

  }

}


.p-service-card  {
  @include pc {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &__image {
      @include octagon(85px);
      background-color: #ccc;
      aspect-ratio: 576/432;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
  
    }
    .u-action--target {
      // border: 1px solid #000;
      @include octagon(85px);
      height: 432px;
      // border: 1px solid #000;
      min-height: unset;
    }
    &__card {
      @include shadow;
      margin-top: -160px;
      height: 100%;
    }
    &__body {
      margin-left: 24px;
      margin-right: 24px;
      background-color: #fff;
      height: 100%;
      @include octagon(42px);
      padding: 48px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    &__number {
      @include roboto;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 32px;
      /* または200% */
      
      display: flex;
      align-items: center;
      margin-top: -8px;
      color: $blue;
      
    }
    &__title {
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 48px;
      margin-top: -12px;
      text-align: justify;
    }
    &__text {
      margin-top: 4px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 32px;
      /* または200% */
      
      // display: flex;
      // align-items: center;
      // text-align: justify;
      margin-bottom: 18px;
      
    }
    &__more {
      margin-bottom: 2px;
      margin-top: auto;
    }
  }
  @include sp {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: vw(-32);
    margin-right: vw(-32);
    &__image {
      @include octagon(vw(55));
      background-color: #ccc;
      aspect-ratio: 375/280;
      height: vw(280);
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
  
    }
    .u-action--target {
      // border: 1px solid #000;
      // @include octagon(85px);
      // height: vw(24);
      // border: 1px solid #000;
      min-height: unset;
    }
    &__card {
      @include shadow;
      margin-top: vw(-72);
      margin-left: vw(24);
      margin-right: vw(24);
    }
    &__body {
      // margin-left: 24px;
      // margin-right: 24px;
      background-color: #fff;
      height: 100%;
      @include octagon(vw(55));
      padding: vw(48) vw(32);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    &__number {
      @include roboto;
      font-style: normal;
      font-weight: 700;
      font-size: vw(16);
      line-height: 2;
      /* または200% */
      
      display: flex;
      align-items: center;
      margin-top: vw(-8);
      color: $blue;
      
    }
    &__title {
      font-style: normal;
      font-weight: 700;
      font-size: vw(20);
      line-height: vw(32);
      margin-top: vw(1);
      text-align: justify;
    }
    &__text {
      margin-top: vw(7);
      font-style: normal;
      font-weight: 500;
      font-size: vw(16);
      line-height: 2;
      text-align: justify;
      /* または200% */
      
      // display: flex;
      // align-items: center;
      // text-align: justify;
      margin-bottom: vw(18);
      
    }
    &__more {
      align-self: center;
      margin-top: vw(-2);
      margin-bottom: vw(-4);
    }
  }
}
