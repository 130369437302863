
  @keyframes caption {
    0% {
      opacity: 0;
      @include pc {
        transform: translateY(-10px);
      }
      @include sp {
        transform: translateY(#{vw(-20)});
      }
    }
    20% {
      opacity: 1;
      transform: translateY(0);
    }
    80% {
      opacity: 1;
      transform: translateY(0);
    }
    100% {
      opacity: 0;
      @include pc {
        transform: translateY(-10px);
      }
      @include sp {
        transform: translateY(#{vw(-20)});
      }
    }
  }

.p-slideshow {
  @include pc {
    position: relative;
    &__slider {
      aspect-ratio: 1066/800;
      // background-color: #ccc;
      position: relative;
    }
    &__slide {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
    &__image {
      @include octagon(8.22916667vw);
      opacity: 0; // for animation
      transition: opacity .7s linear;
      transition-delay: 0s;
      img {
        aspect-ratio: 1066/800;
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
    &__caption {
      position: absolute;
      z-index: 20;
      left: 0;
      bottom: 0;
      padding: 24px 24px 25px;
      // transition: opacity 1s $curve_main, transform .4s $curve_main;
      // transition-delay: 0s, 0.5s;
    }
    &__client {
      opacity: 0; // for animation
      display: flex;
      margin-bottom: 7px;
      align-items: center;
      dt {
        font-weight: 700;
        @include roboto;
        font-weight: 700;
        font-size: 14px;
        line-height: 14px;
        margin-right: 8px;
      }
      dd {
        font-style: normal;
        font-weight: 500;
        line-height: 14px;
        font-size: 14px;
      }
    }
    &__tag {
      display: inline-flex;
      opacity: 0; // for animation
      li {
        margin-right: 8px;
        font-size: 14px;
        font-weight: 500;
        &::before {
          content: '#';
        }
      }

    }
  
    &__nav {
      display: flex;
      align-items: center;
      position: absolute;
      right: 0;
      bottom: -56px;
    }
    &__arrow {
      width: 32px;
      height: 32px;
      @include hover(.3);
      background: imgurl('arrow--next.svg') center/contain no-repeat;
      &--prev {
        transform: scale(-1, 1);
        order: 1;
        margin-right: 17px;
      }
      &--next {
        order: 4;
        margin-left: 17px;
      }
    }
    &__playbutton {
      order: 5;
      width: 32px;
      height: 32px;
      margin-left: 24px;
      @include hover(.3);
      background: imgurl('playbutton.svg') center/contain no-repeat;
      [data-active="true"] & {
        background: imgurl('icon--pause.svg') center/contain no-repeat;
      }
    }
    &__readout {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 28px;
      
      /* main/blue */
      
      color: #1EA2D9;
      &--current {
        order: 2;
        display: flex;
        align-items: center;
        &::after {
          content: '';
          display: block;
          width: 78px;
          height: 1px;
          background: #1EA2D9;
          margin: 0 8px;
        }
      }
      &--total {
        order: 3;
      }
    }
  
    @for $i from 0 through 30 {
      &[data-slide-count="#{$i}"] {
        .p-slideshow__slide:nth-of-type(#{$i + 1}) {
          .p-slideshow__image {
            opacity: 1;
          }
          .p-slideshow__client {
            animation: caption 4.5s $curve_main #{0.5s + 0.5s} 1 normal forwards;
          }
          .p-slideshow__tag {
            animation: caption 4.5s $curve_main #{0.5s + .66667s} 1 normal forwards;
          }
        }
        // // next
        // .p-slideshow__slide:nth-of-type(#{$i + 2}) {
        //   .p-slideshow__image {
        //     opacity: 1;
        //   }
        //   .p-slideshow__caption {
        //     opacity: 0;
        //     transform: translateY(-20px);
        //   }
        // }
      }
    }
  
    &[data-active="false"] {
      @for $i from 0 through 30 {
        &[data-slide-count="#{$i}"] {
          .p-slideshow__slide:nth-of-type(#{$i + 1}) {
            .p-slideshow__client,
            .p-slideshow__tag {
              opacity: 1 ;
              transform: none ;
              animation: unset;
            }
          }
        }
      }
    }
  
    // &[data-slide-count="1"] {
    //   .p-slideshow__slide:nth-of-type(2) {
    //     opacity: 1;
    //   }
    // }
    // &[data-slide-count="2"] {
    //   .p-slideshow__slide:nth-of-type(3) {
    //     opacity: 1;
    //   }
    // }
  }
  @include sp {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    &__slider {
      aspect-ratio: 375/280;
      width: 100%;
      position: relative;
    }
    &__slide {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
    &__image {
      @include octagon(vw(55.5));
      opacity: 0; // for animation
      transition: opacity .7s linear;
      transition-delay: 0s;
      img {
        aspect-ratio: 375/280;
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
    &__caption {
      display: none;
      // position: absolute;
      // z-index: 20;
      // left: 0;
      // bottom: 0;
      // padding: vw(24);
    }
    // &__client {
    //   opacity: 0; // for animation
    //   display: flex;
    //   margin-bottom: vw(8);
    //   align-items: center;
    //   dt {
    //     font-weight: 700;
    //     @include roboto;
    //     font-weight: 700;
    //     font-size: vw(14);
    //     line-height: vw(14);
    //     margin-right: vw(8);
    //   }
    //   dd {
    //     font-style: normal;
    //     font-weight: 500;
    //     line-height: vw(14);
    //     font-size: vw(14);
    //   }
    // }
    // &__tag {
    //   opacity: 0; // for animation
    // }
  
    &__nav {
      display: flex;
      align-items: center;
      // position: absolute;
      right: 0;
      margin-top: vw(14);
    }
    &__arrow {
      width: vw(24);
      height: vw(24);
      background: imgurl('arrow--next_sp.svg') center/contain no-repeat;
      &--prev {
        transform: scale(-1, 1);
        order: 1;
        margin-right: vw(16);
      }
      &--next {
        order: 4;
        margin-left: vw(16);
      }
    }
    &__playbutton {
      order: 5;
      width: vw(24);
      height: vw(24);
      margin-left: vw(24);
      background: imgurl('playbutton.svg') center/contain no-repeat;
      [data-active="true"] & {
        background: imgurl('icon--pause_sp.svg') center/contain no-repeat;
      }
    }
    &__readout {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      font-size: vw(14);
      line-height: 2;
      
      /* main/blue */
      
      color: #1EA2D9;
      &--current {
        order: 2;
        display: flex;
        align-items: center;
        &::after {
          content: '';
          display: block;
          width: vw(78);
          height: vw(1);
          min-height: 1px;
          background: #1EA2D9;
          margin: 0 vw(8);
        }
      }
      &--total {
        order: 3;
      }
    }
  
    @for $i from 0 through 30 {
      &[data-slide-count="#{$i}"] {
        .p-slideshow__slide:nth-of-type(#{$i + 1}) {
          .p-slideshow__image {
            opacity: 1;
          }
          // .p-slideshow__client {
          //   animation: caption 4.5s $curve_main #{0.5s + 0.5s} 1 normal forwards;
          // }
          // .p-slideshow__tag {
          //   animation: caption 4.5s $curve_main #{0.5s + .66667s} 1 normal forwards;
          // }
        }
      }
    }
  
    &[data-active="false"] {
      @for $i from 0 through 30 {
        &[data-slide-count="#{$i}"] {
          .p-slideshow__slide:nth-of-type(#{$i + 1}) {
            .p-slideshow__client,
            .p-slideshow__tag {
              opacity: 1 ;
              transform: none ;
              animation: unset;
            }
          }
        }
      }
    }
  }
  
}