// archive common
body.archive {
  @include pc {
    .p-octobg--archive {
      // padding-bottom: 240px;
      z-index: 6;
      &::after {
        // border: 1px solid #000;
        margin-top: 368px;
      }
    }
    .p-pagination {
      margin-top: 120px;
    }
  }
  @include sp {
    .p-octobg--archive {
      z-index: 5;
      padding-top: vw(240);
      margin-top: vw(-200);
      background: linear-gradient(180deg, rgba(30, 162, 217, 0) 0%, rgba(30, 162, 217, 0.2) vw(4176 * 0.237), rgba(30, 162, 217, 0) vw(4176));
      &::after {
        pointer-events: none;
        height: vw(272);
        object-fit: cover;
        mask-image: linear-gradient(to bottom, #000 0px, transparent vw(272));
      }
    }
    .l-archive-header {
      margin-bottom: 0;
      position: relative;
    }
    .p-pagination {
      margin-top: vw(80 - 7);
      // margin-bottom: vw(6);
      position: relative;
    }
  }
}

body.post-type-archive-whitepaper {
  @include pc {
    .p-featured-download {
      margin-bottom: 160px;
    }
  }
  @include sp {
    .p-featured-download {
      margin-bottom: vw(160);
    }
  }
}


body.post-type-archive-works {
  @include pc {
    // .p-featured-download {
    //   margin-bottom: 160px;
    // }
  }
  // @include sp {
  //   .l-page-header__action {
  //     display: none;
  //   }
  // }

}

