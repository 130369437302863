
.p-octobg {
  &--cta {
    z-index: 10;
    @include pc {
      padding-top: 400px;
      background: linear-gradient(0deg, rgba(#ACDEF3, 0) -2.44%, rgba(#ACDEF3, 1) 39.18%, rgba(#ACDEF3, 0) 100%);

      &::before {
          content: '';
          display: block;
          width: 100%;
          top: 0;
          bottom: 0;
          position: absolute;
          z-index: -1;
          background: linear-gradient(0deg, rgba(#ACDEF3, 0) -2.44%, rgba(#ACDEF3, 1) 39.18%, rgba(#ACDEF3, 0) 100%);
          background-color: #fff;
          @include octagon_half();
      }
    }
    @include sp {
      position: relative;
      margin-top: vw(-40);
      padding-top: vw(248);

      
// background: linear-gradient(180deg, rgba(30, 162, 217, 0) 0%, rgba(30, 162, 217, 0.2) 11.98%, rgba(30, 162, 217, 0.2) 90.63%, rgba(30, 162, 217, 0) 100%);
      &::before {
        background: linear-gradient(0deg, #fff 0%, #ACDEF3 11.98%, #ACDEF3, #fff 100%);

        background: linear-gradient(0deg, #fff 0%, lighten(#ACDEF3, 13%) 11.98%, lighten(#ACDEF3, 13%), #fff 100%);
        @include content;
        @include octagon_half(vw(63));
        height: 100%;
        position: absolute;
        top: 0;
        z-index: -2;
        width: 100%;
      }

    }
  }
}
.l-cta {
  @include pc {
    padding-bottom: 240px;
    .l-iceblock .l-iceblock__body {
      padding-top: 160px;
      padding-bottom: 160px;
    }
    &__body {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &__lead {
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 52px;
      /* or 162% */
      
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      margin-bottom: 38px;
      margin-top: -10px;
      
    }
    &__action {
      margin-bottom: 80px - 48;
      // display: flex;
      // display: grid;
      // grid-template-columns: auto auto;
      // gap: 32px;
      align-items: center;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
  
    }
    .btn-group,
    .c-tel {
      margin: 0 16px;
      margin-bottom: 48px;
    }
    .cta-download {
      max-width: 800px;
      width: 100%;
      .p-download__inner {
        padding: 39px 0 46px;

      }
      .p-download__text {
        margin-bottom: 4px;
        font-size: 20px;
        line-height: 2;
        text-align: center;
      }
      .btn {
        max-width: 344px;
        width: 100%;
        height: 72px;
      }
    }
  }
  @include sp {

    padding-bottom: vw(120);
    // background-color: #000;
    // background-color: #fff;background: linear-gradient(180deg, rgba(#ACDEF3, 0) 0%, rgba(#ACDEF3, 1) 11.98%, rgba(#ACDEF3, 1) 90.63%, rgba(#ACDEF3, 0) 100%);

    // background: linear-gradient(180deg, rgba(#ACDEF3, 0) 0%, rgba(#ACDEF3, 1) 11.98%, rgba(#ACDEF3, 1) 90.63%, rgba(#ACDEF3, 0) 100%);
    // border: 1px solid #000;
  
    .u-octagon__inner {
      @include octagon(vw(63));
    }
    &__body {
      // border: 1px solid #000;
      // padding-top: vw(120);
      // padding-bottom: vw(120);
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &__lead {
      font-style: normal;
      font-weight: 700;
      font-size: vw(18);
      line-height: vw(32);
      text-align: center;
      margin-bottom: vw(44);
      margin-top: vw(-4);
      white-space: nowrap;
      h1 {
        margin-bottom: vw(24 - 8);
      }
      p {
        &:last-child {
          margin-bottom: 0;
        }
      }
      
    }
    &__action {
      // margin-bottom: 80px - 48;
      // margin-left: vw(-16);
      // margin-right: vw(-16);
      // border: 1px solid #000;
      // display: flex;
      // display: grid;
      // grid-template-columns: auto auto;
      // gap: 32px;
      align-items: center;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
  
    }
    .btn-group,
    .c-tel {
      // margin: 0 16px;
      margin-bottom: 48px;
    }
    .c-tel {
      margin-top: vw(-6);
    }
    .cta-download {
      width: 100%;
      .p-download {
        &__border {
          @include octagon(vw(46));
        }
        &__inner {
          @include octagon(vw(44.5));
          padding-top: vw(22);
          padding-bottom: vw(30);
        }
        &__text {
          margin-bottom: vw(-16);
          text-align: center;
        }
      }
      .btn .btn__icon {
        width: vw(20);
        height: vw(20);
        margin-right: vw(4);
      }
    }
  }
}