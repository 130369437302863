
.l-downloads {
  @include pc {
    // border: 1px solid #000;
    width: 100%;
    display: grid;
    gap: 48px 24px;
    grid-template-columns: 1fr 1fr;
    @include pc1440 {
      gap: vw1440(48) vw1440(24);
    }
    .p-download {
      display: block;
      height: 100%;
      &--horiz {
        .p-download {
          &__border {
            height: 100%;
          }
          &__inner {
            height: 100%;
            gap: 32px;
            padding: 46px 30px;
            grid-template-columns: 168px auto;
            @include pc1440 {
              gap: vw1440(32);
              padding: vw1440(46) vw1440(30);
            }
          }
          &__thumbnail {
            width: 168px;
            @include octagon(33px);
          }
        }
      }
    }
  }
  @include sp {
    margin-left: vw(-8);
    margin-right: vw(-8);
    .p-download {
      margin-bottom: vw(48);
      &:last-child {
        margin-bottom: 0;
      }
      &__title {
        font-weight: 500;
        font-size: vw(16);
        line-height: 32px;
      }
      &__button {
        margin-top: vw(15);
      }
    }
  }
}

.p-download {
  @include pc {
    @include shadow;
    width: 100%;
    &[href] {
      @include hover_color($green);
      &:hover {
        .btn {
          background-color: $green;
          &__icon {
            path {
              fill: #fff;
            }
          }
          &__text {
            color: #fff;
          }
        }
      }
    }
    &__border {
      display: block;
      @include octagon(42px);
      // background: $green;
      background: #B4DEAA;
      padding: 2px;
    }
    &__body {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &__inner {
      @include octagon(40.5px);
      background: #eaf5eb;
      padding: 30px;
    }
    // border: 2px solid $green;
    &__thumbnail {
      background-color: #ccc;
      @include octagon(42px);
      aspect-ratio: 288/216;
      margin-bottom: 35px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &__title {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      text-align: justify;
      margin-bottom: 24px - 7;
      width: 100%;
    }
    &__text {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 32px;
      margin-bottom: -8px;
    }
    &__button {
      margin-top: 19px;width: 100%;
      .btn {
  
        padding-left: 0;
        padding-right: 0;
      }
    }
    &--horiz {
      .p-download {
        &__inner {
          // display: flex;
          // flex-direction: row-reverse;
          display: grid;
          grid-template-columns: auto 216px;
          align-items: center;
          gap: 24px;
          direction: rtl;
        }
        &__body {
          direction: ltr;
        }
        &__title {
          font-weight: 500;
          font-size: 20px;
          line-height: 32px;
          margin-bottom: 3px;
        }
        &__thumbnail {
          margin: 0;
          width: 216px;
          aspect-ratio: 1/1;
          img {
            aspect-ratio: 1/1;
          }
        }
      }
    }
    &--xl {
      .p-download {
        &__border {
          @include octagon(120px);
        }
        &__inner {
          @include octagon(118.5px);
          padding: 46px;
          gap: 0;
          // grid-template-columns: auto 640px;
          // grid-template-columns: math.div(512, 1440) * 100% math.div(640, 1440) * 100%;
          // grid-template-columns:  42.66666667% auto;
          justify-content: center;
          display: flex;
          justify-content: center;
        }
        &__title {
          margin-bottom: 8px;
          margin-top: -10px;

          font-style: normal;
          font-weight: 700;
          font-size: 32px;
          line-height: 56px;
          
          display: flex;
          align-items: center;
          text-align: justify;
          
        }
        &__thumbnail {
          margin: 0;
          width: 100%;
          aspect-ratio: 512/376;
          margin-left: 48px;
          max-width: 512px;

          flex: math.div(512, 1440) * 100% 1 1;
          @include octagon(76px);
          img {
            aspect-ratio: 512/376;
          }
        }
        &__button {
          margin-top: 31px;
          max-width: 324px;
          @include pc1440 {
            max-width: vw1440(324);
          }
        }
        &__body {
          align-items: flex-start;
          max-width: 640px;
          flex: math.div(640, 1440) * 100% 1 1;
        }
      }
    }
  }
  @include sp {
    // filter: drop-shadow(0px 0px 24px rgba(0, 0, 0, 0.1));
    @include shadow;
    @include hover_color($green);
    display: block;
    width: 100%;
    &__thumbnail {
      aspect-ratio: 263/192;
      margin-bottom: vw(32);
      @include octagon(vw(39));
      img {
        width: 100%;
      }
    }
    &__border {
      display: block;
      @include octagon(vw(55));
      // background: $green;
      background: #B4DEAA;
      padding: vw(2);
    }
    &__inner {
      @include octagon(vw(53.4));
      background: #eaf5eb;
      // padding: 30px;
      padding: vw(46) vw(30);
    }
    &__title {
      font-style: normal;
      font-weight: 500;
      font-weight: bold;
      font-size: vw(20);
      text-align: center;
      line-height: 2;
      margin-top: vw(-8);
    }
    &__text {
      font-style: normal;
      font-weight: 500;
      font-size: vw(16);
      line-height: 2;
      text-align: justify;
      margin-bottom: vw(-8);
      margin-top: vw(7);
      &:first-child {
        margin-top: 0;
      }
    }
    &__button {
      margin-top: vw(16);
      margin-top: vw(32);
      .btn {
        padding-left: 0;
        padding-right: 0;
      }
    }
    // &--horiz {
    //   .p-download {
    //     &__inner {
    //       // display: flex;
    //       // flex-direction: row-reverse;
    //       display: grid;
    //       grid-template-columns: auto 216px;
    //       align-items: center;
    //       gap: 24px;
    //       direction: rtl;
    //     }
    //     &__body {
    //       direction: ltr;
    //     }
    //     &__title {
    //       font-weight: 700;
    //       font-size: 20px;
    //       line-height: 32px;
    //       margin-bottom: 3px;
    //     }
    //     &__thumbnail {
    //       margin: 0;
    //       width: 216px;
    //       aspect-ratio: 1/1;
    //       img {
    //         aspect-ratio: 1/1;
    //       }
    //     }
    //   }
    // }
    &--xl {
      width: auto;
      margin-left: vw(-8);
      margin-right: vw(-8);
    }
  }
}
