

.l-subsection {
  @include pc {
    padding-top: 240px;
    z-index: 8;
    padding-bottom: 240px;
    position: relative;
    &::before {
      @include content;
      @include octagon_half();
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      width: 100%;
      z-index: -1;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 50%, #FFFFFF 100%);
      background-size: 100% 62.5vw;
      height: 62.5vw;
    }
    &__title {
      margin-bottom: 80px - 16;
      margin-top: -16px;
      color: $blue;
      margin-top: 160px-11;
      text-align: center;
      // border: 1px solid #000;
      &:first-child {
        margin-top: -16px;
      }
    }
    .p-service {
      margin-top: 48px;
    }
  }
  @include sp {
    background: transparent;
    z-index: 8;
    position: relative;
    padding-top: vw(160);
    padding-bottom: vw(200);
    margin-top: vw(-40);
    &::before {
      @include content;
      @include octagon_half(vw(63));
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      width: 100%;
      z-index: -1;
      height: vw(1200);
      background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 50%, #FFFFFF 100%);
      background-size: auto vw(1200);
    }
    &__title {
      margin-bottom: vw(72);
      margin-top: vw(-8);
      color: $blue;
      text-align: center;
      margin-top: vw(151);
      &.level2-heading {
        text-align: center;
      }
      &:first-child {
        margin-top: vw(-8);
      }
    }
  }

}