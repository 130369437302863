
  .c-nav {
    @include pc {
      @include shadow;
      &__inner {
        padding: 44px - 8 44px;
        background-color: #fff;
        @include octagon(42px);
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }
      &__list {
        max-width: 900px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        
      }
      &__item {
        font-weight: 700;
        font-size: 16px;
        margin: 16px 12px;
        display: flex;
        align-items: center;
        text-align: right;
        
        /* main/blue */
        
        color: #1EA2D9;
        &:hover {
          .c-nav__arrow {
            background-color: $blue;
            path {
              stroke: #fff;
            }
          }
        }
      }
      &__arrow {
        margin-left: 8px;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        border: 2px solid $blue;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: background-color .4s $curve_main;
        background-color: #fff;
        position: relative;
        svg {
          top: -2px;
          left: -2px;
          transform: rotate(90deg);
          width: 24px;
          height: 24px;
          position: absolute;
        }
        path {
          stroke: $blue;
          transition: stroke .4s $curve_main;
        }
      }
      &--md {
        .c-nav {
          &__list {
            max-width: 780px;
          }
        }
      }
      &--sm {
        .c-nav {
          &__list {
            max-width: 700px;
          }
        }
      }
    }
    @include sp {
      margin-left: vw(-8);
      margin-right: vw(-8);
      @include shadow;
      &__inner {
        @include octagon(vw(55));
        background-color: #fff;
        padding: vw(48) vw(30);
      }
      &__item {
        display: flex;
        margin-bottom: vw(20);
        color: $blue;
        align-items: center;
        font-size: vw(16);
        font-weight: 700;
        line-height: vw(28);

        &:last-child {
          margin-bottom: 0;
        }
      }
      &__arrow {
        margin-left: vw(9);
        width: vw(24);
        height: vw(24);
        border-radius: 50%;
        border: vw(2) solid $blue;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: background-color .4s $curve_main;
        background-color: #fff;
        position: relative;
        svg {
          transform: rotate(90deg);
          width: vw(24);
          height: vw(24);
          position: absolute;
          top: vw(-2);
          left: vw(-2);
        }
        path {
          stroke: $blue;
          transition: stroke .4s $curve_main;
        }
      }
    }
  }
