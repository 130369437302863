
body.single-whitepaper {

  @include pc {
    .p-whitepaper {
      &__eyecatch {
        margin-bottom: 16px;
        img {
          width: 100%;
        }
      }
      &__content {
        h3 {
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 40px;
          /* または200% */
          
          display: flex;
          align-items: center;
          text-align: justify;
          color: $blue;
        }
        hr {
          margin-top: 41px;
        }
        ul:not(.notice):not(.c-checklist) {
          li {
            margin-bottom: 16px;
          }
        }
      }
    }
    .l-blog-row {
      grid-template-columns: 1fr 1fr;
      gap: 48px;

    }
    .p-download-form {
      @include shadow;
      &__inner {
        background-color: #ACDEF3;
        @include octagon(120px);
        padding: 120px 48px;
      }
      &__title {
        margin-bottom: 40px;
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 64px;
        /* または200% */
        
        display: flex;
        align-items: center;
        text-align: justify;
        margin-top: -15px;;
      }
    }
  }
  @include sp {
    .p-whitepaper {
      &__eyecatch {
        margin-top: vw(-48);
        margin-bottom: vw(24);
        img {
          width: 100%;
        }
      }
      &__content {
        margin-bottom: vw(-14);
        hr {
          display: none;
        }
        h3 {
          color: $blue;
          border-bottom: vw(2) solid #BBE4F3;
          margin-bottom: vw(28);
          padding-bottom: vw(13);
        }
        // ul:not(.notice):not(.c-checklist) {
        //   li {
        //     // margin-bottom: 16px;
        //   }
        // }
      }
    }
    .l-blog-row {
      gap: vw(160);
    }
    .l-blog-col {
      margin-bottom: 0;
    }
    .p-download-form {
      @include shadow;
      margin-left: vw(-32);
      margin-right: vw(-32);
      &__inner {
        background-color: #ACDEF3;
        @include octagon(vw(63));
        padding: vw(120) vw(32);
      }
      &__title {
        font-style: normal;
        font-weight: 700;
        font-size: vw(24);
        line-height: vw(40);
        /* または200% */
        
        display: flex;
        align-items: center;
        text-align: justify;
        margin-top: vw(-8);
        margin-bottom: vw(72);
        // margin-top: -15px;;
      }
    }
  }

}

body.post-type-archive-whitepaper {
  @include sp {
    .l-page-body {
      padding-bottom: vw(192);
    }
  }
}