// oirei
.c-hashtags {
  @include pc {
    display: inline-flex;
    flex-wrap: wrap;
    &__item {
      font-weight: 500;
      font-size: 14px;
      margin: 4px 8px 4px 0;
      
      display: flex;
      align-items: center;
      
      /* main/black 30% */
      
      color: rgba(0, 0, 0, 0.3);
      flex-grow: 0;
      margin-right: 8px;
      &::before {
        content: '#';
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  @include sp {
    display: inline-flex;
    flex-direction: column;
    flex-wrap: wrap;
    &__item {
      font-weight: 500;
      font-weight: 500;
      font-size: vw(14);
      color: rgba(0, 0, 0, 0.3);
      margin-bottom: vw(10);
      &::before {
        content: '#';
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
}