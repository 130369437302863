
.c-user {
  @include pc {
    &[href] {
      .c-user__title {
        transition: color .4s $curve_main;
      }
      &:hover {
        .c-user__title {
          color: $blue;
        }
      }

    }
    a {
      .c-user {
        &__title {
          transition: color .4s $curve_main;
        }
      }
      &:hover {
        .c-user {
          &__title {
            color: $blue
          }
        }
      }
    }
    &--panel {
      @include shadow;
      .c-user {
        &__inner {
          padding: 32px;
          background-color: #ACDEF3;
          @include octagon(42px);
        }
      }
    }
    &__inner {
      justify-content: space-between;
      display: flex;
      align-items: center;
    }
    &__thumbnail {
      overflow: hidden;
      border-radius: 50%;
      aspect-ratio: 1/1;
      img {
        display: block;
        border-radius: 50%;
        // background: #000;
        aspect-ratio: 1/1;
        background-color: #ccc;
        height: 120px;
        width: 120px;
        object-fit: cover;
        .c-user--lg & {
          height: 160px;
          width: 160px;
        }
      }
    }
    &__body {
      width: calc(100% - 120px - 16px);
      .c-user--lg & {
        width: calc(100% - 160px - 16px);
      }
    }

    &__header {
      display: flex;
      align-items: center;
      margin-bottom: 0px;
      margin-top: 8px;
      
    }
    &__title {
      font-weight: 700;
      font-size: 20px;
      line-height: 40px;
      white-space: nowrap;
    }
    &__subtitle {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.3);
      margin-left: 8px;
    }
    &__text {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 32px;
      /* or 200% */

      display: flex;
      align-items: center;
      text-align: justify;
    }
  }
  @include sp {
    &[href] {
      display: block;
    }
    &--panel {
      @include shadow;
      .c-user {
        &__inner {
          padding: vw(28) vw(32) vw(36);
          background: #ACDEF3;
          @include octagon(vw(46));
        }
      }

    }
    &__item {
      border: 1px solid #000;
    }
    &__thumbnail {
      float: left;
      width: vw(80);
      height: vw(80);
      background-color: #ccc;
      border-radius: 50%;
      margin-right: vw(16);
      img {
        border-radius: 50%;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &__header {
      height: vw(80);
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-bottom: vw(16);
    }
    &__title {
      font-weight: 700;
      font-size: vw(16);
      line-height: 2;
      margin-bottom: vw(2);
      margin-top: vw(-8);
    }
    &__subtitle {
      font-weight: 500;
      font-size: vw(14);
      line-height: vw(24);
      display: flex;
      align-items: center;
      word-break: keep-all;
      color: rgba(0, 0, 0, 0.3);
      margin-top: vw(-2);
      line-height: vw(20);
    }
    &__text {
      clear: both;
      font-style: normal;
      font-weight: 500;
      font-size: vw(14);
      line-height: 2;
      text-align: justify;
      margin-top: vw(-8);
      margin-bottom: vw(-8);
    }
  }
}

// oirei
.c-users {
  @include pc {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 48px;
    &--xs {
      display: grid;
      grid-template-columns: unset;
      grid-template-rows: repeat(1, auto);
      gap: 16px;
      .c-user {
        &__thumbnail {
          width: 120px;
          height: 120px;
          img {
            height: 120px;
            width: 120px;
          }
        }
        &__inner {
          // justify-content: space-between;
          // display: flex;
          // align-items: center;
          display: grid;
          grid-template-columns: 136px auto;
          align-items: center;
        }
        &__header {
          margin-top: 4px;
          margin-bottom: 3px;
        }
      }
    }
  }
  @include sp {
    display: grid;
    grid-template-rows: repeat(1, auto);
    gap: vw(32);
    &--xs {
      gap: vw(24);
    }
  }
}
