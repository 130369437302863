// oirei
@mixin __container_pc {
  max-width: 1440px + 160px;
  padding: 0 80px;
  margin: 0 auto;
  width: 100%;

  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  box-sizing: border-box;
  &--md {
    max-width: 1200px + 160px;
  }
  &--sm {
    max-width: 920px + 160px;
  }
}
@mixin __container_sp {
  padding: 0 vw(32);
}
.l-container {
  @include pc {
    @include __container_pc;
  }
  @include sp {
    @include __container_sp;
  }
}

