

body.single-seminar-event {
  @include pc {

    // .l-single-header {
    //   margin-bottom: -592px;
    // }
    // .l-single-header__title {
    //   padding-top: 160px;
    // }
    // .l-single-content {
    //   background: linear-gradient(180deg, rgba(30, 162, 217, 0) 0%, rgba(30, 162, 217, 0.2) 31.50%, rgba(30, 162, 217, 0) 100%);
    // }
  
    .l-page-body .p-page-title--md {
      margin-bottom: 69px;
    }
    // 概要
    .p-event-description {
      // margin-bottom: 72px;
      margin-bottom: 240px;
      // z-index: 8;
      position: relative;
      @include __clear-octobg-style;
      &__body {
        margin-bottom: 72px;
        p {
          margin-top: 12px;
        }
      }
    }

    .p-event-recommend {
      h3 {
        color: #000;
        margin-bottom: 8px;
        font-weight: 700;
        font-size: 24px;
        line-height: 48px;
        /* または200% */
        
        display: flex;
        align-items: center;
        text-align: justify;
      }
    }
  
    // 申し込み
    .p-subscribe {
      position: relative;
      z-index: 7;
      margin-top: 240px;
      &__title {
        margin-bottom: 80px - 16;
        margin-top: -16px;
        &:last-child {
          margin-bottom: -16px;
        }
      }
      &--closed {
        .p-subscribe__title {
          text-align: center;
        }
      }
    }
  }
  @include sp {

    .l-single-header {
      padding-bottom: 0;
      position: relative;
      z-index: 10;
      &::after {
        bottom: vw(8);
      }
    }

    .l-single-seminar {
      margin: 0;
      padding: 0;
    }
  
    // 概要
    .p-event-description {
      z-index: 8;
      position: relative;
      margin-top: vw(-8);
      padding-bottom: vw(160);
      // background: linear-gradient(0deg, rgba(30, 162, 217, 0.04) 1.52%, rgba(30, 162, 217, 0.1) 7.67%, rgba(30, 162, 217, 0.2) 90.77%, rgba(30, 162, 217, 0) 100%);

      p {
        margin-top: vw(16);

      }
      &::after {
        top: 0;
        margin-top: vw(-208);
        @include bg--even;
      }
      &__body {
        margin-bottom: 72px;
      }
    }
    .p-event-recommend {
      margin-left: vw(-8);
      margin-right: vw(-8);
      h3 {
        line-height: 2;
        margin-top: vw(-7);
        margin-bottom: vw(44);
      }
    }
  
    // 申し込み
    .p-subscribe {
      // background: linear-gradient(180deg, rgba(30, 162, 217, 0.2)  0%, rgba(30, 162, 217, 0) 100%);

      // background: linear-gradient(180deg,rgba(30, 162, 217, 0.04) 1.52%, rgba(30, 162, 217, 0.0) 100%);
      // padding-bottom: vw(200);
      // overflow: hidden;
      // half grad bottom
      
      position: relative;
      z-index: 7;
      padding-top: vw(160);
      // &--closed {
      // }
      &__title {
        margin-top: vw(-8);
        margin-bottom: vw(72);
        &:last-child {
          margin-bottom: vw(-8);
        }
      }
    }
  }

}
