
.p-header-description {
  // display: none !important;
  @include pc {
    container-type: inline-size;
    background-color: #fff;
    position: absolute;
    padding-left: 48px;
    padding-top: 24px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    display: flex;
    align-items: flex-start;
    z-index: 999;
    width: 100%;
    height: 136px;
    @include mq($BREAKPOINT, 1440px) {
      height: calc(#{vw1440(56)} + 80px);
    }
  }
  @include sp {
    // background: rgba(30, 162, 217, 0.2);
    background: #D2ECF7;
    font-weight: 400;
    font-size: vw(10);
    line-height: 1.6;
    display: flex;
    align-items: center;
    color: #1EA2D9;
    padding: vw(4) vw(24);
  }
}
.l-header {
  @include pc {
    // background-color: #fff;
    padding: 24px 48px;
    position: sticky;
    top: 0;
    margin-top: 32px + 10px;
    margin-bottom: -10px;
    display: grid;
    grid-template-columns: 207px auto;
    gap: 48px;
    @include mq($BREAKPOINT, 1440px) {
      gap: vw1440(24);
      grid-template-columns: vw1440(207) auto;
    }
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    transform: translateY(-10px);
    z-index: 1000;
    // transition: transform .1s ease-out, box-shadow .4s $curve_main;
    transition: transform .4s ease-out, box-shadow .4s $curve_main, background-color .4s ease-out;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0);
    &.sticky {
      background-color: #fff;
      transform: translateY(0px);
      box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.1);
    }
    &__logo {
      width: 207px;
      img {
        width: 100%;
        height: auto;
      }
      @include mq($BREAKPOINT, 1440px) {
        width: vw1440(207);
        height: vw1440(56);
      }
    }
    &__open {
      display: none;
    }
    &__dropdown {
      justify-self: start;
      display: grid;
      align-items: center;
      grid-template-columns: auto auto;
      // border: 1px solid #000;
      height: 56px;
      // border: 1px solid #000;
      width: 100%;
      @include mq($BREAKPOINT, 1600px) {
        height: vw1600(56);
        gap: vw1600(24);
      }
    }
    &__menu {
      display: flex;
      align-items: center;
    }
    &__button {
      justify-self: end;
      display: grid;
      grid-template-columns: auto auto;
      gap: 16px;
      .btn {
        padding-left: 30px;
        padding-right: 30px;
      }
      .btn__text {
        font-weight: 500;
        font-size: 16px;
      }
      @include mq($BREAKPOINT, 1600px) {
        gap: vw1600(16);
        .btn {
          padding: vw1600(10) vw1600(32);
          &__text {
            font-size: vw1600(16);
          }
        }
      }
    }
  
    @include sp {
      display: none;
    }
  }
  @include sp {
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 1000;
    
    &__body {
      background-color: #fff;
      height: vw(64);
      top: 0;
      left: 0;
      width: 100vw;
      z-index: 9999;
      position: relative;
      padding: vw(12) vw(24);
      transition: top .4s $curve_main, box-shadow .4s $curve_main;
      box-shadow: 0px 0px 24px rgba(0, 0, 0, 0);

      body.sticky & {
        box-shadow: 0px 0px 24px rgba(0, 0, 0, .1);
      }
      body.is-menu-active.sticky & {
        box-shadow: 0px 0px 24px rgba(0, 0, 0, 0) !important;
      }
      body:not(.sticky).is-menu-active & {
        box-shadow: 0px 0px 24px rgba(0, 0, 0, 0);
        top: vw(-40);
      }
    }
    &__logo {
      height: vw(40);
      top: 0;
      left: 0;
      img {
        height: vw(40);
      }

      
    }
    &__open {
      z-index: 9999;
      width: vw(32 + 16);
      height: vw(32 + 16);
      // position: fixed;
      position: absolute;
      top: vw(16 - 8);
      right: vw(24 - 8);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      // display: grid;
      // align-items: center;
      // gap: vw(8);

      &::before,
      &::after {
        @include content;
        width: vw(32);
        height: vw(2);
        min-height: 2px;
        background-color: $blue;
        margin: vw(4) 0;
        transition: transform .4s $curve_main;
        will-change: transform;
      }
      body.is-menu-active & {
        $arrow_offset: vw(5);
        &::before {
          transform: translateY($arrow_offset) rotate(45deg);
        }
        &::after {
          transform: translateY(-$arrow_offset) rotate(-45deg);
        }
      }
    }

    &__dropdown {
      // display: none;
      padding: vw(96 - 64) vw(32) vw(48);
      position: fixed;
      top: vw(64);
      left: 0;
      width: 100vw;
      // height: 100%;
      bottom: 0;
      overflow: auto;

    overscroll-behavior: contain;
      background-color: #fff;
      z-index: 9997;
      pointer-events: none;
      opacity: 0;
      transition: opacity .8s $curve_main;
      transition-delay: .3s;
      body.is-menu-active & {
        opacity: 1;
        pointer-events: auto;
        transition-duration: .0s;
        transition-delay: .1s;
      }
    }
    &__download {
      margin-top: vw(48);
      font-weight: 700;
      font-size: vw(16);
      line-height: 2;
      line-height: vw(46);
      display: block;
    }
    &__menu {
      .p-menu {
        margin-bottom: vw(44);
        &__icon {
          transition: transform .8s $curve_main, opacity .8s $curve_main;
          transform: translateX(vw(-11));
          opacity: 0;
          body.is-menu-active & {
            transform: translateX(0);
            opacity: 1;
          }
        }
        &__text {
          transition: transform .8s $curve_main, opacity .8s $curve_main;
          transform: translateX(vw(-11));
          opacity: 0;

          body.is-menu-active & {
            transform: translateX(0);
            opacity: 1;
          }
        }
        &__item {
          
          border-color: rgba(30, 162, 217, 0.0);
          transition: border-color .2s $curve_main .0s;

          body.is-menu-active & {
            border-color: rgba(30, 162, 217, 0.2);
            transition: border-color .8s $curve_main .2s;
          }
        }
        &--sub {
          .p-menu {
            &__item {
              body.is-menu-active & {
                border-color: rgba(76, 167, 55, 0.2);
              }
            }
          }
        }
        &__arrow {
          opacity: 0;
          transition: opacity .8s $curve_main .0s;

          body.is-menu-active & {
            transition: opacity .8s $curve_main .1666s;
            opacity: 1;
          }
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
      // margin-bottom: ;
    }


  }
}

.l-header-overlay {
  @include pc {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 999;
    background-color: rgba(#000, .2);
    pointer-events: none;
    opacity: 0;
    transition: opacity .8s $curve_main;
    body.inquiry-active & {
      pointer-events: auto;
      opacity: 1;
    }
  }
  @include sp {
    display: none;
  }
}

.p-header-inquiry {
  @include pc {
    position: relative;
    margin-right: 0;
    &:hover {
      .p-header-inquiry__body {
        opacity: 1;
        pointer-events: auto;
        transform: translateY(0);
      }
    }
    &__button { 
      margin-right: 0;
      z-index: 5;
      position: relative;
    }
    .btn {
      margin-right: 0;
      padding-left: 22px;
      &__arrow {
        transform: scale(1,1) rotate(90deg);
        transition: transform .15s $curve_main;
        path {
          stroke: $green;
        }
      }
      &:hover {
        background-color: $green;
        .btn__text {
          color: #fff;
        }
        .btn__arrow {
          transform: scale(1,-1) rotate(90deg);
          path {
            stroke: #fff;
          }
        }
      }
    }
    &__body {
      position: absolute;
      right: 0;
      // padding-top: 48px + 56;
      // margin-top: -56px;
      padding-top: 24px;
      margin-top: 0;
      opacity: 0; //dev
      position: absolute;
      pointer-events: none;
      transition: opacity .4s $curve_main, transform .6s $curve_main, margin .4s $curve_main, padding .4s $curve_main;
      transform: translateY(-10px);
      right: -24px;
      // transition: margin-top 1.4s $curve_main, padding-top 1.4s $curve_main;
  
      .sticky & {
        padding-top: 48px + 56;
        margin-top: -56px;
      }
    }
    &__panel {
      width: 320px;
      background: #FFFFFF;
      box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      padding: 15px 24px 16px;
    }
    .c-tel {
      padding-top: 3px;
      text-align: center;
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;
      text-align: center;
      &__title {
        text-align: center;
      }
    }
    .c-tel__header {
      margin-bottom: 2px;
      align-items: center;
      display: flex;
      flex-direction: column;
      text-align: center;
    }

  }
  @include sp {
    display: grid;
    grid-template-columns: 1fr;
    border: 1px solid #000;
    .btn {
      display: none;
    }
    &__menu {
      font-style: normal;
    }
  }
}

.inquiry-link {
  @include pc {
    height: 43px;
    border-bottom: 2px solid rgba(76, 167, 55, 0.2);
    line-height: 43px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    align-items: center;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    &__text {
      font-weight: 500;
      transition: color .4s $curve_main;
    }
    &__arrow {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      border: 2px solid $green;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: auto;
      transition: background-color .4s $curve_main;
      path {
        transition: stroke .4s $curve_main;
        stroke: $green;
      }
    }
    &:hover {
      .inquiry-link {
        &__text {
          color: $green;
        }
        &__arrow {
          background-color: $green;
          path {
            stroke: #fff;
          }
        }
      }
    }
    &--mail {
      // bd
      &::before {
        @include content;
        display: block;
        width: 24px;
        height: 24px;
        background: url('#{$images}/icon--mail24.svg');
        margin-right: 8px;
      }
    }
    &--line {
      // bd
      &::before {
        @include content;
        display: block;
        width: 24px;
        height: 24px;
        background: url('#{$images}/icon--line24.svg');
        margin-right: 8px;
      }
    }
  }
  @include sp {
    display: none;
    // &__text {
    //   font-weight: 700;
    //   font-size: 16px;
    //   line-height: 32px;
    // }
  }
}
