
// oirei


// header 192x56 172x56 / 172x56 arrow > SPではテキスト
// mv 344x72 270x72　> spでは四角
// cta 344x72 270x72
// dl 344x72
// main -------------------------
.btn {
  @include pc {
    @include btn;
    @include btn_pc;
    &--xs {
      @include btn--xs_pc;
    }
    &--sm {
      @include btn--sm_pc;
    }
    &--md {
      @include btn--md_pc;
    }
    &__icon {
      path {
        transition: fill .4s $curve_main, stroke .4s $curve_main;
      }
    }
    &__arrow {
      margin-right: -8px;
      margin-left: 4px;
      width: 24px;
      height: 24px;
      path {
        transition: fill .4s $curve_main, stroke .4s $curve_main;
      }
    }
  }
  @include sp {
    @include btn;
    @include btn_sp;
  }
  &--primary {
    @include set_button_color($blue);
  }
  &--secondary {
    @include set_button_color($green);
  }

}

.btn-group {
  @include pc {
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: 16px;
  }
  // @include pc {
  //   display: flex;
  //   .btn {
  //     margin-right: 16px;
  //     &:last-child {
  //       margin-right: 0;
  //     }
  //   }
  // }
  @include sp {
    width: 100%;
    display: grid;
    gap: vw(16);
  }
}

@mixin __clear-button-styles {
  display: block;
  white-space: unset;
  justify-content: unset;
  align-items: unset;
  flex-direction: unset;
  line-height: auto;
  font-size: unset;
  font-weight: unset;
  box-shadow: unset;
  border-radius: unset;
  transition: unset;
  height: unset;
  background-color: unset;
  border: unset;
  line-height: unset;
  color: unset;

}