body.page-template-company {
  @include pc {

    .l-company {
      &__access {
        .level2-heading {
          margin-top: -8px;
          margin-bottom: 48px - 16;
        }
      }
    }
  
    .c-nav {
      margin-bottom: 160px;
    }
    .p-detail-info {
      margin-bottom: 240px;
    }
  
    .p-shop {
      display: grid;
      gap: 48px;
      align-items: center;
      grid-template-columns: 392px 480px;
      & + .p-shop {
        margin-top: 48px;
      }
  
      &__title {
        margin-bottom: 10px;
      }
      &__address {
        margin-top: 9px;
      }
      // &__body {
      //   border: 1px solid #000;
      // }
      &__map {
        iframe {
          height: auto;
          width: 100%;
          aspect-ratio: 480/360;
        }
      }
    }
  }
  @include sp {
    .l-company {
      
      &__access {
        margin-top: vw(-8);
        .level2-heading {
          margin-bottom: vw(-8);
        }
      }
    }
    .p-shop {
      &__title {
        // margin-bottom: vw(8);
        margin-top: vw(40);
      }
      &__address {
        // border: 1px solid #000;
        margin-top: vw(16);
        p {
          margin-top: vw(8);
        }
      }
      &__map {
        margin-top: vw(16);
        iframe {
          width: 100%;
          height: auto;
          aspect-ratio: 311/232;
        }
      }
    }
    .c-nav {
      margin-bottom: vw(160);
    }
    .p-detail-info {
      margin-bottom: vw(160);
    }
  }
}
