
.p-sp-footer {
  @include pc {
    display: none;
  }

  position: fixed;
  bottom: 0;
  z-index: 900;
  left: 0;
  height: vw(72);
  width: 100vw;
  display: grid;
  grid-template-columns: auto vw(145);
  gap: vw(1);
  color: #fff;
  background-color: #fff;
  transform: translateY(#{vw(72)});
  transition: transform .4s $curve_main .0s;
  // transition
  body.scroll-up & {
    // transition-delay: .4s;
    transform: translateY(0);
  }
  display: grid;
  gap: vw(2);
  grid-template-columns: 1fr 1fr vw(145);

  &__item {
    @include __clear-button-styles;
    background-color: $green;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: vw(12);
    &--tel {
      background-color: $blue;
    }
  }
  &__icon {
    width: vw(16);
    height: vw(16);
    margin-bottom: vw(5);
    svg {
      display: block;
      width: 100%;
      height: 100%;
      path {
        fill: #fff;
      }
    }
  }
  &__text {
    text-align: center;
    font-weight: 700;
    font-size: vw(10);
    line-height: 1.6;
    small {
      font-weight: 500;
      font-size: vw(8);
      line-height: 2;
    }
  }
}