body.home {
  background-color: #fff;
  @include pc {
    .p-index-features {
      padding-top: vw1920(688);
      margin-top: vw1920(-528 - 48);
    
      background: linear-gradient(
          180deg,
          rgba(30, 162, 217, 0) 0%,
          rgba(30, 162, 217, 0.2) 50%,
          rgba(30, 162, 217, 0) 100%
          
        );
      &__row {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 24px;
      }
    }
    .p-index-feature {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      z-index: 5;
      
      .l-iceblock__body {
          padding-left: 48px;
          padding-right: 48px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          justify-content: center;
          height: 100%;
          padding: 88px 48px;
      }
      &__icon {
        margin-bottom: 16px;
      }
      &__title {
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 56px;
        text-align: center;
        
        /* main/black */
        
        color: #000000;
        
        margin-bottom: 32px - 23;
      }
    
      &__text {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 40px;
        text-align: center;
        max-width: 472px;
        
        /* main/black */
        
        color: #000000;
      }
      .c-more {
        margin-top: 32px - 8;
      }
    }
    .l-index-services {
      padding-bottom: 430px;
      padding-top: 480px;
      .l-section__body {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .p-services {
        margin-bottom: 120px;
      }
    }
  
    .p-octobg--services {
      margin-top: -240px;
      background: linear-gradient(0deg, rgba(30, 162, 217, 0.04) 0%, rgba(30, 162, 217, 0.2) 50%, rgba(30, 162, 217, 0) 100%);
    }
  
    .l-index-works {
      padding-bottom: 736px;
      background: linear-gradient(0deg, rgba(30, 162, 217, 0) 0%, rgba(30, 162, 217, 0.2) 50%, rgba(30, 162, 217, 0) 100%);

      &__works {
        margin-bottom: 85px;
      }
      .c-logo-list {
        margin-bottom: 120px;
        // width: 100%;
        // display: grid;
        // gap: 24px 48px;
        // grid-template-columns: repeat(3, 1fr);
        // &__item {
        //   border: 1px solid #000;
        //   height: 80px;
        //   // display: flex;
        //   // flex-direction: column;
        //   // justify-content: center;
        //   // align-items: center;
        //   // grid-column: 1 / 2;
        //   position: relative;
        //   img {
        //     height: 100%;
        //     width: 100%;
        //     position: absolute;
        //     width: auto;
        //     object-fit: cover;
        //     // position: relative;
        //   }
        // }
      }
    }
  
    .l-index-experts {
      background: transparent;
      margin-top: -736px;
      .c-users {
        margin-bottom: 120px;
      }
    }
  
    .l-index-seminar {
      background: transparent;
      &::after {
        background: linear-gradient(
          180deg,
          rgba(30, 162, 217, 0.2) 0%,
          rgba(30, 162, 217, 0.2) 50%,
          rgba(30, 162, 217, 0) 100%
        );
        height: 100%;
        // transform: rotate(-180deg);
      }
  
      .l-section__body {
        padding-bottom: 0;
      }
      &__cards {
        margin-bottom: 120px;
        .c-card__title {
          margin-bottom: 15px;

        }
      }
    }
  
    .l-index-media {
      background: transparent;
  
      .l-section__body {
        padding-bottom: 0;
      }
      .l-articles {
        margin-bottom: 120px;
      }
    }
  
  
    .l-index-column {
      background: transparent;
      // padding-bottom: 240px;
  
      &__row {
        display: grid;
        grid-template-columns: minmax(250px, 23.5%) auto;
        gap: 48px;
        align-items: start;
      }
      .c-more {
        margin-top: 120px;
      }
      &__col {
        vertical-align: top;
      }
      .l-section__header {
        align-items: flex-start;
        text-align: left;
        margin-bottom: 81px;
      }
      .p-column-categories__header {
        margin-bottom: 44px;
      }
      .l-section__title {
        align-items: flex-start;
      }
      .l-section__lead {
        text-align: left;
      }
    }
  
  
    .p-column-categories {
      &__header {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        display: flex;
        align-items: center;
        color: #1ea2d9;
        margin-bottom: 28px;
      }
    }
  }
  @include sp {
    .p-index-features {
      padding-top: vw(400);
      margin-top: vw(-400);
      background: linear-gradient(0deg, rgba(30, 162, 217, 0.06) 0%, rgba(30, 162, 217, 0.2) 50%, rgba(30, 162, 217, 0) 100%);
      &__row {
        // width: 100%;
        display: grid;
        grid-template-rows: auto auto;
        gap: vw(24);
        margin-left: vw(-8);
        margin-right: vw(-8);
      }
    }
    .p-index-feature {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      z-index: 5;
      .l-iceblock__body {
        padding: vw(48) vw(32);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      &__icon {
        width: vw(80);
        height: vw(80);
        margin-bottom: vw(16);
        img {
          width: 100%;
        }
        
      }
      &__title {
        font-style: normal;
        font-weight: 700;
        font-size: vw(24);
        line-height: vw(40);
        margin-top: vw(-8);
        text-align: center;
        /* main/black */
        
        color: #000000;
        
        margin-bottom: vw(8);
      }
    
      &__text {
        font-style: normal;
        font-weight: 500;
        font-size: vw(16);
        line-height: 2;
        text-align: justify;
        
        /* main/black */
        
        color: #000000;
      }
      .c-more {
        margin-top: vw(18);
        margin-bottom: vw(2);
      }
    }
    .p-octobg--services {
      margin-top: vw(-48);
      padding-top: vw(208);
      background: linear-gradient(0deg, rgba(30, 162, 217, 0.04) 0%, rgba(30, 162, 217, 0.2) 10.42%, rgba(30, 162, 217, 0.2) 90.63%, rgba(30, 162, 217, 0) 100%);
    }
    .l-index-services {
      padding-bottom: vw(160);
      
      .c-more {
        margin-top: vw(80);
      }
    }
    
  
  
    .p-octobg--works {
      margin-top: vw(-40);
      padding-top: vw(208);
      background: linear-gradient(0deg, rgba(30, 162, 217, 0) 0%, rgba(30, 162, 217, 0.2) 90.63%, rgba(30, 162, 217, 0) 100%);
    }
    .l-index-works {
      margin-bottom: vw(40);
      &__works {
        margin-bottom: 80px;
      }
      .c-more {
        margin-top: vw(82);
      }
      .c-hashtags {
        display: flex;
        flex-direction: column;
        margin-bottom: 0;
        &__item {
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
    .l-index-experts {
      background: transparent;
      padding-bottom: vw(80);
      .c-users {
        margin-bottom: vw(80);
      }
    }
    .p-octobg--static {
      z-index: 5;
      &::after {
        background: linear-gradient(0deg, rgba(30, 162, 217, 0) 0%, rgba(30, 162, 217, 0.2) 90.63%, rgba(30, 162, 217, 0.2) 100%);
        height: 100%;
        position: absolute;
        mask-image: unset;
      }
    }
    .l-index-seminar {
      background: transparent;
      margin-top: vw(-40);
      .l-section__body {
        padding-bottom: 0;
        padding-top: vw(160);
      }
      &__cards {
        margin-bottom: vw(80);
      }
    }
    .l-index-media {
      background: transparent;
      margin-bottom: vw(40);
      .l-section__body {
        padding-bottom: 0;
        padding-top: vw(160);
        // padding-left: vw(24);
        // padding-right: vw(24);
      }
      .l-articles {
        margin-bottom: vw(80);
      }
    }
  
  
    .l-index-column {
      background: transparent;
      .l-section__body {
        padding-bottom: vw(200);
      }
      &__row {
        // display: grid;
        // // grid-template-columns: minmax(250px, 23.5%) auto;
        // gap: 48px;
        // align-items: start;
        // border: 1px solid #000;
        display: grid;
        grid-template-rows: repeat(auto, auto);
        gap: vw(80);
      }
      .c-more {
        margin-top: vw(80);
      }
    }
  
  
    .p-column-categories {
      &__header {
        font-style: normal;
        font-weight: 700;
        font-size: vw(16);
        // line-height: 32px;
        display: flex;
        align-items: center;
        color: #1ea2d9;
        margin-bottom: vw(28);
      }
    }
  }
}



.c-logo-list {
  @include pc {
    width: 100%;
    display: grid;
    gap: 24px 48px;
    grid-template-columns: repeat(4, 1fr);
    &__item {
      height: 80px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  @include sp {
    width: 100%;
    display: grid;
    gap: vw(16) vw(15);
    grid-template-columns: repeat(2, 1fr);
    // border: 1px solid #000;
    &__item {
      height: vw(56);
      // display: flex;
      // flex-direction: column;
      // justify-content: center;
      // align-items: center;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}