.c-checklist {
  @include pc {
    list-style: none;
    margin-left: 0;
    margin-top: 21px;
    li {
      display: flex;
      margin-bottom: 15px;
      line-height: 2;
      // align-items: ;
      &:last-child {
        margin-bottom: 0;
      }
      &::before {
        flex: 32px 0 0;
        margin-right: 8px;
        // margin-top: -8px;
        @include content;
        width: 32px;
        height: 32px;
        background: url('#{$images}/icon--check.svg') center no-repeat;
        // margin-top: -3px;
      }
    }
  }
  @include sp {
    list-style: none;
    margin-left: 0;
    margin-top: vw(35);
    margin-bottom: vw(-4);
    li {
      display: flex;
      margin-bottom: vw(32);
      font-weight: 500;
      font-size: vw(16);
      line-height: 2;
      text-align: justify;
      margin-top: vw(-8);
      &:last-child {
        margin-bottom: 0;
      }
      &::before {
        flex: vw(24) 0 0;
        margin-right: vw(8);
        @include content;
        width: vw(24);
        margin-top: vw(4);
        height: vw(24);
        background: url('#{$images}/icon--check_sp.svg') center/contain no-repeat;
        // margin-top: -3px;
      }
    }
  }
}