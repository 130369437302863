
.c-tel {
  &--blue {
    .c-tel__inner {
      // background: rgba(30, 162, 217, 0.2);
      background-color: #ACDEF3;
    }
  }
  @include pc {
    display: block;
    &__inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      white-space: nowrap;
      width: 100%;
    }
    &--has-background {
      @include shadow;
      .c-tel__inner {
        padding: 16px 24px 24px;
        @include octagon(22px);
      }
    }
    &__header {
      // display: flex;
      display: grid;
      grid-template-columns: auto auto;
      gap: 9px;
      align-items: center;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 28px;
      margin-bottom: 4px;
      .c-tel--lg & {
        margin-bottom: 10px;
        font-size: 16px;
      }
    }
    // &__title {
    // }
    &__number {
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      /* or 200% */
      
      display: flex;
      align-items: center;
      text-align: center;
      
      @include roboto;
      &::before {
        @include content;
        display: block;
        width: 24px;
        height: 24px;
        background: url('#{$images}/icon--tel24.svg') center/cover no-repeat;
        margin-right: 4px;
        flex: 24px 0 0;
        .c-tel--lg & {
          width: 32px;
          height: 32px;
          margin-right: 8px;
          flex: 32px 0 0;
        }
      }
  
      .c-tel--lg & {
        font-size: 32px;
        height: 32px;
      }
    }
    // &--scale {
    //   .c-tel {
    //     &__header {
    //       font-size: vw1920(14);
    //       line-height: vw1920(28);
    //       margin-bottom: vw1920(4);
    //       gap: vw1920(9);
    //     }
    //     &__number {
    //       font-size: vw1920(24);
    //       &::before {
    //         width: vw1920(24);
    //         height: vw1920(24);
    //         margin-right: vw1920(4);
    //         .c-tel--lg & {
    //           width: vw1920(32);
    //           height: vw1920(32);
    //           margin-right: vw1920(8);
    //         }
    //       }
    //     }
    //   }
    // }
    // &--center {
    //   .c-tel {
    //     &__inner {
    //       border: 1px solid #000;
    //     }
    //   }
      
    // }
  }
  @include sp {

    display: flex;
    flex-direction: column;
    align-items: center;
    white-space: nowrap;

    &__header {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: vw(24);
    }
    &__title {
      font-style: normal;
      font-weight: 500;
      font-size: vw(16);
      line-height: vw(32);
      margin-bottom: vw(8);
      margin-top: vw(-2);
    }
    &__hour {
      font-size: vw(16);
      

    }
    &__number {
      font-style: normal;
      font-weight: 700;
      font-size: vw(24);
      /* or 200% */
      
      display: flex;
      align-items: center;
      text-align: center;
      
      @include roboto;
      &::before {
        @include content;
        display: block;
        width: vw(24);
        height: vw(24);
        background: url('#{$images}/icon--tel24.svg') center/cover no-repeat;
        margin-right: vw(8);
        // .c-tel--lg & {
        //   width: 32px;
        //   height: 32px;
        //   margin-right: 8px;
        // }
      }
  
      // .c-tel--lg & {
      //   font-size: 32px;
      //   height: 32px;
      // }
    }
  }
}