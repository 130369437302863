@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@500;600;700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

@mixin number {
  font-family: 'HelveticaNeue-CondensedBold', 'helvetica neue', 'helvetica', 'Yantramanav', sans-serif;
}
@mixin barlow {
  font-family: 'Barlow', sans-serif;
}
@mixin lato {
  font-family: 'lato', 'arial', 'helvetica', sans-serif;
}
@mixin roboto {
  font-family: 'roboto', sans-serif;
}
@mixin poppins {
  font-family: 'Poppins', sans-serif;
}
@mixin serif {
  font-family: 'Noto Serif JP', serif;
}


@mixin noto {
}
@mixin montserrat {
  font-family: 'Montserrat', sans-serif;
}
@mixin jost {
  font-family: 'Jost', sans-serif;
}
@mixin noto_sans {
  font-family: 'Noto Sans JP', sans-serif;
}
@mixin noto_serif {
  font-family: 'Noto Serif JP', serif;
}

@mixin  yumin_local() {
  font-family: 'Yu Mincho Pr6', serif;
}
@mixin  yugo() {
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
}
@mixin  yumin() {
  font-family: yu-mincho-pr6, 'Yu Mincho Pr6', sans-serif;
}

@mixin  baskerville() {
  font-family: baskerville-urw, serif;
}
@mixin  lato() {
  font-family: lato, sans-serif;
}
@mixin  shuei() {
  font-family: dnp-shuei-mincho-pr6, sans-serif;
}