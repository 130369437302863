// oirei
// done px sp

.l-footer {
  background-color: #fff;
  @include pc {
    padding: 120px 48px 0;
    margin-top: auto;
    &__row {
      // display: flex;
      // justify-content: space-between;
      &--main {
        border-bottom: 2px solid rgba(30, 162, 217, 0.2);
        padding-bottom: 208px;
        display: grid;
        grid-template-columns: minmax(240px, 444px) minmax(640px * 0.9, 1032px);
        gap: 48px;
        justify-content: space-between;
        padding-left: 32px;
      }
      &--sub {
        border: 0;
        padding: 0;
        justify-content: space-between;
        display: grid;
        grid-template-columns: auto auto;
      }
    }
    &__logo {
      margin-bottom: 26px;
      display: block;
      &[href] {
        @include hover;
      }
      img {
        max-height: 120px;
        width: 100%;
      }
    }
    &__address {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 28px;
      display: flex;
      align-items: center;
    }
    &__copyright {
      padding: 24px 0;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      /* or 229% */
      
      display: flex;
      align-items: center;
      
      /* main/black 30% */
      
      color: rgba(0, 0, 0, 0.3);
      
    }
  }
  
  @include sp {
    padding: vw(80) vw(32) vw(32);
    &__row {
      &--main {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
      }
      &--sub {
        border: 0;
        padding: 0;
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        align-items: center;
      }
    }
    &__col {
      display: flex;
      flex-direction: column;
    }
    &__logo {
      margin-bottom: vw(24);
      height: vw(61);
      display: block;
      // background-color: #0ff;
      text-align: center;
      margin-top: vw(2);
      img {
        height: vw(61);
        width: auto;
      }
    }
    &__address {
      margin-top: vw(-7);
      font-style: normal;
      font-weight: 500;
      font-size: vw(14);
      line-height: 2;
      text-align: center;
      margin-bottom: vw(48 - 14);
    }
    .c-footer-menu {
      border-bottom: vw(2) solid rgba(30, 162, 217, 0.2);
      margin-bottom: vw(46);
      padding-bottom: vw(32);
      margin-top: vw(-16);
    }
    .c-other-links {
      margin-bottom: vw(48 - 12);
    }
    &__copyright {
      font-weight: 500;
      font-size: vw(10);
      line-height: 2;
      display: flex;
      align-items: center;
      text-align: center;
      color: rgba(0, 0, 0, 0.3);
      margin-bottom: vw(-6);
    }
  }
}


.c-footer-menu {
  @include pc {
    display: grid;
    gap: 24px;
    grid-template-columns: minmax(auto, 240px) minmax(auto, 240px) minmax(auto, 240px) minmax(auto, 240px);
    &__col {
      display: grid;
      gap: 32px;
      align-content: start;
      // border:  solid #000;
    }
    &__item {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 16px;
      a {
        @include hover_color;
      }
      
    }
  }
  @include sp {
    &__col {
      display: flex;
      flex-direction: column;
    }
    &__item {
      font-weight: 700;
      font-size: vw(16);
      line-height: 2;
      margin: vw(8) 0;
      position: relative;
    }

  }
}


.c-footer-submenu {
  @include pc {
    &__toggle {
      display: none;
    }
    &__list {
      gap: 12px;
      display: grid !important;
      height: auto !important;
      margin-top: 26px !important;
    }
    &__item {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 28px;
      a {
        @include hover_color;
      }
    }
  }
  @include sp {
    &__toggle {
      width: vw(16);
      height: vw(16);
      background: imgurl('arrow--footer-submenu.svg') center/vw(16) no-repeat;
      position: absolute;
      top: 0;
      right: 0;
      top: vw(8);

      // hitarea
      &::after {
        @include content;
        top: vw(-12);
        right: vw(-12);
        bottom: vw(-12);
        left: vw(-12);
        position: absolute;
        background-color: transparent;
      }

      &.is-active {
        transform: scale(1, -1);
      }

    }
    &__list {
      // padding-top: vw(6);
      padding-bottom: vw(2);
      display: grid;
      display: none;
    }
    &__item {
      font-weight: 500;
      font-size: vw(14);
      line-height: 2;
      display: flex;
      align-items: center;
      & > a {
        margin-top: vw(4);
        
      }
      &:first-child {
        margin-top: vw(6);
      }
    }
  }
}