
.p-filterbox {
  @include pc {
    @include shadow;
    &__inner {
      background-color: #fff;
      @include octagon(42px);
      width: 100%;
      padding: 48px 120px 56px;
      display: grid;
      grid-template-columns: auto auto;
      justify-content: space-between;
      align-items: end;
      gap: 48px;
      @include pc1440 {
        // border: 1px solid #000;
        // grid-template-rows: auto auto;
        // grid-template-columns: 1fr;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
    &__title {
      font-weight: 700;
      font-size: 20px;
      line-height: 40px;
      /* or 200% */
      
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      margin-top: -7px;
      color: $blue;
      
    }
    &__params {
      display: inline-flex;
      flex-wrap: wrap;
      margin-top: -8px;
      margin-bottom: -7px;
      .c-checkbox {
        margin-right: 24px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    &__item {
      margin-top: 8px;
    }
    .btn-group {
      margin-bottom: -9px;
      .btn__text {
        font-weight: 600;
      }
    }
  }
  @include sp {
    @include shadow;
    margin-left: vw(-8);
    margin-right: vw(-8);
    &__inner {
      background-color: #fff;
      @include octagon(vw(55));
      padding: vw(48) vw(32);
    }
    &__title {
      font-weight: 700;
      font-size: vw(16);
      line-height: 2;
      margin-bottom: vw(24);
      margin-top: vw(-8);
      color: $blue;
      
    }
    &__col {
      width: 100%;
    }
    &__params {
      display: grid;
      grid-template-rows: repeat(1, auto);
      gap: vw(23);
      flex-wrap: wrap;
      margin-bottom: vw(32);
    }

    // .btn-group {
    //   gap: vw(24);
    // }
    .btn__icon {
      width: vw(20);
    }
  }
}