
.p-figure-blocks{
  @include pc {
    display: grid;
    grid-template-rows: repeat(auto);
    gap: 120px;
    .p-figure-block {
      margin-bottom: 0;
      &:nth-of-type(2n + 1) {
        flex-direction: row-reverse;
        .p-figure-block__image {
          margin-left: 0;
          margin-right: -219px;;
        }
      }
    }
  }
  @include sp {
    display: grid;
    grid-template-rows: repeat(auto);
    gap: vw(48);
    .p-figure-block {
      margin-bottom: 0;
    }
  }
}
.p-figure-block {
  @include pc {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 240px;
    // .l-iceblock {
    // }
    &__content {
      width: 100%;
      margin: 0;
      max-width: 800px;
      min-height: unset;
  
      .l-iceblock__inner {
        @include octagon(135px);
      }
      .l-iceblock__body {
        padding: 120px;
        align-items: flex-start;
        min-height: unset;
      }
      .l-iceblock__background {
        @include octobox_padding(80px);
        &::after {
          @include octagon(118px, 1);
        }
      }
    }
    &__label {
      @include roboto;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 32px;
      /* または200% */
      
      display: flex;
      align-items: center;
      color: $blue;
      margin-bottom: 13px;
      margin-top: -7px;
    }
    &__title {
      font-style: normal;
      font-weight: 700;
      margin-bottom: 12px;
      margin-top: -9px;
      font-size: 24px;
      line-height: 48px;
      text-align: justify;
    }
    &__image {
      width: 59.652778%;
      margin-left: -219px;
      @include octagon(127px);
      img {
        aspect-ratio: 859/640;
        width: 100%;
      }
    }
    &__text {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 32px;
      p {
        margin-bottom: 8px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    &--md {
      .p-figure-block {
        &__title {
          font-size: 32px;
          line-height: 56px;
          margin-bottom: 14px;
          margin-top: -9px;
        }
      }
    }
    .c-more {
      margin-top: 16px;
    }
  }
  @include sp {
    margin-bottom: vw(160);
    margin-left: vw(-8);
    margin-right: vw(-8);
    display: flex;
    flex-direction: column-reverse;
    .l-iceblock .l-iceblock__body {
      padding: vw(80) vw(32);
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &__image {
      margin-left: vw(-24);
      margin-right: vw(-24);
      @include octagon(vw(55));
      margin-bottom: vw(-72);
      img {
        width: 100%;
      }
    }
    &__label {
      @include roboto;
      font-style: normal;
      font-weight: 700;
      font-size: vw(16);
      line-height: 2;
      display: flex;
      align-self: flex-start;
      color: $blue;
      margin-bottom: vw(9);
      margin-top: vw(-8);
    }
    &__title {
      font-style: normal;
      font-weight: 700;
      font-size: vw(24);
      line-height: vw(40);
      text-align: justify;
      align-self: flex-start;
      margin-bottom: vw(24);
      margin-top: vw(-8);
      &--md {
        font-size: vw(20);
        line-height: vw(32);
      }
    }
    &__text {
      font-style: normal;
      font-weight: 500;
      font-size: vw(16);
      line-height: 2;
      text-align: justify;
      margin-top: vw(-8);
      margin-bottom: vw(-8);
     
      p {
        margin-bottom: vw(16);
        &:last-child {
          margin-bottom: 0;
        }
        br {
          display: none;
        }
      }
    }
    &__content {
      .l-iceblock__inner {
        @include octagon(vw(55));
      }
    }
    .c-more {
      margin-top: vw(24);
      text-align: left;
      align-self: center;
      display: inline-flex;
    }
  }
}