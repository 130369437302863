
.c-image-list {
  @include pc {
    display: flex;
    gap: 24px 25px;
    display: grid;
    grid-template-columns: repeat(5, auto);
    list-style: none;
    &__item {
      padding: 0;
    }
    img {
      margin: 0;
      padding: 0;
      display: block;
      width: 100%;
      object-fit: cover;
    }
  }
  @include sp {
    display: flex;
    gap: vw(24) vw(25);
    // flex-wrap: wrap;
    display: grid;
    grid-template-columns: repeat(2, auto);
    list-style: none;
    &__item {
      padding: 0;
    }
    img {
      margin: 0;
      padding: 0;
      display: block;
      width: 100%;
      object-fit: cover;
    }
  }
}
