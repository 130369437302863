// oirei

.p-kv {
  @include pc {
    padding: 48px 48px 48px 80px;
    // margin-top: 8px;
    z-index: 5;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
  
    &__body {
      flex: math.div(630, 1792) * 100% 0 1;
    }
    &__gallery {
      width: 100%;
      height: 100%;
      flex: math.div(1066, 1792) * 100% 0 1;
      margin-left: math.div(96, 1792) * 100%;
      
    }
  
  
    &__lead {
      font-style: normal;
      font-weight: 700;
      font-size: vw1920(32);
      line-height: vw1920(52);
      margin-top: vw1920(-24);
      /* or 162% */
      
      display: flex;
      align-items: center;
      
      /* main/black */
      
      color: #000000;
      margin-bottom: vw1920(16);
      
    }
    &__text {
      font-style: normal;
      font-weight: 500;
      font-size: vw1920(20);
      line-height: 2;
      /* or 200% */
      
      display: flex;
      align-items: center;
      
      /* main/black */
      
      color: #000000;
      margin-bottom: vw1920(39);
    }
    &__tel {
      @include shadow;
    }
    .btn-group {
      margin-bottom: vw1920(32);
      max-width: vw1920(630);
      @include pc1440 {
        gap: vw1440(16);
      }
    }
    .btn {
      font-weight: 700;
      font-size: vw1920(20);
      line-height: 2;
      padding: vw1920(17.5) vw1920(30) vw1920(17.5);
      &__icon {
        width: vw1920(32);
        height: vw1920(33);
        margin-right: vw1920(8);
        margin-bottom: vw1920(-2);
        svg {
          width: vw1920(32);
          height: vw1920(33);
        }
      }
      &__text {
        font-size: vw1920(20);
        @include mq($BREAKPOINT, 1440px) {
          font-size: vw1440(14);
        }
      }
    }
    .c-tel {
      background-color: #fff;
      padding: vw1920(16) vw1920(16) vw1920(24);
      @include octagon(22px);
      max-width: vw1920(630);
    }
  }

  @include sp {
    display: flex;
    flex-direction: column-reverse;
    &__body {
      padding: vw(24) vw(32) vw(120);

    }
    &__lead {
      font-style: normal;
      font-weight: 700;
      font-size: vw(18);
      line-height: 178%;
      margin-bottom: vw(24);
      margin-top: vw(-6);
    }
    &__text {
      font-weight: 500;
      font-size: vw(16);
      line-height: 2;
      text-align: justify;
      margin-top: vw(-12);
      margin-bottom: vw(-8);
      br {
        display: none;
      }
    }

    &__cta {
      display: none;
    }

  }
}


