.p-qa {
  @include pc {
    &__title {
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 56px;
      /* または200% */
      text-align: justify;

      margin-bottom: 20px;
      margin-top: -16px;
    }
    dt,
    &__q {
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 48px;
      text-align: justify;
      margin: 0 0 4px;
      padding: 0;
      // border:  solid #000;
    }
    dd,
    &__a {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 32px;
      margin: 0 0 28px;
      padding: 0;
      &:last-child {
        margin-bottom: -8px;
      }
      ul {
        margin: 8px 0;
        list-style: disc;
        margin-left: 24px;
        li {
          margin-bottom: 8px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  @include sp {
    &__title {
      font-style: normal;
      font-weight: 700;
      font-size: vw(24);
      line-height: vw(40);
      text-align: justify;
      margin-bottom: vw(48 - 15);
      margin-top: vw(-9);
    }
    &__q {
      font-style: normal;
      font-weight: 700;
      font-size: vw(20);
      line-height: vw(40);
      text-align: justify;
      margin-bottom: vw(7);
    }
    &__a {
      font-style: normal;
      font-weight: 500;
      font-size: vw(16);
      line-height: 2;
      text-align: justify;
      margin-bottom: vw(33);
      &:last-child {
        margin-bottom: vw(-8);
      }
      ul {
        margin: vw(8) 0;
        list-style: disc;
        margin-left: vw(24);
        li {
          margin-bottom: vw(8);
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
