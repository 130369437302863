body.post-type-archive-experts {
  @include pc {
    .c-card {
      &__projects {
        color: #000;
        margin-bottom: -2px;
      }
      &__subtitle {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        /* または171% */
        
        display: flex;
        align-items: center;
        margin-top: -20px;
        margin-bottom: 5px;
  
      }
      &__project-title {
        margin-top: -9px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 32px;
        color: rgba(0, 0, 0, 0.3);
        margin-bottom: 6px;
      }
      &__project-item {
        display: flex;
        align-items: flex-start;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 12px;
        &::before {
          @include content;
          width: 6px;
          height: 6px;
          background-color: $blue;
          flex: 6px 0 0;
          margin-right: 8px;
          margin-top: 7px;
          border-radius: 50%;
  
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  @include sp {
    .c-card {
      &__projects {
        margin-bottom: vw(-2);
      }
      &__image {
        margin-bottom: vw(2);
      }
      &__title {
        margin-bottom: vw(14);
      }
      &__subtitle {
        font-style: normal;
        font-weight: 500;
        font-size: vw(14);
        line-height: vw(24);
        
        display: flex;
        align-items: center;
        margin-bottom: vw(12);
  
      }
      &__project-title {
        margin-top: vw(-7);
        font-style: normal;
        font-weight: 500;
        font-size: vw(14);
        line-height: vw(32);
        color: rgba(0, 0, 0, 0.3);
        margin-bottom: vw(6);
      }
      &__project-item {
        display: flex;
        align-items: flex-start;
        font-style: normal;
        font-weight: 500;
        font-size: vw(14);
        line-height: vw(20);
        margin-bottom: vw(12);
        &::before {
          @include content;
          width: vw(8);
          height: vw(8);
          background-color: $blue;
          flex: vw(8) 0 0;
          margin-right: vw(8);
          margin-top: vw(6);
          border-radius: 50%;
  
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}


body.single-experts {
  @include pc {
    .l-page-body {
      padding-top: 200px;
    }
    .p-experts-header {
      max-width: 1080px;
      margin: 0 auto;
      padding: 160px 80px;
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 192px;
      &__role {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 32px;
        text-align: justify;
        margin-top: -8px;
        margin-bottom: 9px;
      }
      &__body {
        flex: math.div(565, 920) * 100% 0 1;
      }
      &__image {
        aspect-ratio: 307/240;
        @include octagon(48px);
        flex: 33.36956522% 0 1;
        img {
          width: 100%;;
          height: 100%;
          object-fit: cover;
        }
      }
      &__description {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 32px;
        display: flex;
        align-items: center;
        text-align: justify;
  
        margin-bottom: -8px;
        margin-top: 27px;
      }
    }
    .p-experts-section {
      margin-bottom: 60px + 6;
      
      &:last-child {
        margin-bottom: 0;
      }
  
      &__title {
        margin-bottom: 8px;
      }
      &__body {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
      .c-cards {
        margin-top: 24px;
        gap: 25px;
      }
    }
    .p-experts-more {
      display: inline-flex;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 0;
      .c-more {
        margin-bottom: 2px;
      }
    }
  }
  @include sp {
    // .l-page-body {
    //   padding-top: 200px;
    // }
    .p-experts-header {
      // max-width: 1080px;
      // margin: 0 auto;
      // padding: 160px 80px;
      // display: flex;
      // justify-content: space-between;
      // width: 100%;
      // margin-bottom: 192px;
      padding: vw(80) vw(32) vw(120);
      &__body {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      &__role {
        font-style: normal;
        font-weight: 500;
        font-size: vw(16);
        line-height: 2;
        text-align: justify;
        margin-top: vw(-8);
        margin-bottom: vw(16);
      }
      &__image {
        aspect-ratio: 307/240;
        @include octagon(vw(46));
        margin-top: vw(32);
        img {
          width: 100%;;
          height: 100%;
          object-fit: cover;
        }
      }
      &__description {
        font-style: normal;
        font-weight: 500;
        font-size: vw(16);
        line-height: vw(32);
        display: flex;
        align-items: center;
        text-align: justify;
  
        // margin-bottom: -8px;
        margin-bottom: vw(-8);
        margin-top: vw(16);
      }
    }
    .p-experts-section {
      margin-bottom: vw(72);
      
      &:last-child {
        margin-bottom: 0;
      }
  
      &__title {
        margin-bottom: vw(12);
        margin-top: vw(-8);
      }
      &__body {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
      .c-cards {
        margin-top: vw(40);
        gap: 25px;
      }
    }
    .p-experts-more {
      display: inline-flex;
      flex-direction: column;
      align-items: flex-start;
      margin-top: vw(-4);
      margin-bottom: vw(3);
      .c-more {
        margin-bottom: vw(0);
        width: auto;
      }
    }
    
  }
}