// oirei
.c-data {
  @include pc {
    margin-top: auto;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto auto;
    gap: 16px 8px;
    color: #000;
    &__title {
      font-weight: 500;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.3);
    }
    &__body {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
    }
    &--inline {
      display: inline-flex;
    }
    &--invert {
      color: #fff;
      .c-data__title {
        color: #fff;
      }
    }
    &--sm {
      .c-data {
        &__title {
          font-size: 14px;
        }
        &__body {
          font-size: 14px;
        }
      }
    }
  }
  @include sp {
    margin-top: auto;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto auto;
    gap: vw(4) vw(4);
    margin-top: vw(-3);
    margin-bottom: vw(-2);
    &__title {
      font-weight: 500;
      font-size: vw(14);
      color: rgba(0, 0, 0, 0.3);
      line-height: vw(20);
    }
    &__body {
      font-style: normal;
      font-weight: 500;
      font-size: vw(14);
      line-height: vw(20);
    }
  }
}