
@mixin octagon($r: 119px, $ratio: 1.7) {
  clip-path: polygon(
    $r 0%,
    calc(100% - $r) 0%,
    100% math.div($r, $ratio),
    100% calc(100% - math.div($r, $ratio)),
    calc(100% - $r) 100%,
    $r 100%,
    0% calc(100% - math.div($r, $ratio)),
    0% math.div($r, $ratio)
  );
  min-height: math.div($r, $ratio) * 2;
}
@mixin octagon_half($r: 325px, $ratio: 1.71052632) {
  clip-path: polygon($r 0%, calc(100% - $r) 0%, 100% math.div($r, $ratio), 100% 100%, 0% 100%, 0% math.div($r, $ratio));
}

@mixin octagon_lt($r: 325px, $ratio: 1.71052632) {
  clip-path: polygon(
    $r 0%,
    100% 0%,
    100% 100%,
    0% 100%,
    0% math.div($r, $ratio)
  );
}

@mixin octobox_padding($p: 80px) {
  top: $p;
  right: $p;
  bottom: $p;
  left: $p;
}

.u-octagon {
  will-change: transform;
  > &__inner {
    position: relative;
    height: 100%;
    @include pc {
      @include octagon(119px);
    }
    @include sp {
      @include octagon(vw(55));
    }
  }
  &--shadow {
    @include shadow;
  }

  &--xs {
    > .u-octagon__inner {
      position: relative;
      height: 100%;
      @include pc {
        @include octagon(22px);
      }
      @include sp {
        @include octagon(vw(22));
      }
    }
  }
  &--md {
    > .u-octagon__inner {
      position: relative;
      height: 100%;
      @include pc {
        @include octagon(56px);
      }
      @include sp {
        @include octagon(vw(46));
      }
    }
  }
}

.u-octagon-border {
  @include pc {
    @include octagon(42px);
    background: #fff;
    padding: 48px;
    position: relative;
    &__inner {
      pointer-events: none;
      z-index: 1;
      position: absolute;
      background-color: $green;
      
    @include octagon(42px);
      top: 2px;
      right: 2px;
      bottom: 2px;
      left: 2px;
  
    }
    &__body {
      z-index: 2;
      position: relative;
    }
  }
  @include sp {
    @include octagon(vw(55));
    background: #fff;
    padding: vw(48) vw(32);
    position: relative;
    &__inner {
      pointer-events: none;
      z-index: 1;
      position: absolute;
      background-color: $green;
      
    @include octagon(vw(54));
      top: 2px;
      right: 2px;
      bottom: 2px;
      left: 2px;
  
    }
    &__body {
      z-index: 2;
      position: relative;
    }
  }
  &--green {
    background: #B4DEAA;
    .u-octagon-border__inner {
      background-color: #EAF5EB;
    }
  }
  &--white {
    background: #B4DEAA;
    .u-octagon-border__inner {
      background-color: #fff;
    }
  }
  &--blue {
    background: $blue;
    .u-octagon-border__inner {
      background-color: #fff;
    }
  }
}