.l-sidemenu {
  @include pc {
    .c-widget {
      margin-bottom: 48px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  // @include sp {
  //   display: none;
  // }
  &__title {
    &:first-child {
      margin-top: 0;
    }
  }
}

