// oirei


// done px pc
.l-panel-posts {
  display: grid;
  gap: 24px;
  @include shadow;
  &__item {
    // border: 1px solid #000;
    background: #FFFFFF;
    display: block;
    @include octagon(42px);
    padding: 32px;
    &:hover {
      .l-panel-posts {
        &__title {
          color: $blue;
        }
      }
    }
  }
  &__title {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 40px;
    text-align: justify;
    -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 24px - 9;
    transition: color .4s $curve_main;
    margin-top: -7px;
  }
}

.l-thumb-posts {
  @include pc {
    display: grid;
    gap: 32px;
    &--sm {
      gap: 24px;
    }
    &__item {
      display: grid;
      gap: 32px;
      grid-template-columns: minmax(160px, 235px) 1fr;
      align-items: center;
  
      .l-thumb-posts--sm & {
        grid-template-columns: 128px 1fr;
        gap: 16px;
      }
      &:hover {
        .l-thumb-posts__title {
          color: $blue;
        }
      }
    }
  
    &__thumbnail {
      // border: 1px solid #000;
      aspect-ratio: 235/176;
      .u-octagon__inner {
        background-color: #ccc;
        @include octagon(35px);
        .l-thumb-posts--sm & {
          @include octagon(19px);
        }
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &__body {
      min-width: 350px;
      .l-thumb-posts--sm & {
        min-width: unset;
      }
    }
    &__date {
      @include roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      margin-bottom: 9px;
    }
    &__title {
      font-weight: 700;
      font-size: 20px;
      line-height: 40px;
      text-align: justify;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      margin-bottom: 15px;
      @include hover_color;
      a {
        @include hover_color;
      }
  
      .l-thumb-posts--sm & {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 32px;
        -webkit-line-clamp: 3;
        text-align: justify;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    &__meta {
      display: flex;
      // display: grid;
      // grid-template-columns: minmax(0px, 50%) auto;
      align-items: center;
      // border: 1px solid #000;
      .c-label {
        margin-right: 16px;
        display: inline-block;
        // min-width: 140px;
        // flex: auto 1 1;
      }
      .c-hashtags {
        display: flex;
        white-space: nowrap;
        flex-wrap: wrap;
      }
    }
  }
  @include sp {
    display: grid;
    gap: vw(32);
    &__item {
      display: grid;
      gap: vw(16);
      grid-template-columns: vw(120) auto;
      align-items: center;
    }
  
    &__thumbnail {
      // border: 1px solid #000;
      aspect-ratio: 1/1;
      .u-octagon__inner {
        background-color: #ccc;
        @include octagon(vw(24));
        // .l-thumb-posts--sm & {
        //   @include octagon(19px);
        // }
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &__body {
      display: flex;
      flex-direction: column;
      // .l-thumb-posts--sm & {
      //   min-width: unset;
      // }
    }
    &__date {
      // @include roboto;
      // font-style: normal;
      // font-weight: 500;
      // font-size: 16px;
      // margin-bottom: 9px;

      display: none;
    }
    &__title {
      order: 2;
      font-weight: 700;
      font-size: vw(16);
      line-height: vw(28);
      text-align: justify; // ios bug
      @include safari {
        text-align: left;
      }
      -webkit-line-clamp: 3;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      margin-bottom: vw(-5);
      // .l-thumb-posts--sm & {
      //   font-style: normal;
      //   font-weight: 500;
      //   font-size: 16px;
      //   line-height: 32px;
      //   -webkit-line-clamp: 3;
      //   text-align: justify;
      // }
      &:last-child {
        margin-bottom: 0;
      }
    }
    &__meta {
      order: 1;
      display: grid;
      justify-content: start;
      align-items: center;
      margin-bottom: vw(10);
      
      overflow: hidden;
      text-overflow: ellipsis;
      // .c-label {
      //   margin-right: 16px;
      // }
      .c-hashtags {
        display: none;
      }
    }
    &__category {
      flex: 1;
      overflow: hidden;
    }
  }
}