// color mixins -------------------------
@mixin btn_elements_color($color) {
  .btn__text {
    color: $color;
  }
  .btn__icon {
    path {
      fill: $color;
    }
  }
  .btn__arrow {
    path {
      stroke: $color;
    }
  }
}
@mixin btn_elements_invert_color($color) {
  &.btn--invert {
    background-color: #fff;
    @include btn_elements_color($color);
    @include pc {
      &:hover {
        background-color: $color;
        @include btn_elements_color(#fff);
      }
    }
  }
}
@mixin set_button_color($color) {
  // animation
  @include pc {
    &:hover {
      background-color: #fff;
      @include btn_elements_color($color);
    }
  }
  // default colors
  border-color: $color;
  background-color: $color;
  @include btn_elements_color(#fff);
  @include btn_elements_invert_color($color);
}
// color mixins -------------------------

// layouts -------------------------
@mixin btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  cursor: pointer;
}

// responsive -------------------------
@mixin btn_pc {
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.1);
  border-radius: 50px;
  transition: background-color 0.4s $curve_main, color 0.4s $curve_main;
  border: 2px solid transparent;
  &__text {
    line-height: 1.6;
    font-size: 20px;
    font-weight: 700;
    transition: color 0.4s $curve_main;
  }
  &__icon {
    margin-right: 8px;
  }
}
@mixin btn_sp {
  width: 100%;
  box-shadow: 0px 0px vw(24) rgba(0, 0, 0, 0.1);
  border-radius: vw(80);
  border: vw(2) solid transparent;
  &__text {
    font-size: vw(16);
    line-height: vw(60);
    font-weight: 700;
  }
  .btn__icon {
    width: vw(24);
    height: vw(24);
    margin-right: vw(8);
    svg {
      width: 100%;
      display: block;
      height: 100%;
    }
    &--sm {
      width: vw(20);
      height: vw(20);
    }
  }
  &--xs {
    .btn__text {
      line-height: vw(42);
    }
  }
}

// sizes -------------------------
@mixin btn--xs_pc {
  padding: 4px 32px 0px;
  padding-left: 22px;
  padding-right: 22px;
  .btn__text {
    line-height: 2;
    font-size: 16px;
    font-weight: 500;
  }
  .btn__icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    svg {
      width: 20px;
      height: 20px;
    }
  }
  .btn__text {
    font-weight: 500;
  }
}
@mixin btn--sm_pc {
  padding: 10px 30px;
  font-size: 16px;
  font-weight: 500;
}
@mixin btn--md_pc {
  padding: 18px 30px;
  font-size: 20px;
  font-weight: 700;
  .btn__icon {
    width: 32px;
    height: 33px;
    margin-right: 8px;
  }
  &.btn--scaling {
    @include btn--scaling;
  }
}

@mixin btn--scaling {
  @include pc1440 {
    padding: vw1440(18) vw1440(32);
    .btn__text {
      font-size: vw1440(20);
    }
    .btn__icon {
      width: vw1440(32);
      height: vw1440(33);
      margin-right: vw1440(8);
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
  @media (min-width: #{$BREAKPOINT + 1}) and (max-width: 1090px) {
    .btn__text {
      font-size: vw1440(18);
    }
  }
  @media (min-width: #{$BREAKPOINT + 1}) and (max-width: 1000px) {
    .btn__text {
      font-size: vw1440(16);
    }
  }
}
