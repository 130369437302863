

.p-menu {
  @include pc {
    // display: grid;
    // grid-template-columns: repeat(8, auto);
    align-items: center;
    justify-content: start;
    // gap: 48px;
    display: flex;
    // @include mq($BREAKPOINT, 1440px) {
    //   gap: vw1440(24);
    // }
    &__arrow {
      display: none;
    }
    &__item {
      font-size: 16px;
      margin-right: vw1920(48);
      &:last-child {
        margin-right: 0;
      }
      @include mq($BREAKPOINT, 1600px) {
        font-size: vw1600(16);
        margin-right: vw1600(24);
      }
      @include mq($BREAKPOINT, 1440px) {
        font-size: vw1440(16);
        margin-right: vw1440(24);
      }
      @include mq(1920px, null) {
        margin-right: 48px;
      }
      position: relative;
      font-style: normal;
      font-weight: 500;
      line-height: 2;
      white-space: nowrap;
      transition: color .4s $curve_main;

      
      &::after {
        content: '';
        display: block;
        position: absolute;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #1EA2D9;
        left: 50%;
        bottom: 0;
        bottom: -12px;
        margin-left: -4px;
        opacity: 0;
        pointer-events: none;
      }
      &.is-active {
        color: #1EA2D9;
        font-weight: 700;
        &::after {
          opacity: 1;
        }
      }
      &:hover {
        color: #1EA2D9;
      }

      // &[href="/column/"] {
      //   body.category-column & {
      //     color: #1EA2D9;
      //     &::after {
      //       opacity: 1;
      //     }
      //   }
      // }
    }
  }
  @include sp {
    display: grid;
    grid-template-columns: 1fr;
    gap: vw(16);
    margin-top: vw(-4);
    &__item {
      // background-color: #ccc;
      font-style: normal;
      font-weight: 700;
      font-size: vw(16);
      line-height: 2;
      display: flex;
      align-items: center;
      border-bottom: vw(2) solid rgba(30, 162, 217, 0.2);
      padding-bottom: vw(2);
      margin-bottom: vw(4);
      &:first-child {
        margin-bottom: vw(-4);
      }
      &--inline {
        line-height: 1;
        // border: 1px solid #000;
        padding-bottom: vw(12);
      }
      small {
        color: rgba(0, 0, 0, 0.3);
        font-size: vw(14);
        line-height: 1;
        display: block;
        margin-top: vw(8);
      }
    }
    &__icon {
      width: vw(24);
      height: vw(24);
      margin-right: vw(8);
      svg {
        width: 100%;
        height: 100%;
      }
      path {
        fill: $green
      }
    }
  }
}
