.c-scroll-table {
  @include pc {
    max-height: 1184px;
    overflow: scroll;
    // max-width: 100%;
    width: 100%;
    table {
      border-collapse: separate;
    }
    thead {
      th {
        position: sticky;
        // left: 0;
        top: 0;
        z-index: 4;
        border-top: 1px solid #1ea2d9;
        border-bottom: 1px solid #1ea2d9;
        border-right: 1px solid #1ea2d9;
        padding: 30px;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 28px;
        text-align: center;

        &:first-child {
          position: sticky;
          background-color: #cdecf7;
          border-right: 1px solid #1ea2d9;
          border-left: 1px solid #1ea2d9;
          z-index: 5;
          left: 0;
        }
      }
    }
    tbody {
      th {
        position: sticky;
        left: 0;
        z-index: 2;
        // border-top: 1px solid #1EA2D9;
        border-bottom: 1px solid #1ea2d9;
        border-left: 1px solid #1ea2d9;
        border-right: 1px solid #1ea2d9;
        font-weight: 700;
        font-size: 16px;
        line-height: 28px;
        text-align: center;
        min-width: 223px;
      }
      td {
        border-bottom: 1px solid #1ea2d9;
        border-right: 1px solid #1ea2d9;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        max-width: 320px;
        min-width: 200px;
        background-color: #fff;
        padding: 20px 24px 20px 23px;
      }
    }
    th {
      padding: 20px 24px 20px 23px;
      background-color: #cdecf7;
      min-width: 112px;
      vertical-align: middle;
    }
    ul {
      list-style-type: disc;
      margin-left: 20px;
      li {
        margin-bottom: 7px;
        &:last-child {
          margin: 0;
        }
      }
    }
    a {
      text-decoration: underline;
      color: $blue;
    }
  }

  @include sp {
    max-height: vw(1671);
    overflow: scroll;
    margin-right: vw(-32);
    // max-width: 100%;
    table {
      border-collapse: separate;
    }
    thead {
      th {
        position: sticky;
        // left: 0;
        top: 0;
        z-index: 4;
        border-top: 1px solid #1ea2d9;
        border-bottom: 1px solid #1ea2d9;
        border-right: 1px solid #1ea2d9;
        padding: vw(21);
        font-style: normal;
        font-weight: 700;
        font-size: vw(16);
        line-height: vw(28);
        text-align: center;

        &:first-child {
          position: sticky;
          background-color: #cdecf7;
          border-right: 1px solid #1ea2d9;
          border-left: 1px solid #1ea2d9;
          z-index: 5;
          left: 0;
        }
      }
    }
    tbody {
      th {
        position: sticky;
        left: 0;
        z-index: 2;
        border-bottom: 1px solid #1ea2d9;
        border-left: 1px solid #1ea2d9;
        border-right: 1px solid #1ea2d9;
        font-weight: 700;
        font-size: vw(16);
        line-height: vw(28);
        text-align: center;

        padding: vw(16);

        min-width: vw(112);
        width: vw(112);
      }
      td {
        border-bottom: 1px solid #1ea2d9;
        border-right: 1px solid #1ea2d9;
        font-style: normal;
        font-weight: 500;
        font-size: vw(14);
        line-height: vw(24);
        max-width: vw(200);
        min-width: vw(200);
        background-color: #fff;
        padding: vw(20) vw(23) vw(19) vw(23);
      }
    }
    th {
      background-color: #cdecf7;
      vertical-align: middle;
    }
    ul {
      list-style-type: disc;
      margin-left: vw(20);
      li {
        margin-bottom: vw(7);
        &:last-child {
          margin: 0;
        }
      }
    }
    a {
      text-decoration: underline;
      color: $blue;
    }
  }
}

.c-resp-table {
  th {
    background-color: #ceecf7;
  }
  td {
    background-color: #fff;
  }
  th,
  td {
    border-style: solid;
    border-color: $blue;
  }
  @include pc {
    border-collapse: separate;
    // border-bottom: 1px solid $blue;
    border-bottom-width: 1px;
    border-bottom: 1px solid $blue;
    th,
    td {
      border: 1px solid $blue;
      border-bottom: 0;
      border-right: 0;
      &:last-child {
        border-right: 1px solid $blue;
      }
    }
    th {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 28px;
      text-align: center;
      padding: 17px 24px;
      vertical-align: middle;
    }
    td {
      padding: 18px 24px 16px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
    }
    small {
      font-size: 12px;
      line-height: 20px;
    }
  }

  @include sp {
    border-collapse: separate;
    border-bottom: 1px solid $blue;
    th,
    td {
      display: block;
      border: 1px solid $blue;
      border-bottom: 0;
    }
  }

  @include sp {
    border-collapse: separate;
    border-bottom: 1px solid $blue;
    th,
    td {
      display: block;
      border: 1px solid $blue;
      border-bottom: 0;
    }
    th {
      font-weight: 700;
      font-size: vw(16);
      line-height: vw(28);
      text-align: left;
      padding: vw(9) vw(15) vw(10);
    }
    td {
      padding: vw(11) vw(15) vw(12);
      font-style: normal;
      font-weight: 500;
      font-size: vw(16);
      line-height: vw(28);
    }
  }
}
