

// single-column
.l-single-column {
  @include pc {
    position: relative;
    z-index: 5;
  
    .c-toc {
      margin-bottom: 80px;
    }
    &__author {
      margin-top: 48px;
      .level3-heading {
        margin-bottom: 24px;
      }
    }
  }
  @include sp {
    // padding-top: vw(184);
    // margin-top: vw(-40);
    // padding-bottom: vw(160);
    .c-toc {
      margin-bottom: vw(160 - 8);
      margin-left: vw(-8);
      margin-right: vw(-8);
    }
    &__author {
      margin-top: vw(44);
      .level3-heading {
        font-size: vw(20);
        line-height: vw(32);
        margin-bottom: vw(19);
      }
      .c-user {
        margin-top: vw(0);
        margin-left: vw(-8);
        margin-right: vw(-8);
      }
    }
  }
}


// column
body.single {
  @include sp {
    .l-sidemenu {
      display: none;
    }
    .p-banners {
      margin-top: vw(160);
    }
  }
}

